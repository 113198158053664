import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import '../../Style/MultiEmailComponent.css'
import { Chip } from '@mui/material'

const MultiEmailComponent = ({ emails, setEmails, placeholder = 'Emails' }) => {
  return (
    <ReactMultiEmail
      style={{ marginTop: '10px' }}
      placeholder={placeholder}
      emails={emails}
      onChange={_emails => {
        setEmails(_emails)
      }}
      getLabel={(email, index, removeEmail) => {
        return (
          <Chip
            sx={{ mr: 1 }}
            key={index}
            label={email}
            onDelete={() => removeEmail(index)}
          />
        )
      }}
    />
  )
}

export default MultiEmailComponent
