import '../../Style/Loading.css'

const Loading = ({ padding = '0', margin = '15px' }) => {
  return (
    <div className='container' style={{ padding: padding, margin: margin }}>
      <div className='cube'>
        <div className='cube__inner'></div>
      </div>
      <div className='cube'>
        <div className='cube__inner'></div>
      </div>
      <div className='cube'>
        <div className='cube__inner'></div>
      </div>
    </div>
  )
}

export default Loading
