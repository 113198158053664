import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import App from './App'
import Layout from './Components/Layout'
import NoPage from './Components/Helpers/NoPage'
import SignIn from './Components/Auth/SignIn'
import SignOut from './Components/Auth/SignOut'
import Dashboard from './Components/Main/Dashboard'
import SignUp from './Components/Auth/SignUp'
import Search from './Components/Main/Search/Search'

import { getStorage } from 'firebase/storage'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

import Upload from './Components/Document/Upload'
import View from './Components/View/View'
import Edit from './Components/Document/Edit'
import Profile from './Components/Main/Profile'
import SuperAdmin from './Components/SuperAdmin/SuperAdmin'
import About from './Components/Support/About'
import Help from './Components/Support/Help'
import ContactUs from './Components/Support/ContactUs'
import TermsOfUse from './Components/Support/TermsOfUse'
import Privacy from './Components/Support/Privacy'
import Pricing from './Components/Support/Pricing'
import Subprocessors from './Components/Support/Subprocessors'
import DPA from './Components/Support/DPA'
import Blog from './Components/Support/Blog'

const firebaseConfig = {
  apiKey: 'AIzaSyC4_26DAWlzVyAxDgmNlvcFWgzzPnMdu6s',
  authDomain: 'communitydocument.com',
  projectId: 'community-doc',
  storageBucket: 'community-doc.appspot.com',
  messagingSenderId: '150230633881',
  appId: '1:150230633881:web:b8e735d83e55715bf81708',
  measurementId: 'G-6RM4V4D6LD'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const storage = getStorage()
const db = getFirestore(app)
const analytics = getAnalytics(app)

export default function Base () {
  const [uid, setUid] = useState(null)
  const [userType, setUserType] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [savedLocation, setSavedLocation] = useState('/search')
  const [overrideLocation, setOverrideLocation] = useState(null)
  const [dark, setDark] = useState(false)

  useEffect(() => {
    if (window.sessionStorage.getItem('theme') !== null) {
      setDark(window.sessionStorage.getItem('theme') === '0')
    }
  }, [])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  })

  const lightTheme = createTheme({
    palette: {
      mode: 'light'
    }
  })

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Layout
                auth={auth}
                setUid={setUid}
                uid={uid}
                db={db}
                userType={userType}
                setUserType={setUserType}
                savedLocation={savedLocation}
                setSavedLocation={setSavedLocation}
                displayName={displayName}
                setDisplayName={setDisplayName}
                setEmail={setEmail}
                overrideLocation={overrideLocation}
                setOverrideLocation={setOverrideLocation}
                dark={dark}
                setDark={setDark}
              />
            }
          >
            <Route index element={<App />} />
            <Route path='/signOut' element={<SignOut auth={auth} />} />
            <Route
              path='/signIn'
              element={
                <SignIn
                  auth={auth}
                  savedLocation={overrideLocation || savedLocation}
                  db={db}
                  setUserType={setUserType}
                  setDisplayName={setDisplayName}
                  analytics={analytics}
                />
              }
            />
            <Route
              path='/signUp'
              element={
                <SignIn
                  auth={auth}
                  savedLocation={overrideLocation || savedLocation}
                  db={db}
                  setUserType={setUserType}
                  setDisplayName={setDisplayName}
                  analytics={analytics}
                />
              }
            />
            <Route
              path='/signUpAdmin'
              element={
                <SignUp
                  auth={auth}
                  db={db}
                  setUserType={setUserType}
                  savedLocation={overrideLocation || savedLocation}
                  displayName={displayName}
                  setDisplayName={setDisplayName}
                  asAdmin={true}
                  analytics={analytics}
                />
              }
            />
            <Route
              path='/dashboard'
              element={
                <Dashboard uid={uid} db={db} displayName={displayName} />
              }
            />
            <Route
              path='/profile'
              element={
                <Profile
                  uid={uid}
                  db={db}
                  displayName={displayName}
                  setDisplayName={setDisplayName}
                  userType={userType}
                  email={email}
                  dark={dark}
                  setDark={setDark}
                  analytics={analytics}
                />
              }
            />
            <Route
              path='/upload'
              element={
                <Upload
                  storage={storage}
                  uid={uid}
                  userEmail={email}
                  db={db}
                  displayUserName={displayName}
                />
              }
            />
            <Route
              path='/edit/:id'
              element={
                <Edit
                  uid={uid}
                  db={db}
                  storage={storage}
                  userEmail={email}
                  displayUserName={displayName}
                  isDraft={false}
                />
              }
            />
            <Route
              path='/draft/:id'
              element={
                <Edit
                  uid={uid}
                  db={db}
                  storage={storage}
                  userEmail={email}
                  displayUserName={displayName}
                  isDraft={true}
                />
              }
            />
            <Route
              path='/copy/:id'
              element={
                <Edit
                  uid={uid}
                  db={db}
                  storage={storage}
                  userEmail={email}
                  displayUserName={displayName}
                  isDraft={false}
                  isCopy={true}
                />
              }
            />
            {uid === 'ofOJBZqp7WdrpYYYPV16KAbotsd2' && (
              <Route
                path='/admin'
                element={<SuperAdmin db={db} storage={storage} uid={uid} />}
              />
            )}
            <Route
              path='/search'
              element={<Search uid={uid} db={db} displayName={displayName} />}
            />
            <Route
              path='/view/:id'
              element={
                <View
                  uid={uid}
                  db={db}
                  setSavedLocation={setSavedLocation}
                  displayName={displayName}
                  email={email}
                  analytics={analytics}
                />
              }
            />
            <Route
              path='/support/about'
              element={<About analytics={analytics} />}
            />
            <Route
              path='/support/help'
              element={<Help analytics={analytics} />}
            />
            <Route
              path='/support/contact'
              element={<ContactUs db={db} analytics={analytics} />}
            />
            <Route
              path='/support/termsofuse'
              element={<TermsOfUse analytics={analytics} />}
            />
            <Route
              path='/support/privacy'
              element={<Privacy analytics={analytics} />}
            />
            <Route
              path='/support/pricing'
              element={<Pricing analytics={analytics} />}
            />
            <Route
              path='/support/subprocessors'
              element={<Subprocessors analytics={analytics} />}
            />
            <Route
              path='/support/dpa'
              element={<DPA analytics={analytics} />}
            />
            <Route
              path='/support/blog'
              element={<Blog analytics={analytics} />}
            />
            <Route path='*' element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Base />
  </React.StrictMode>
)
