import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField
} from '@mui/material'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import MultiEmailComponent from '../Email/MultiEmailComponent'
import {
  Cancel,
  DarkMode,
  Delete,
  Edit,
  LightMode,
  Verified
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'

const Profile = ({
  db,
  uid,
  displayName,
  setDisplayName,
  userType,
  email,
  dark,
  setDark,
  analytics
}) => {
  const [changeDisplayName, setChangeDisplayName] = useState('')
  const [alert, setAlert] = useState(false)
  const [payments, setPayments] = useState([])
  const [showAllPayments, setShowAllPayments] = useState(false)

  const navigate = useNavigate()

  const handleDisplayName = event => {
    const value = event.target.value
    setChangeDisplayName(value)
    setAlert(false)
  }

  const submit = async () => {
    let docRef = doc(db, 'users', uid)
    await setDoc(
      docRef,
      {
        displayName: changeDisplayName
      },
      { merge: true }
    ).then(() => {
      setDisplayName(changeDisplayName)
      setAlert(true)
      logEvent(analytics, 'changed_display_name')
    })
  }

  useEffect(() => {
    async function getPayments () {
      let querySnapshot = await getDocs(
        collection(db, 'customers', uid, 'payments')
      )

      let pay = []
      querySnapshot.forEach(doc => {
        pay.push(doc.data())
      })
      setPayments(pay)
    }

    if (userType) {
      getPayments()
    }
    setChangeDisplayName(displayName)
  }, [displayName])

  const [sentRequest, setSentRequest] = useState(false)

  async function adminRequest () {
    setSentRequest(true)
    await addDoc(collection(db, 'mail'), createObject())
  }

  function createObject () {
    let obj = {}
    obj.to = ['dbcmediaonline@gmail.com']
    obj.message = {
      subject: 'Request to be an Admin on Community Document',
      html: `Request from: ${uid} - ${email}`
    }
    return obj
  }

  function getDateString (date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
  }

  return (
    <Box sx={{ m: { md: 2, xs: 0 }, minHeight: '95%', pb: 2 }}>
      <Stack spacing={2} sx={{ width: 'fit-content', margin: '10px auto' }}>
        <Card raised>
          <CardHeader title='Profile' />
          <CardContent>
            <Stack spacing={2} maxWidth={400}>
              {alert && (
                <Alert
                  variant='filled'
                  severity='success'
                  onClose={() => {
                    setAlert(false)
                  }}
                >
                  Updated Display Name
                </Alert>
              )}
              <p style={{ marginBottom: '10px', fontSize: '1em' }}>
                <b>{email}</b>
              </p>
              <TextField
                id='displayName'
                variant='outlined'
                label='Display Name'
                onChange={handleDisplayName}
                value={changeDisplayName}
                required
              />
              <Button
                variant='contained'
                color='success'
                disabled={
                  changeDisplayName === displayName ||
                  changeDisplayName.length === 0
                }
                onClick={submit}
              >
                Submit Name Change
              </Button>

              <Button
                onClick={() => {
                  window.sessionStorage.setItem('theme', !dark ? '0' : '1')
                  setDark(!dark)
                }}
                color='inherit'
                startIcon={dark ? <DarkMode /> : <LightMode />}
                sx={{ height: 'fit-content' }}
              >
                Toggle Theme
              </Button>
              <Divider component='div' sx={{ width: '100%' }} />
              <span style={{ marginBottom: '10px', fontSize: '0.75em' }}>
                {!userType
                  ? 'Free User: View and Comment'
                  : 'Paid User: Upload, View, and Comment'}
              </span>
              <span style={{ fontSize: '0.75em' }}>
                Contact{' '}
                <Link href='mailto:contact@communitydocument.com'>
                  contact@communitydocument.com
                </Link>{', '}
                if you want to change your user type or have any questions!
              </span>
              {!userType && (
                <>
                  {sentRequest ? (
                    <Alert severity='success'>
                      Admin request has been sent. We will get back to you as
                      quick as we can!
                    </Alert>
                  ) : (
                    <Button
                      onClick={adminRequest}
                      color='inherit'
                      startIcon={<Verified />}
                      sx={{ height: 'fit-content' }}
                    >
                      Send an Admin Request
                    </Button>
                  )}
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
        {userType && payments.length > 0 && (
          <Card raised>
            <CardHeader title='Payments' />
            <CardContent>
              <Stack spacing={2} maxWidth={400}>
                {payments
                  .sort((a, b) => b.created - a.created)
                  .filter(val => val.items && val.items.length > 0)
                  .map((payment, i) => (
                    <>
                      {i < 5 ? (
                        <Box key={payment.id}>
                          <div>Item: {payment.items[0].description}</div>
                          <div>
                            Price: ${payment.items[0].amount_total / 100}
                          </div>
                          <div>Quantity: {payment.items[0].quantity} days</div>
                          <div>
                            Billed On: {getDateString(payment.created * 1000)}
                          </div>
                          <Link href={'/view/' + payment.metadata.item}>
                            View Document
                          </Link>
                        </Box>
                      ) : (
                        <>
                          {showAllPayments && (
                            <Box key={payment.id}>
                              <div>Item: {payment.items[0].description}</div>
                              <div>
                                Price: ${payment.items[0].amount_total / 100}
                              </div>
                              <div>
                                Quantity: {payment.items[0].quantity} days
                              </div>
                              <div>
                                Billed On:{' '}
                                {getDateString(payment.created * 1000)}
                              </div>
                              <Link href={'/view/' + payment.metadata.item}>
                                View Document
                              </Link>
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  ))}
                {payments.length > 5 && (
                  <Button onClick={() => setShowAllPayments(!showAllPayments)}>
                    {(showAllPayments ? 'Hide' : 'All') + ' Payments'}
                  </Button>
                )}
                <Button
                  variant='contained'
                  onClick={() => navigate('/support/contact')}
                >
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        )}
        {userType && <CreateTeams uid={uid} db={db} email={email} />}
      </Stack>
    </Box>
  )
}

const CreateTeams = ({ uid, db, email }) => {
  const [teams, setTeams] = useState([])
  const [teamName, setTeamName] = useState('')
  const [teamEmails, setTeamEmails] = useState([])
  const [editId, setEditId] = useState(null)
  const [teamNameEdit, setTeamNameEdit] = useState('')
  const [teamEmailsEdit, setTeamEmailsEdit] = useState([])

  const [alert, setAlert] = useState(null)

  const handleTeamName = event => {
    const value = event.target.value
    setTeamName(value)
  }
  const handleTeamNameEdit = event => {
    const value = event.target.value
    setTeamNameEdit(value)
  }

  async function submitTeamEdit () {
    let oldTeam = {
      teamName: teamNameEdit,
      emails: teamEmailsEdit,
      creator: uid,
      editDate: Date.now()
    }
    await setDoc(doc(db, 'teams', editId), oldTeam, {
      merge: true
    }).then(() => {
      setTeams([
        { ...oldTeam, id: editId },
        ...teams.filter(val => val.id !== editId)
      ])

      setTeamEmailsEdit([])
      setTeamNameEdit('')
      setEditId(null)
      setAlert('Updated Team')
    })
  }

  async function submitTeam () {
    let newTeam = {
      teamName: teamName,
      emails: teamEmails,
      creator: uid,
      createDate: Date.now()
    }
    await addDoc(collection(db, 'teams'), newTeam).then(docRef => {
      setTeams([...teams, { ...newTeam, id: docRef.id }])
      setAlert('Added Team')
      setTeamEmails([])
      setTeamName('')
    })
  }

  async function deleteTeam (id) {
    await deleteDoc(doc(db, 'teams', id)).then(() => {
      setTeams([...teams.filter(val => val.id !== id)])
      setAlert('Deleted Team')
    })
  }

  useEffect(() => {
    async function getTeams () {
      let q = query(collection(db, 'teams'), where('creator', '==', uid))
      const documentSnapshots = await getDocs(q)
      let teams_ = []
      documentSnapshots.forEach(val => {
        teams_.push({ ...val.data(), id: val.id })
      })
      setTeams(teams_)
    }

    if (uid && db) {
      getTeams()
    }
  }, [uid, db])

  return (
    <Card raised>
      <CardHeader title='Teams' />
      <CardContent>
        <Stack spacing={2} maxWidth={400}>
          {alert && (
            <Alert
              variant='filled'
              severity='success'
              onClose={() => {
                setAlert(null)
              }}
              sx={{ mb: 1 }}
            >
              {alert}
            </Alert>
          )}
          <span style={{ marginBottom: '10px', fontSize: '1em' }}>
            Create Teams so You Can Share Admin Privileges Easily!
          </span>
          <Card>
            <CardHeader title='New Team' />
            <Stack spacing={2} maxWidth={400} sx={{ p: 2 }}>
              <span className='caption'>
                Press enter after entering each email, copy and paste a list, or
                add a team.
              </span>
              <TextField
                id='teamName'
                variant='outlined'
                label='Team Name'
                onChange={handleTeamName}
                value={teamName}
                required
              />
              <MultiEmailComponent
                emails={teamEmails}
                setEmails={setTeamEmails}
                placeholder='Team Emails'
              />
              <Button
                variant='contained'
                onClick={submitTeam}
                disabled={teamEmails.length === 0 || teamName.length === 0}
              >
                Create
              </Button>
            </Stack>
          </Card>
          {teams.map(team => (
            <Card key={team.id} sx={{ p: 1 }}>
              <CardHeader
                title={team.teamName}
                action={
                  <>
                    <IconButton onClick={() => deleteTeam(team.id)}>
                      <Delete color='error' />
                    </IconButton>
                    {editId === null ? (
                      <IconButton
                        onClick={() => {
                          setEditId(team.id)
                          setTeamEmailsEdit(
                            team.emails.filter(val => val !== email)
                          )
                          setTeamNameEdit(team.teamName)
                        }}
                      >
                        <Edit />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setEditId(null)
                          setTeamEmailsEdit([])
                          setTeamNameEdit('')
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </>
                }
              />
              <CardContent>
                <Stack spacing={2}>
                  {'Emails: '} <br /> {team.emails.join(', ')}
                  <br />
                  {editId === team.id && (
                    <>
                      <TextField
                        id='teamName'
                        variant='outlined'
                        label='Team Name'
                        onChange={handleTeamNameEdit}
                        value={teamNameEdit}
                        required
                      />
                      <MultiEmailComponent
                        emails={teamEmailsEdit}
                        setEmails={setTeamEmailsEdit}
                        placeholder='Team Emails'
                      />
                      <Button
                        variant='contained'
                        onClick={submitTeamEdit}
                        disabled={
                          teamEmailsEdit.length === 0 ||
                          teamNameEdit.length === 0
                        }
                      >
                        Submit Changes
                      </Button>
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Profile
