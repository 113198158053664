import { Box, Button, Card, CardContent, CardHeader, Link } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Pricing = ({ analytics }) => {
  const navigate = useNavigate()
  useEffect(() => {
    logEvent(analytics, 'pricing')
  }, [])
  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardContent sx={{ maxWidth: '800px', margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <h3
            style={{
              color: '#1976d2'
            }}
          >
            Engage, Revise, Empower
          </h3>
          <h1>Document Hosting Pricing</h1>
        </Box>
        <span>
          Contact{' '}
          <Link href='mailto:pricing@communitydocument.com'>
            pricing@communitydocument.com
          </Link>{' '}
          to discuss pricing!
        </span>
        <br />
        <Button
          onClick={() => navigate('/support/contact')}
          variant='contained'
          sx={{ mt: 2 }}
        >
          Contact Us
        </Button>
      </CardContent>
    </Card>
  )
}

export default Pricing
