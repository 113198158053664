import { Box, Button, Card, CardContent, Divider, Link } from '@mui/material'
import { LegendBar } from '../View/CommentTypeIcon'
import { useNavigate } from 'react-router-dom'
import {
  AdminPanelSettings,
  ContactSupport,
  Delete,
  FileOpen,
  Info,
  PersonAdd,
  PostAdd
} from '@mui/icons-material'
import { useEffect } from 'react'
import { logEvent } from 'firebase/analytics'

const Help = ({ analytics }) => {
  const navigate = useNavigate()
  useEffect(() => {
    logEvent(analytics, 'help')
  }, [])
  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardContent sx={{ maxWidth: '800px', m: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <h3
            style={{
              color: '#1976d2'
            }}
          >
            Engage, Revise, Empower
          </h3>
          <h1>Using Community Document</h1>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <FileOpen /> <h3>Documents</h3>
        </Box>
        <p style={{ marginTop: '0' }}>
          Using the control bar, you can change pages, zoom, filter comments and
          toggle fullscreen mode.
          <br />
          <br />
          <b>You cannot view or leave comments in fullscreen mode.</b> Click
          anywhere on the document to add a comment, a question or a like. Click
          an icon on the document to view comments and reply to them.
          <br />
        </p>
        <LegendBar />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <PersonAdd /> <h3>Create An Account</h3>
        </Box>
        <p style={{ marginTop: '0' }}>
          <Button
            onClick={() => navigate('/signin')}
            variant='contained'
            sx={{ mr: 2 }}
          >
            Sign In
          </Button>
          <Button onClick={() => navigate('/signup')} variant='contained'>
            Sign Up
          </Button>
          <br />
          <br />
          <i>
            Requiring sign-ins for commenting helps maintain a respectful and
            accountable community, fostering meaningful interactions while
            deterring spam and inappropriate behavior.
          </i>
        </p>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <Delete /> <h3>Delete An Account</h3>
        </Box>
        <p style={{ marginTop: '0' }}>
          Contact{' '}
          <Link href='mailto:delete.request@communitydocument.com'>
            delete.request@communitydocument.com
          </Link>{' '}
          to delete your account.
        </p>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              color: '#1976d2'
            }}
          >
            <Info /> <h3>Need More Information or Help:</h3>
          </Box>
          <Button
            onClick={() => navigate('/view/howtouse')}
            variant='contained'
          >
            How To...
          </Button>
          <Button
            onClick={() => navigate('/support/contact')}
            variant='contained'
          >
            Contact Us
          </Button>
        </Box>
        <Divider component='div' sx={{ width: '100%', mt: 10, mb: 10 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'center'
          }}
        >
          <AdminPanelSettings />
          <h2>Admin User</h2>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <ContactSupport /> <h3>How To</h3>
        </Box>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/zcH7Zx-t2b8?si=Qk6GBI3BzKwqsU1Z'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
          style={{ width: '100%' }}
        ></iframe>

        <Card raised sx={{ mt: 2, mb: 1 }}>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: '#1976d2'
              }}
            >
              <PostAdd /> <h3>Upload a Document</h3>
            </Box>
            <p style={{ margin: '0' }}>
              <b>Document Information</b>
              <br />
              Required:
              <ul>
                <li>File Name,</li> <li>Organization Name</li>
              </ul>
              Optional:
              <ul>
                <li>Logo,</li> <li>Host Name,</li> <li>Description,</li>{' '}
                <li>Contact Information,</li>{' '}
                <li>Contact Link (must be a valid url)</li>
              </ul>
              <br />
              <b>Document File is Required</b>
              <br />
              <br />
              <b>Document Configuration</b>
              <br />
              Required:{' '}
              <ul>
                <li>Visible Dates (dates any user can see the document)</li>
              </ul>
              Optional:{' '}
              <ul>
                <li>
                  Admin Emails (share admin permissions with other users, they
                  must sign up with the inputted email),
                </li>{' '}
                <li>Allow Comments (dates must be between Visible Dates),</li>
                <li>
                  Comment Visibility (allow users to see all comments or just
                  their own),
                </li>
                <li>Access Code (add a password to view the document),</li>
                <li>
                  Searchable by public (allow document to be found on search
                  tab),
                </li>
                <li>Require sign in to view</li>
              </ul>
            </p>
          </CardContent>
        </Card>
        <Button
          onClick={() => navigate('/signupadmin')}
          variant='contained'
          sx={{ mt: 1, mr: 2 }}
        >
          Admin Sign Up
        </Button>
        <Button
          onClick={() => navigate('/support/contact')}
          variant='contained'
          sx={{ mt: 1 }}
        >
          Contact Us
        </Button>
      </CardContent>
    </Card>
  )
}

export default Help
