import { Comment } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip
} from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import communityLogo from '../../../Icons/logo.png'
import { openDB } from 'idb'

const DB_NAME = 'pdf-cache-db'
const DB_VERSION = 1
const STORE_NAME = 'pdfs'

const SearchResultTile = ({
  documentResult,
  db,
  title,
  uid,
  realDoc = null,
  inLibrary
}) => {
  const navigate = useNavigate()
  const [document, setDocument] = useState(realDoc)

  const getDate = date => {
    return new Date(date.toDate())
  }

  const showPreview = document => {
    return !document.viewCode && checkDates(document)
  }

  const checkDates = document => {
    const today = Date.now()
    return (
      (today >= getDate(document.visibleDate.startDate) &&
        today <= getDate(document.visibleDate.endDate)) ||
      document.uid === uid ||
      (document.admins && document.admins.includes(uid))
    )
  }

  useEffect(() => {
    const getDocument = async () => {
      var result = documentResult.path.substring(
        documentResult.path.lastIndexOf('/') + 1
      )
      const docRef = doc(db, 'documents', result)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let data = docSnap.data()
        data.id = docSnap.id;
        (data.searchable || (inLibrary && inLibrary.includes(data.id))) && checkDates(data) && setDocument(data)
      }
    }

    if (!realDoc && documentResult && documentResult.path) {
      getDocument()
    }
  }, [])

  function getDateString (date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date.toDate()).toLocaleDateString(undefined, options)
  }

  function getComboDateString (obj) {
    return getDateString(obj.startDate) + ' - ' + getDateString(obj.endDate)
  }

  const [src, setSrc] = useState(null)

  useEffect(() => {
    const fetchPdf = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade (db) {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME)
          }
        }
      })

      try {
        const cachedFile = await db.get(STORE_NAME, document.url)
        if (cachedFile) {
          // Convert Blob to object URL
          const objectURL = URL.createObjectURL(cachedFile)
          setSrc(objectURL)
        } else {
          const response = await fetch(document.url)
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`)
          }
          const blob = await response.blob()

          // Store the Blob in IndexedDB
          await db.put(STORE_NAME, blob, document.url)

          // Convert Blob to object URL
          const objectURL = URL.createObjectURL(blob)
          setSrc(objectURL)
        }
      } catch (error) {
        console.error('Error fetching PDF: ', error)
      }
    }
    if (document && document.url) {
      fetchPdf()
    }

    return () => {
      // Clean up the object URL when the component unmounts
      if (src) {
        URL.revokeObjectURL(src)
      }
    }
  }, [document])

  return (
    <>
      {document && (
        <Card
          key={document.id + title}
          sx={{
            maxWidth: 345,
            minWidth: 300,
            m: 1,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column'
          }}
          onClick={() =>
            navigate(
              '/view/' +
                (typeof document.customRoute !== 'undefined' &&
                document.customRoute.length > 0
                  ? document.customRoute
                  : document.id)
            )
          }
          className='searchCard'
        >
          {showPreview(document) ? (
            <div className='previewHolder'>
              <div
                className='previewCover'
                onClick={() =>
                  navigate(
                    '/view/' +
                      (typeof document.customRoute !== 'undefined' &&
                      document.customRoute.length > 0
                        ? document.customRoute
                        : document.id)
                  )
                }
              ></div>
              <iframe
                loading='lazy'
                className='previewFrame'
                title={'document ' + document.fileName + document.uid}
                src={src}
                style={{ height: '100px', width: '100%' }}
              />
            </div>
          ) : (
            <div className='previewHolder'>
              <div
                className='previewCover noAccessCode'
                onClick={() =>
                  navigate(
                    '/view/' +
                      (typeof document.customRoute !== 'undefined' &&
                      document.customRoute.length > 0
                        ? document.customRoute
                        : document.id)
                  )
                }
              >
                <img
                  alt='logo'
                  src={document.logoURL ? document.logoURL : communityLogo}
                />
                <span style={{ fontStyle: 'italic' }}>
                  Access Code Required
                </span>
              </div>
            </div>
          )}
          <CardHeader
            title={document.fileName}
            subheader={
              <>
                {document.displayName +
                  (document.hostName ? ` (${document.hostName})` : '')}
                <br />
                {getComboDateString(document.visibleDate)}
              </>
            }
            sx={{ p: 1 }}
          />
          <CardContent sx={{ p: 1 }}>
            {document.description && (
              <>
                <span style={{ fontWeight: 'bold' }}>Description:</span>
                <br />
                <span>
                  {document.description.substring(0, 100) +
                    (document.description.length > 100 ? '...' : '')}
                </span>
                <br />
                <br />
              </>
            )}
            <span style={{ fontWeight: 'bold' }}>
              {document.commentDate
                ? 'Comment Between:'
                : 'Available for Viewing'}
            </span>

            {document.commentDate && (
              <>
                <br />
                <span>{getComboDateString(document.commentDate)}</span>
              </>
            )}
          </CardContent>
          <CardActions>
            <Tooltip title='Comments'>
              <Button startIcon={<Comment />}>
                {document.comments ? document.comments.length : '0'}
              </Button>
            </Tooltip>
          </CardActions>
        </Card>
      )}
    </>
  )
}

export const SearchTileRow = ({ documents, title }) => {
  return (
    <>
      <h3>{title}</h3>
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: '90vw',
          gap: '8px',
          paddingRight: '10px'
        }}
      >
        {documents.map(document => (
          <SearchResultTile key={document.id + title} realDoc={document} />
        ))}
      </div>
    </>
  )
}

export default SearchResultTile
