import { Box } from '@mui/material'
import Loading from './Components/Helpers/Loading'

function App () {
  return (
    <Box sx={{width: '100px', m: '40px auto'}}>
      <Loading padding='50px 0'/>
    </Box>
  )
}

export default App
