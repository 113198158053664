import { useLocation, useNavigate } from 'react-router-dom'
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import ViewDoc from './ViewDoc'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  Close,
  Code,
  Comment,
  CommentsDisabled,
  ContentCopy,
  Edit,
  History,
  Info,
  IosShare,
  Login,
  Publish,
  Refresh
} from '@mui/icons-material'
import EmailShare from '../Email/EmailShare'
import { PaperComponent } from '../Helpers/Drag'
import DownloadButton from '../Document/DownloadButton'
import ViewCommentsUnder from './ViewCommentsUnder'
import Loading from '../Helpers/Loading'
import noData from '../../Icons/noData.jpg'
import warning from '../../Icons/warning.jpg'

const View = ({ uid, db, displayName, email, setSavedLocation }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const [document, setDocument] = useState(null)
  const [documentURL, setDocumentURL] = useState(null)
  const [alert, setAlert] = useState(null)
  const [isAllowedToView, setIsAllowedToView] = useState(false)
  const [isAllowedToComment, setIsAllowedToComment] = useState(false)
  const [isAllowedToCommentEver, setIsAllowedToCommentEver] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [comments, setComments] = useState([])
  const [accessCode, setAccessCode] = useState('')
  const [entryAccessCode, setEntryAccessCode] = useState('')
  const [entryAccessCodeConfirmed, setEntryAccessCodeConfirmed] = useState(true)
  const [showSignInAlert, setShowSignInAlert] = useState(false)

  const [pageNumber, setPageNumber] = useState(1)
  const [showInfo, setShowInfo] = useState(false)
  const [loading, setLoading] = useState(true)

  let today = Date.now()

  function getDate (date) {
    return new Date(date.toDate())
  }

  useEffect(() => {
    async function getDocument () {
      setLoading(true)
      var result = pathname.substring(pathname.lastIndexOf('/') + 1)
      const docRef = doc(db, 'documents', result)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let data = docSnap.data()
        data.id = docSnap.id
        setDocument(data)
        setDocumentURL(data.url)

        if (uid && email.length > 0 && displayName.length > 0) {
          await getDoc(doc(db, 'users', uid)).then(val => {
            let data2 = val.data()
            if (!data2.visited || data2.visited.length < 2) {
              setShowInfo(true)
            }
          })
          await setDoc(
            doc(db, 'users', uid),
            {
              visited: arrayUnion(data.id)
            },
            { merge: true }
          )
          await setDoc(
            doc(db, 'documents', data.id),
            {
              visited: data.visited ? data.visited + 1 : 1,
              visitedBy: arrayUnion({
                uid: uid,
                displayName: displayName,
                email: email
              })
            },
            { merge: true }
          )
          setLoading(false)
          setIsAllowedToCommentEver(true)

          setShowSignInAlert(false)
          setTimeout(() => {
            setShowSignInAlert(false)
          }, 2000)
        } else {
          setLoading(false)
          setIsAllowedToCommentEver(false)
        }
      } else {
        let q1 = query(
          collection(db, 'documents'),
          where('customRoute', '==', result)
        )
        const querySnapshot = await getDocs(q1)
        if (querySnapshot.docs.length === 0) {
          setLoading(false)
          setAlert('No such document!')
        } else {
          querySnapshot.forEach(async docSnap => {
            let data = docSnap.data()
            data.id = docSnap.id
            setDocument(data)
            setDocumentURL(data.url)

            if (uid && email.length > 0 && displayName.length > 0) {
              await getDoc(doc(db, 'users', uid)).then(val => {
                let data2 = val.data()
                if (!data2.visited || data2.visited.length < 2) {
                  setShowInfo(true)
                }
              })
              await setDoc(
                doc(db, 'users', uid),
                {
                  visited: arrayUnion(data.id)
                },
                { merge: true }
              )
              await setDoc(
                doc(db, 'documents', data.id),
                {
                  visited: data.visited ? data.visited + 1 : 1,
                  visitedBy: arrayUnion({
                    uid: uid,
                    displayName: displayName,
                    email: email
                  })
                },
                { merge: true }
              )
              setLoading(false)
              setIsAllowedToCommentEver(true)

              setShowSignInAlert(false)
              setTimeout(() => {
                setShowSignInAlert(false)
              }, 2000)
            } else {
              setLoading(false)
              setIsAllowedToCommentEver(false)
            }
          })
        }
      }
    }

    if (uid && email.length > 0 && displayName.length > 0) {
      setIsAllowedToCommentEver(true)
    }
    setDocument(null)
    setDocumentURL(null)
    getDocument()
  }, [uid, displayName, email, pathname])

  useEffect(() => {
    async function anonView () {
      if (!uid && email.length === 0 && displayName.length === 0 && document) {
        await setDoc(
          doc(db, 'documents', document.id),
          {
            visited: increment(1)
          },
          { merge: true }
        )
      }
    }

    setTimeout(() => {
      setShowSignInAlert(true)
    }, 2000)

    setTimeout(() => {
      anonView()
    }, 10000)
  }, [])

  function handleEntryAccessCode (e) {
    let val = e.target.value
    setEntryAccessCode(val)
  }

  async function setCommentDB (commentDB, comment, add = true) {
    setComments(commentDB)
    const docRef = doc(db, 'documents', document.id)
    if (add) {
      await setDoc(
        docRef,
        {
          comments: arrayUnion(comment),
          commentCount: increment(1)
        },
        { merge: true }
      )
      await setDoc(
        doc(db, 'users', uid),
        {
          commentedOn: arrayUnion(document.id)
        },
        { merge: true }
      )
    } else {
      await setDoc(
        docRef,
        {
          comments: arrayRemove(comment),
          commentCount: increment(-1)
        },
        { merge: true }
      )
    }
  }

  useEffect(() => {
    if (document) {
      setComments(document.comments || [])
      if (
        document.uid === uid ||
        (document.admins && document.admins.includes(uid))
      ) {
        setIsAdmin(true)
        setIsAllowedToComment(true)
        setIsAllowedToView(true)
        return
      }

      if (typeof document.draft === 'undefined' && document.draft === true) {
        navigate('/search')
      } else {
        if (document.viewCode) {
          setAccessCode(document.viewCode)
          if (
            document.accessApproved &&
            document.accessApproved.includes(uid)
          ) {
            setEntryAccessCodeConfirmed(true)
          } else {
            if (
              window.sessionStorage.getItem('accessCode') !== null &&
              window.sessionStorage.getItem('accessCode') === document.viewCode
            ) {
              setEntryAccessCodeConfirmed(true)
            } else {
              setEntryAccessCodeConfirmed(false)
            }
          }
        }

        if (
          today >= getDate(document.visibleDate.startDate) &&
          today <= getDate(document.visibleDate.endDate)
        ) {
          setIsAllowedToView(true)
          if (document.commentDate) {
            if (
              today >= getDate(document.commentDate.startDate) &&
              today <= getDate(document.commentDate.endDate)
            ) {
              setIsAllowedToComment(true)
            }
          }
        }
      }
    } else {
      setIsAdmin(false)
      setIsAllowedToComment(false)
      setIsAllowedToView(false)
      setComments([])
    }
  }, [document])

  function removeComment (comment) {
    setCommentDB(
      comments.filter(val => val.id !== comment.id),
      comment,
      false
    )
  }

  return (
    <Stack sx={{ pb: 2 }}>
      {alert && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            width: { md: '50%', xs: '90%' },
            margin: 'auto',
            mt: 6
          }}
        >
          <img
            src={noData}
            alt='Cannot Find Document'
            style={{ borderRadius: '4px', width: '100%' }}
          />
          <Typography component='h3' sx={{ fontWeight: 'bold' }}>
            Cannot Find Document
          </Typography>
        </Box>
      )}
      {loading && <Loading padding='50px 0' margin='auto' />}
      {!loading && document && documentURL && (
        <>
          <ViewTopCard
            db={db}
            uid={uid}
            accessCode={accessCode}
            entryAccessCode={entryAccessCode}
            setEntryAccessCodeConfirmed={setEntryAccessCodeConfirmed}
            entryAccessCodeConfirmed={entryAccessCodeConfirmed}
            handleEntryAccessCode={handleEntryAccessCode}
            document={document}
          />
          {document.commentDate && entryAccessCodeConfirmed && (
            <>
              {!(
                today >= getDate(document.commentDate.startDate) &&
                today <= getDate(document.commentDate.endDate)
              ) &&
              today >= getDate(document.visibleDate.startDate) &&
              today <= getDate(document.visibleDate.endDate) ? (
                <Box
                  sx={{
                    m: { md: '10px auto', xs: 'auto' },
                    width: { md: '90vw', xs: '95%' },
                    p: { md: '0', xs: '0' }
                  }}
                >
                  <Alert
                    variant='filled'
                    severity='error'
                    sx={{ alignItems: 'center' }}
                    icon={<Comment fontSize='inherit' />}
                  >
                    Commenting Period Has Ended
                  </Alert>
                </Box>
              ) : (
                <>
                  {' '}
                  {showSignInAlert && !isAllowedToCommentEver && !uid && (
                    <Box
                      sx={{
                        m: { md: '10px auto', xs: 'auto' },
                        width: { md: '90vw', xs: '95%' },
                        p: { md: '0', xs: '0' }
                      }}
                    >
                      <Alert
                        variant='filled'
                        severity='error'
                        sx={{ alignItems: 'center' }}
                        icon={<Login fontSize='inherit' />}
                        action={
                          <Stack alignItems='center' direction='row'>
                            <Button
                              color='inherit'
                              onClick={() => {
                                setSavedLocation(window.location.pathname)
                                navigate('/signup')
                              }}
                              size='small'
                            >
                              Sign Up
                            </Button>
                            <IconButton
                              color='inherit'
                              onClick={() => setShowSignInAlert(false)}
                            >
                              <Close />
                            </IconButton>
                          </Stack>
                        }
                      >
                        Sign Up to Comment
                      </Alert>
                    </Box>
                  )}
                </>
              )}
            </>
          )}

          {!(
            today >= getDate(document.visibleDate.startDate) &&
            today <= getDate(document.visibleDate.endDate)
          ) && (
            <Box
              sx={{
                m: { md: '10px auto', xs: 'auto' },
                width: { md: '90vw', xs: '95%' },
                p: { md: '0', xs: '0' },
                textAlign: 'center',
                gap: { md: '24px', xs: '16px' },
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Alert
                variant='filled'
                severity='error'
                sx={{ alignItems: 'center' }}
                icon={<History fontSize='inherit' />}
              >
                Viewing Period Has Ended
              </Alert>
              {!isAdmin && (
                <img
                  src={warning}
                  alt='Viewing Period Has Ended'
                  style={{
                    borderRadius: '4px',
                    width: 'max(40%, 250px)',
                    margin: 'auto'
                  }}
                />
              )}
            </Box>
          )}

          {isAllowedToView && entryAccessCodeConfirmed && (
            <ViewDoc
              url={documentURL}
              fileName={document.fileName}
              uid={uid}
              document={document}
              comments={isAllowedToCommentEver ? comments : []}
              setComments={setCommentDB}
              isAllowedToComment={
                typeof document.commentDate !== 'undefined' &&
                isAllowedToCommentEver &&
                isAllowedToComment
              }
              commentVisibility={document.commentVisibility}
              displayName={displayName}
              db={db}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              showInfo={showInfo}
              setShowInfo={setShowInfo}
            />
          )}
          {isAllowedToCommentEver &&
            typeof document.commentDate !== 'undefined' &&
            entryAccessCodeConfirmed &&
            ((today >= getDate(document.commentDate.startDate) &&
              today <= getDate(document.commentDate.endDate)) ||
              isAdmin) &&
            comments.length > 0 && (
              <ViewCommentsUnder
                comments={comments}
                uid={uid}
                document={document}
                removeComment={removeComment}
                db={db}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                displayName={displayName}
                isAllowedToComment={
                  isAllowedToCommentEver && isAllowedToComment
                }
              />
            )}
        </>
      )}
    </Stack>
  )
}

const ViewTopCard = ({
  db,
  uid,
  accessCode,
  entryAccessCode,
  setEntryAccessCodeConfirmed,
  entryAccessCodeConfirmed,
  handleEntryAccessCode,
  document
}) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showEmbedDialog, setShowEmbedDialog] = useState(false)
  const [showAccessCodeFailed, setShowAccessCodeFailed] = useState(false)

  async function checkViewCode () {
    const accessApproved = accessCode === entryAccessCode
    setEntryAccessCodeConfirmed(accessApproved)
    setShowAccessCodeFailed(!accessApproved)
    if (accessApproved && uid) {
      const docRef = doc(db, 'documents', document.id)
      await setDoc(
        docRef,
        {
          accessApproved: arrayUnion(uid)
        },
        { merge: true }
      )
    }
    if (accessApproved) {
      window.sessionStorage.setItem('accessCode', entryAccessCode)
    }
  }

  function getDateString (date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date.toDate()).toLocaleDateString(undefined, options)
  }

  function getComboDateString (obj) {
    return getDateString(obj.startDate) + ' - ' + getDateString(obj.endDate)
  }

  function isDraftCheck (document) {
    return typeof document.draft !== 'undefined' && document.draft === true
  }

  useEffect(() => {
    window.document.title = document.fileName
  }, [document])

  return (
    <Box
      sx={{
        m: { md: '16px auto', xs: '0 0 16px' },
        width: { md: '90vw', xs: '100%' }
      }}
    >
      <Card raised>
        <CardHeader
          action={
            <Stack
              direction='row'
              useFlexGap
              flexWrap='wrap'
              sx={{
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              {window.innerWidth < 800 && (
                <Tooltip title='Reload Page'>
                  <IconButton
                    aria-label='reload'
                    onClick={() => window.location.reload()}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title='More Document Information'>
                <IconButton
                  aria-label='info'
                  onClick={() => setShowInfoModal(true)}
                >
                  <Info />
                </IconButton>
              </Tooltip>
              {typeof document.commentDate === 'undefined' && (
                <Tooltip title='Comments Turned Off'>
                  <IconButton>
                    <CommentsDisabled />
                  </IconButton>
                </Tooltip>
              )}
              <Dialog
                open={showInfoModal}
                onClose={() => setShowInfoModal(false)}
                PaperComponent={PaperComponent}
                aria-labelledby='draggable-dialog-title'
                fullWidth
              >
                <DialogTitle style={{ cursor: 'move' }}>
                  {document.fileName} Information
                </DialogTitle>
                <DialogContent>
                  <Stack spacing={2}>
                    <Typography component='h5'>
                      <span style={{ fontWeight: '600' }}>
                        {'Organization: '}
                      </span>
                      {document.displayName}
                    </Typography>
                    {document.hostName && (
                      <Typography component='h5'>
                        <span style={{ fontWeight: '600' }}>{'Host: '}</span>{' '}
                        {document.hostName}
                      </Typography>
                    )}
                    {document.description && document.description.length > 0 && (
                      <Typography component='h5'>
                        <span style={{ fontWeight: '600' }}> Description:</span>{' '}
                        {document.description}
                      </Typography>
                    )}
                    {document.contactInformation &&
                      document.contactInformation.length > 0 && (
                        <>
                          <Typography component='h5'>
                            <span style={{ fontWeight: '600' }}>
                              {'Contact Information: '}
                            </span>
                            {document.contactInformation}
                          </Typography>
                        </>
                      )}
                    {document.contactLink && (
                      <>
                        <Typography component='h5'>
                          <span style={{ fontWeight: '600' }}>
                            {'Contact Link: '}
                          </span>
                          <Link
                            href={document.contactLink}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {document.contactLink}
                          </Link>
                        </Typography>
                      </>
                    )}
                    <Typography component='h5'>
                      <span style={{ fontWeight: '600' }}>{'Live: '}</span>
                      {getComboDateString(document.visibleDate)}
                    </Typography>
                    {document.commentDate && (
                      <>
                        <Typography component='h5'>
                          <span style={{ fontWeight: '600' }}>
                            {'Comment Between: '}
                          </span>
                          {getComboDateString(document.commentDate)}
                        </Typography>
                        <Typography component='h5' sx={{ fontStyle: 'italic' }}>
                          {!document.commentVisibility
                            ? 'Users can only see their own comments'
                            : "Users can see everyone's comments"}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant='outlined'
                    onClick={() => setShowInfoModal(false)}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {(document.uid === uid ||
                (document.admins && document.admins.includes(uid))) &&
                window === window.parent && (
                  <>
                    {isDraftCheck(document) ? (
                      <Tooltip title='Finish Document Upload'>
                        <IconButton
                          aria-label='edit draft'
                          onClick={() => navigate('/draft/' + document.id)}
                        >
                          <Publish />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title='Edit Document'>
                        <IconButton
                          aria-label='edit'
                          onClick={() => navigate('/edit/' + document.id)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!isDraftCheck(document) && (
                      <>
                        <DownloadButton document={document} db={db} />
                        <Tooltip title='Share'>
                          <IconButton
                            aria-label='share'
                            onClick={() => setShowModal(true)}
                          >
                            <IosShare />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          open={showModal}
                          onClose={() => setShowModal(false)}
                          PaperComponent={PaperComponent}
                          aria-labelledby='draggable-dialog-title'
                          fullWidth
                        >
                          <EmailShare
                            setShowModal={setShowModal}
                            db={db}
                            id={document.id}
                            viewCode={document.viewCode || null}
                            fileName={document.fileName}
                            customURL={document.customRoute || null}
                          />
                        </Dialog>
                        <Tooltip title='Embed'>
                          <IconButton onClick={() => setShowEmbedDialog(true)}>
                            <Code />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          open={showEmbedDialog}
                          onClose={() => setShowEmbedDialog(false)}
                          PaperComponent={PaperComponent}
                          aria-labelledby='draggable-dialog-title'
                          fullWidth
                        >
                          <Embed
                            id={document.id}
                            setShow={setShowEmbedDialog}
                          />
                        </Dialog>
                      </>
                    )}
                  </>
                )}
            </Stack>
          }
          avatar={
            document.logoURL ? (
              <img
                alt='document logo'
                src={document.logoURL}
                style={{
                  height: '100px',
                  marginRight: '10px',
                  maxWidth: '30vw',
                  objectFit: 'contain'
                }}
              />
            ) : undefined
          }
          title={
            <>
              {document.fileName}
              <Chip
                label='DRAFT (Only Visible to Admins)'
                color='error'
                sx={{
                  ml: 2,
                  display: isDraftCheck(document) ? '' : 'none'
                }}
              />
            </>
          }
          subheader={
            document.displayName +
            (document.hostName ? ' (' + document.hostName + ') ' : '')
          }
          className='viewCardHeader'
        />
        <CardContent className='viewCardDetail'>
          <Stack spacing={2}>
            {document.description && document.description.length > 0 && (
              <Typography component='h5'>
                <span style={{ fontWeight: '600' }}> Description:</span>
                <br />
                {document.description}
              </Typography>
            )}
            {document.contactInformation &&
              document.contactInformation.length > 0 && (
                <>
                  <Typography component='h5'>
                    <span style={{ fontWeight: '600' }}>
                      {'Contact Information: '}
                    </span>
                    {document.contactInformation}
                  </Typography>
                </>
              )}
            {document.contactLink && (
              <>
                <Typography component='h5'>
                  <span style={{ fontWeight: '600' }}>{'Contact Link: '}</span>
                  <Link
                    href={document.contactLink}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {document.contactLink}
                  </Link>
                </Typography>
              </>
            )}
            {!entryAccessCodeConfirmed && (
              <>
                <Divider component='div' sx={{ width: '100%' }} />
                {showAccessCodeFailed && (
                  <Alert
                    severity='error'
                    onClose={() => {
                      setShowAccessCodeFailed(false)
                    }}
                    sx={{ mb: 1 }}
                  >
                    Looks like you used the wrong access code. Access code is
                    case sensitive. Please try again or reach out to the
                    document creators.
                  </Alert>
                )}
                <Stack direction='row' spacing={1} sx={{ mt: '20px' }}>
                  <TextField
                    id='accessCode'
                    variant='outlined'
                    label='Access Code'
                    onChange={handleEntryAccessCode}
                    value={entryAccessCode}
                    onKeyUp={e => e.key === 'Enter' && checkViewCode()}
                    sx={{ width: 400 }}
                    required
                  />

                  <Button
                    variant='contained'
                    onClick={checkViewCode}
                    disabled={entryAccessCode === ''}
                  >
                    Access
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

const Embed = ({ id, setShow }) => {
  const [showAlert, setShowAlert] = useState(false)

  function copy () {
    navigator.clipboard.writeText(
      '<iframe src="' +
        window.location.origin +
        '/view/' +
        id +
        '" style="width: 100%; height: 100%; margin: 8px;"></iframe>'
    )
    setShowAlert(true)
  }
  return (
    <>
      <DialogTitle>Embed Document</DialogTitle>
      <DialogContent>
        <Typography variant='span' component='div' sx={{ mb: 2 }}>
          Add code to your website and allow users to interact with your
          document right on your website!
          <br />
          <br />
          Admin actions will be restricted, and Community Document features like
          search, profile, upload and dashboard will be disabled.
        </Typography>
        {showAlert && (
          <Alert
            variant='filled'
            severity='success'
            onClose={() => {
              setShowAlert(false)
            }}
            sx={{ mb: 1 }}
          >
            Copied to Clipboard!
          </Alert>
        )}
        <TextField
          label='Embedding Code'
          multiline
          fullWidth
          rows={4}
          sx={{ mt: 2 }}
          variant='outlined'
          value={
            '<iframe src="' +
            window.location.origin +
            '/view/' +
            id +
            '" style="width: 100%; height: 100%; margin: 8px;"></iframe>'
          }
          disabled={true}
        />
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ContentCopy />}
          onClick={copy}
          variant='contained'
          color='success'
        >
          Copy
        </Button>
        <Button onClick={() => setShow(false)}>Close</Button>
      </DialogActions>
    </>
  )
}

export default View
