import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import GoogleLogo from '../../Icons/google.svg'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Tooltip,
  Box,
  Tabs,
  Tab
} from '@mui/material'
import { PaperComponent } from '../Helpers/Drag'
import { doc, getDoc, setDoc } from '@firebase/firestore'
import SignInToView from './SignInToView'
import Document from '../../Icons/document.jpg'
import { ReportGmailerrorred } from '@mui/icons-material'
import { logEvent } from 'firebase/analytics'

const SignIn = ({
  auth,
  savedLocation,
  db,
  setDisplayName,
  setUserType,
  analytics
}) => {
  const provider = new GoogleAuthProvider()
  const [email, setEmail] = useState('')
  const [email2, setEmail2] = useState('')
  const [password, setPassword] = useState('')
  const [alert, setAlert] = useState(null)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const navigate = useNavigate()
  const [tab, setTab] = useState(1)
  const [goToLocation, setGoToLocation] = useState('')

  useEffect(() => {
    if (savedLocation.includes('/sign')) {
      setGoToLocation('/search')
    } else {
      setGoToLocation(savedLocation)
    }
  }, [savedLocation])

  const handleChange = (event, newValue) => {
    setAlert(null)
    setTab(newValue)
  }

  function getEmailPrefix (email) {
    var parts = email.split('@')
    return parts[0]
  }

  function signIn () {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        logEvent(analytics, 'sign_in')
        navigate(goToLocation)
      })
      .catch(() => {
        setAlert(
          'Something went wrong, please try again. Do you need to make an account?'
        )
      })
  }

  function signUpAccount () {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        logEvent(analytics, 'sign_in')
        navigate(goToLocation)
      })
      .catch(() => {
        createUserWithEmailAndPassword(auth, email, password)
          .then(async userCredential => {
            const user = userCredential.user
            setDisplayName(getEmailPrefix(email))
            logEvent(analytics, 'sign_up')
            await setDoc(
              doc(db, 'users', user.uid),
              {
                displayName: getEmailPrefix(email),
                email: email,
                lastLogin: Date.now(),
                paid: false,
                documentCount: 0
              },
              { merge: true }
            )
            setUserType(false)
            navigate(goToLocation)
          })
          .catch(() => {
            setAlert('Something went wrong, please try again.')
          })
      })
  }

  function sendForgotPasswordEmail () {
    sendPasswordResetEmail(auth, email2)
      .then(() => {
        setAlert('Password reset email was sent.')
      })
      .catch(() => {
        setAlert(
          'We could not find your email. Sign up for an account or retry.'
        )
      })
    setShowForgotPassword(false)
  }

  function signInGoogle () {
    signInWithPopup(auth, provider)
      .then(async result => {
        const user = result.user
        let userRef = doc(db, 'users', user.uid)
        let userDoc = await getDoc(userRef)
        if (!userDoc.exists()) {
          logEvent(analytics, 'sign_up')
          let userRef = doc(db, 'users', user.uid)
          await setDoc(
            userRef,
            {
              email: user.email,
              displayName: getEmailPrefix(user.email),
              lastLogin: Date.now(),
              paid: false,
              documentCount: 0
            },
            { merge: true }
          ).then(() => {
            setUserType(false)
            setDisplayName(getEmailPrefix(user.email))
            navigate(goToLocation)
          })
        } else {
          logEvent(analytics, 'sign_in')
          navigate(goToLocation)
        }
      })
      .catch(() => {
        setAlert('We ran into an issue, please try again.')
      })
  }
  const handleEmail = event => {
    const value = event.target.value
    setEmail(value)
  }
  const handleEmail2 = event => {
    const value = event.target.value
    setEmail2(value)
  }
  const handlePassword = event => {
    const value = event.target.value
    setPassword(value)
  }

  return (
    <Stack sx={{ pb: 2 }}>
      <Card
        raised
        sx={{
          width: 'min(500px, 95%)',
          margin: '20px auto',
          p: { md: 6, xs: 3 }
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={1}
        >
          <SignInToView db={db} savedLocation={savedLocation} />
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
              mb: 1
            }}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label='basic tabs example'
              variant='fullWidth'
            >
              <Tab label='Sign In' value={0} />
              <Tab label='Sign Up' value={1} />
            </Tabs>
          </Box>
          {tab === 0 ? (
            <h3>Existing Account</h3>
          ) : (
            <h3>Create a New Account</h3>
          )}
          {alert !== null && (
            <Alert
              variant='filled'
              severity={
                alert === 'Password reset email was sent.' ? 'success' : 'error'
              }
            >
              {alert}
            </Alert>
          )}
          <TextField
            id='email'
            label='Email'
            variant='filled'
            onChange={handleEmail}
            value={email}
            required
            fullWidth
          />
          <TextField
            id='password'
            label='Password'
            variant='filled'
            type='password'
            onChange={handlePassword}
            required
            value={password}
            fullWidth
            error={password.length < 6 && password.length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  {password.length < 6 && password.length > 0 ? (
                    <Tooltip title={'Password must be 6 characters or longer.'}>
                      <ReportGmailerrorred color='error' />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              )
            }}
          />
          <Stack
            spacing={2}
            direction='row'
            useFlexGap
            flexWrap='wrap'
            justifyContent='center'
          >
            {tab === 0 ? (
              <Button
                variant='contained'
                disabled={email.length === 0 || password.length < 6}
                onClick={signIn}
                sx={{ width: '200px' }}
              >
                Sign In
              </Button>
            ) : (
              <Button
                variant='contained'
                sx={{ width: '200px' }}
                onClick={signUpAccount}
                disabled={email.length === 0 || password.length < 6}
              >
                Sign Up
              </Button>
            )}
            <Button variant='text' onClick={signInGoogle}>
              <img
                src={GoogleLogo}
                alt='Google Sign In'
                style={{ objectFit: 'contain', width: '50px' }}
              />
            </Button>
          </Stack>
          <span
            style={{
              marginBottom: '10px',
              fontSize: '0.75em',
              textAlign: 'center'
            }}
          >
            Requiring sign-ins for commenting helps maintain a respectful and
            accountable community, fostering meaningful interactions while
            deterring spam and inappropriate behavior.
          </span>
          {tab === 0 && (
            <Button
              sx={{ textDecoration: 'underline' }}
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot Password?
            </Button>
          )}
          <Button onClick={() => navigate('/signUpAdmin')}>
            {'Admin Sign Up'}
          </Button>

          <Dialog
            open={showForgotPassword}
            onClose={() => setShowForgotPassword(false)}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
          >
            <DialogTitle style={{ cursor: 'move' }}>
              Forgot Password
            </DialogTitle>
            <DialogContent>
              <TextField
                id='email'
                label='Email'
                variant='filled'
                onChange={handleEmail2}
                value={email2}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => setShowForgotPassword(false)}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={sendForgotPasswordEmail}
                disabled={email2.length === 0}
                color='success'
                variant='contained'
              >
                Send Email
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Card>

      <img
        src={Document}
        alt='Collaborate on Documents/PDFs'
        style={{
          borderRadius: '4px',
          width: '50%',
          margin: 'auto',
          maxWidth: '600px'
        }}
      />
    </Stack>
  )
}

export default SignIn
