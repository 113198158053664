import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import communityLogo from '../../Icons/logo.png'
import { Alert, Stack } from '@mui/material'

const SignInToView = ({ db, savedLocation, signIn = true }) => {
  const [document, setDocument] = useState(null)

  useEffect(() => {
    async function getDocument () {
      var result = savedLocation.substring(savedLocation.lastIndexOf('/') + 1)
      const docRef = doc(db, 'documents', result)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let data = docSnap.data()
        data.id = docSnap.id
        setDocument(data)
      } else {
        let q1 = query(
          collection(db, 'documents'),
          where('customRoute', '==', result)
        )
        const querySnapshot = await getDocs(q1)
        querySnapshot.forEach(docSnap2 => {
          let data = docSnap2.data()
          data.id = docSnap2.id
          setDocument(data)
        })
      }
    }

    if (savedLocation.includes('/view')) {
      getDocument()
    }
  }, [savedLocation])

  return (
    <>
      {document ? (
        <Stack sx={{ width: '100%' }}>
          {document.logoURL ? (
            <img
              alt='logo'
              src={document.logoURL}
              style={{ height: '100px', margin: '10px', objectFit: 'contain' }}
            />
          ) : (
            <img
              alt='logo'
              src={communityLogo}
              style={{ maxWidth: '100%', objectFit: 'contain' }}
            />
          )}
          <Alert
            severity='info'
            icon={false}
            sx={{
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '1.25em'
            }}
          >
            <p style={{ margin: 0 }}>
              {'Sign up to view and comment on'}
              <br />
              <b>{document.fileName}</b>
            </p>
          </Alert>
        </Stack>
      ) : (
        <img
          alt='logo'
          src={communityLogo}
          style={{ maxWidth: '100%', objectFit: 'contain' }}
        />
      )}
    </>
  )
}

export default SignInToView
