import { Button, Card, Stack } from '@mui/material'
import '../../Style/Footer.css'
import communityLogo from '../../Icons/logo.png'
import { useNavigate } from 'react-router-dom'
import { DarkMode, LightMode } from '@mui/icons-material'

const Footer = ({ dark, setDark }) => {
  const navigate = useNavigate()

  return (
    <Card raised sx={{ m: 0 }}>
      <Card className='footer'>
        <img
          alt='logo'
          src={communityLogo}
          style={{
            maxWidth: '100%',
            objectFit: 'contain',
            height: '60px',
            margin: 'auto',
            marginBottom: '16px',
            borderRadius: '4px'
          }}
        />
        <Stack
          direction='row'
          sx={{
            justifyContent: 'space-between',
            width: '90%',
            m: 'auto',
            flexWrap: 'wrap',
            gap: 6
          }}
        >
          <Stack>
            <span className='footerLinkHeader'>Support</span>
            <span
              tabIndex='0'
              className='footerLinks'
              onClick={() => navigate('/support/help')}
            >
              Help
            </span>
            <span
              tabIndex='0'
              className='footerLinks'
              onClick={() => navigate('/support/contact')}
            >
              Contact Us
            </span>
          </Stack>
          <Stack>
            <span className='footerLinkHeader'>Company</span>
            <span
              tabIndex='0'
              className='footerLinks'
              onClick={() => navigate('/support/about')}
            >
              About
            </span>
          </Stack>
          <Stack>
            <span className='footerLinkHeader'>Terms & Privacy</span>
            <span
              tabIndex='0'
              className='footerLinks'
              onClick={() => navigate('/support/termsofuse')}
            >
              Terms of Use
            </span>
            <span
              tabIndex='0'
              className='footerLinks'
              onClick={() => navigate('/support/privacy')}
            >
              Privacy
            </span>
          </Stack>

          <Button
            onClick={() => {
              window.sessionStorage.setItem('theme', !dark ? '0' : '1')
              setDark(!dark)
            }}
            color='inherit'
            startIcon={dark ? <DarkMode /> : <LightMode />}
            sx={{ height: 'fit-content' }}
          >
            Toggle Theme
          </Button>
        </Stack>
      </Card>
    </Card>
  )
}

export default Footer
