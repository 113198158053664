import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Upload from './Upload'

const Edit = ({ storage, uid, db, userEmail, displayUserName, isDraft, isCopy = false }) => {
  const location = useLocation()
  const { pathname } = location
  const [document, setDocument] = useState(null)

  useEffect(() => {
    async function getDocument () {
      var result = pathname.substring(pathname.lastIndexOf('/') + 1)
      const docRef = doc(db, 'documents', result)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let data = docSnap.data()
        data.id = docSnap.id
        setDocument(data)
      }
    }

    getDocument()
  }, [pathname, uid])

  return (
    <>
      {document && (
        <Upload
          storage={storage}
          db={db}
          uid={uid}
          document={document}
          userEmail={userEmail}
          displayUserName={displayUserName}
          isDraft={isDraft}
          isCopy={isCopy}
        />
      )}
    </>
  )
}

export default Edit
