import { useEffect, useState } from 'react'
import Loading from './Loading'
import { Box } from '@mui/material'

const NoPage = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 2000)
  }, [])

  return (
    <Box sx={{ width: '100vw' }}>
      <Box sx={{ m: 'auto', width: 'max-content' }}>
        {show ? (
          <h1>Page Not Found</h1>
        ) : (
          <Loading margin='auto' padding='50px 0' />
        )}
      </Box>
    </Box>
  )
}

export default NoPage
