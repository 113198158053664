import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Tooltip
} from '@mui/material'
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where
} from 'firebase/firestore'
import { getMetadata, listAll, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SuperAdmin = ({ uid, db, storage }) => {
  const [documents, setDocuments] = useState({})
  const [next, setNext] = useState(true)
  const navigate = useNavigate()
  const [lastVisibleUser, setLastVisibleUser] = useState(null)
  const limitLength = 10 //this is max

  const getQuery = lastVisible => {
    return lastVisible
      ? query(
          collection(db, 'users'),
          where('paid', '==', true),
          orderBy('documentCount', 'desc'),
          startAfter(lastVisible),
          limit(limitLength)
        )
      : query(
          collection(db, 'users'),
          where('paid', '==', true),
          orderBy('documentCount', 'desc'),
          limit(limitLength)
        )
  }

  async function getPaidUsers () {
    let first = getQuery(null)
    const documentSnapshots = await getDocs(first)
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]
    if (documentSnapshots.docs.length === limitLength) {
      setLastVisibleUser(lastVisible)
    } else {
      setNext(false)
    }
    const sortable = await getDocumentsFromIds(documentSnapshots)
    setDocuments(sortable)
  }

  async function getNewPaidUsers () {
    const documentSnapshots = await getDocs(getQuery(lastVisibleUser))
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]
    if (documentSnapshots.length === limitLength) {
      setLastVisibleUser(lastVisible)
    } else {
      setNext(false)
    }
    const sortable = await getDocumentsFromIds(documentSnapshots)
    setDocuments(sortable)
  }

  async function getDocumentsFromIds (userSnap) {
    let userDocs = documents
    let ids = []
    userSnap.forEach(doc => {
      ids.push(doc.id)
      userDocs[doc.id] = {
        name: doc.data().displayName,
        email: doc.data().email,
        docs: []
      }
    })

    let q = query(collection(db, 'documents'), where('uid', 'in', ids))
    const querySnapshot = await getDocs(q)
    let docs = []
    querySnapshot.forEach(doc => {
      let val = doc.data()
      val.id = doc.id
      docs.push(val)
    })
    docs.forEach(val => {
      if (
        userDocs[val.uid].docs.filter(val2 => val2.id === val.id).length === 0
      ) {
        userDocs[val.uid].docs = [...userDocs[val.uid].docs, val]
      }
    })
    const sortable = Object.entries(userDocs)
      .sort(([, a], [, b]) => b.docs.length - a.docs.length)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
    return sortable
  }

  useEffect(() => {
    setDocuments({})
    setNext(true)
    setLastVisibleUser(null)
    if (db && storage) {
      if (uid !== 'ofOJBZqp7WdrpYYYPV16KAbotsd2') {
        navigate('/search')
      } else {
        getPaidUsers()
      }
    }
  }, [uid, db, storage])

  return (
    <Box sx={{ m: { md: 2, xs: 0 }, minHeight: '95%', pb: 2 }}>
      <div style={{ width: '90vw', margin: '10px auto' }}>
        <h1>Super Admin</h1>
        <Stack spacing={2}>
          {Object.keys(documents).map(key => (
            <PersonInfoView
              key={key}
              id={key}
              storage={storage}
              document={documents[key]}
            />
          ))}
        </Stack>
        <Button
          variant='contained'
          sx={{ mt: 2 }}
          disabled={!next}
          onClick={getNewPaidUsers}
        >
          More
        </Button>
      </div>
    </Box>
  )
}

const PersonInfoView = ({ id, storage, document }) => {
  const [fileInfo, setFileInfo] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    files(id)
  }, [id])

  const highestVisit = () => {
    return document.docs.sort((a, b) => b.visited - a.visited)[0]
  }

  const highestComment = () => {
    return document.docs.sort((a, b) => b.commentCount - a.commentCount)[0]
  }

  const ids = () => {
    return document.docs.map(val => createLink(val))
  }

  const files = async () => {
    const listRef = ref(storage, id)
    let totalSize = 0
    let logoSize = 0
    let fileSize = 0
    let biggestFile = 0
    let storageLink =
      'https://console.firebase.google.com/u/1/project/community-doc/storage/community-doc.appspot.com/files/~2F' +
      id

    try {
      const res = await listAll(listRef)
      await Promise.all(
        res.items.map(async itemRef => {
          const val = await getMetadata(ref(storage, itemRef._location.path_))
          totalSize += val.size
          if (itemRef._location.path_.includes('_logo_')) {
            logoSize += val.size
          } else {
            fileSize += val.size
            if (val.size > biggestFile) {
              biggestFile = val.size
            }
          }
        })
      )
    } catch (error) {
      console.error('Error occurred:', error)
    } finally {
      setFileInfo({
        totalSize: formatSize(totalSize),
        logoSize: formatSize(logoSize),
        fileSize: formatSize(fileSize),
        biggestFile: formatSize(biggestFile),
        storageLink: storageLink
      })
    }
  }

  function formatSize (sizeInBytes) {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let size = sizeInBytes
    let unitIndex = 0

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024
      unitIndex++
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`
  }

  function createLink (doc) {
    return (
      <Tooltip
        title={doc.fileName}
        key={doc.id + Math.floor(Math.random() * 10000)}
      >
        <Button
          onClick={() => navigate('/view/' + doc.id)}
          size='small'
          variant='contained'
          sx={{ m: 1 }}
        >
          {doc.id}
        </Button>
      </Tooltip>
    )
  }

  return (
    <Card raised>
      <CardHeader
        title={document.name + ' (' + document.email + ')'}
        subheader={id + ' (' + document.docs.length + ' documents)'}
      />
      {document.docs.length > 0 && (
        <CardContent>
          {fileInfo && (
            <div>
              <span>Total Size: {fileInfo.totalSize}</span>
              <br />
              <span>File Sizes: {fileInfo.fileSize}</span>
              <br />
              <span>Logo Sizes: {fileInfo.logoSize}</span>
              <br />
              <span>Biggest File: {fileInfo.biggestFile}</span>
              <br />
              <Link href={fileInfo.storageLink} rel="noreferrer" target='_blank'>
                Storage Link{' '}
              </Link>
            </div>
          )}
          <br />
          <span>
            Visited Count:{' '}
            {document.docs.reduce(
              (acc, val) => acc + (val.visited ? val.visited : 0),
              0
            )}
          </span>{' '}
          <br />
          <span>
            Highest Visit Count: {highestVisit(id).visited || 0} - ID:{' '}
            {createLink(highestVisit(id))}
          </span>
          <br />
          <span>
            Comment Count:{' '}
            {document.docs.reduce(
              (acc, val) => acc + (val.commentCount ? val.commentCount : 0),
              0
            )}
          </span>{' '}
          <br />
          <span>
            Highest Comment Count: {highestComment(id).commentCount || 0} - ID:{' '}
            {createLink(highestComment(id))}
          </span>
          <br />
          <br />
          <span>IDs:</span>
          <br />
          {ids(id)}
          <br />
        </CardContent>
      )}
      <CardActions></CardActions>
    </Card>
  )
}

export default SuperAdmin
