import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip
} from '@mui/material'
import CommentTypeIcon from './CommentTypeIcon'
import { useEffect, useState } from 'react'
import DownloadButton from '../Document/DownloadButton'
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  setDoc
} from 'firebase/firestore'
import { Delete, FileOpen, Reply, ThumbUp } from '@mui/icons-material'

const Replies = ({
  uid,
  document,
  currentComment,
  db,
  displayName,
  isAllowedToComment
}) => {
  const [replies, setReplies] = useState([])

  const [reply, setReply] = useState('')
  const [likes, setLikes] = useState(0)

  function handleReplyChange (e) {
    let value = e.target.value
    setReply(value)
  }

  async function addReply (type) {
    const d = new Date()
    let r = replies
    let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
    let newObj = {
      displayName: displayName,
      uid: uid,
      dateString: d.toDateString(),
      time: d,
      message: type === 'Like' ? '' : reply,
      messageType: type,
      id: document.id + '_' + uid + '_' + Math.floor(Math.random() * 10000),
      currentComment: currentComment.id
    }
    await setDoc(
      ref,
      {
        replies: arrayUnion(newObj)
      },
      { merge: true }
    )
    r.push(newObj)
    setReplies(r)
    if (type === 'Like') {
      setLikes(likes + 1)
    } else {
      setReply('')
    }
  }

  function addLike () {
    let r = replies
    if (
      r.filter(val => val.uid === uid && val.messageType === 'Like').length > 0
    ) {
      let oldObj = r.filter(
        val => val.uid === uid && val.messageType === 'Like'
      )[0]
      removeReply(oldObj, 'Like')
    } else {
      addReply('Like')
    }
  }

  async function removeReply (currentReply, type = 'Reply') {
    let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
    let r = replies
    let oldObj = r.filter(val => val.id === currentReply.id)[0]
    await setDoc(
      ref,
      {
        replies: arrayRemove(oldObj)
      },
      { merge: true }
    )
    r = r.filter(val => val.id !== currentReply.id)
    setReplies(r)
    if (type === 'Like') {
      setLikes(likes - 1)
    }
  }

  useEffect(() => {
    async function getReplies () {
      let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
      let snap = await getDoc(ref)
      if (snap.exists()) {
        let data = snap.data()
        setReplies(data.replies)
        setLikes(data.replies.filter(val => val.messageType === 'Like').length)
      } else {
        setReplies([])
        setLikes(0)
      }
    }
    if (db && document.id) {
      getReplies()
    } else {
      setReplies([])
      setLikes(0)
    }
  }, [document, currentComment, db])

  return (
    <>
      {replies.length > 0 &&
        replies
          .filter(val => {
            if (!document.commentVisibility) {
              return (
                val.uid === uid ||
                (document &&
                  (document.uid === uid ||
                    (document.admins && document.admins.includes(uid))))
              )
            } else {
              return true
            }
          })
          .filter(val => val.messageType !== 'Like')
          .map(currentReply => (
            <Card key={currentReply.id} sx={{ p: 1, m: 1 }}>
              <Stack spacing={2}>
                {currentReply.displayName
                  ? currentReply.displayName
                  : currentReply.uid}
                <span className='caption'>{currentReply.dateString}</span>
              </Stack>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <span style={{ overflowWrap: 'anywhere' }}>
                  {currentReply.messageType === 'Like'
                    ? 'Liked'
                    : currentReply.message}
                </span>

                {isAllowedToComment &&
                  (uid === currentReply.uid ||
                    uid === document.uid ||
                    (document.admins && document.admins.includes(uid))) && (
                    <Tooltip title='Remove Reply'>
                      <IconButton
                        onClick={() => removeReply(currentReply)}
                        color='error'
                        sx={{ width: 'min-content' }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
              </Stack>
            </Card>
          ))}

      {isAllowedToComment && (
        <Stack
          direction='row'
          spacing={2}
          sx={{
            width: 'auto',
            alignSelf: 'center',
            m: '0 -8px'
          }}
        >
          <TextField
            id='outlined-multiline-static'
            label='Reply...'
            fullWidth
            variant='standard'
            value={reply}
            onChange={handleReplyChange}
            onKeyUp={e => e.key === 'Enter' && addReply('Reply')}
          />
          {reply.length === 0 ? (
            <Button
              variant='contained'
              disabled={reply.length === 0}
              onClick={() => addReply('Reply')}
            >
              <Reply />
            </Button>
          ) : (
            <Tooltip title='Reply'>
              <Button
                variant='contained'
                disabled={reply.length === 0}
                onClick={() => addReply('Reply')}
              >
                <Reply />
              </Button>
            </Tooltip>
          )}

          <Tooltip title='Like/Unlike'>
            <Button
              startIcon={<ThumbUp />}
              variant={
                replies.filter(
                  val => val.messageType === 'Like' && val.uid === uid
                ).length > 0
                  ? 'contained'
                  : 'outlined'
              }
              onClick={addLike}
            >
              {likes}
            </Button>
          </Tooltip>
        </Stack>
      )}
    </>
  )
}

const ViewCommentsUnder = ({
  pageNumber,
  setPageNumber,
  comments,
  uid,
  document,
  removeComment,
  db,
  displayName,
  isAllowedToComment
}) => {
  const [sortType, setSortType] = useState('Date')

  const sorts = [
    {
      value: 'Date',
      label: 'Date'
    },
    {
      value: 'User',
      label: 'User'
    },
    {
      value: 'Page',
      label: 'Page'
    },
    {
      value: 'Current Page',
      label: 'Current Page'
    },
    {
      value: 'Message Type',
      label: 'Message Type'
    }
  ]

  function handleSortType (e) {
    let value = e.target.value
    setSortType(value)
  }

  return (
    <Card
      raised
      sx={{
        m: { md: '10px auto', xs: '10px 0' },
        width: { md: '90vw', xs: '100%' }
      }}
    >
      <div style={{ padding: '20px' }}>
        <Stack
          direction='row'
          sx={{ width: '100%', pb: 2 }}
          justifyContent='space-between'
          alignItems='center'
        >
          <h4 style={{ margin: 'unset' }}>Comments:</h4>
          <Stack
            direction='row'
            sx={{ width: '100%' }}
            justifyContent='flex-end'
          >
            <TextField
              id='sortAdminComments'
              select
              label='Sort/Filter'
              defaultValue='Comment'
              variant='outlined'
              sx={{ width: '50%', minWidth: '100px' }}
              value={sortType}
              onChange={handleSortType}
              size='small'
            >
              {sorts.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {document &&
              (document.uid === uid ||
                (document.admins && document.admins.includes(uid))) && (
                <DownloadButton document={document} icon={false} db={db} />
              )}
          </Stack>
        </Stack>
        <Stack spacing={2} key={sortType} className='bmSib'>
          {comments
            .filter(val => {
              if (!document.commentVisibility) {
                return (
                  val.uid === uid ||
                  (document &&
                    (document.uid === uid ||
                      (document.admins && document.admins.includes(uid))))
                )
              } else {
                return true
              }
            })
            .sort((a, b) => {
              switch (sortType) {
                case 'Date':
                  return b.time - a.time
                case 'Page':
                  return a.pageNumber - b.pageNumber
                case 'Message Type':
                  return a.messageType.localeCompare(b.messageType)
                default:
                  return a.displayName && b.displayName
                    ? a.displayName.localeCompare(b.displayName)
                    : b.time - a.time
              }
            })
            .filter(val => {
              if (sortType === 'Current Page') {
                return val.pageNumber === pageNumber
              }
              return true
            })
            .map(currentComment => (
              <Card
                raised
                sx={{ p: 2 }}
                key={currentComment.id + currentComment.uid + sortType}
              >
                <CardHeader
                  avatar={
                    <CommentTypeIcon
                      type={currentComment.messageType}
                      currentComment={currentComment}
                      document={document}
                      uid={uid}
                    />
                  }
                  title={
                    currentComment.displayName
                      ? currentComment.displayName
                      : currentComment.uid
                  }
                  subheader={
                    currentComment.dateString +
                    ' - Page ' +
                    currentComment.pageNumber
                  }
                  action={
                    <Stack direction='row'>
                      <Tooltip title='Go To Page'>
                        <Button
                          onClick={() =>
                            setPageNumber(currentComment.pageNumber)
                          }
                        >
                          <FileOpen />
                        </Button>
                      </Tooltip>
                      {isAllowedToComment &&
                        (uid === currentComment.uid ||
                          uid === document.uid ||
                          (document.admins &&
                            document.admins.includes(uid))) && (
                          <Button
                            onClick={() => removeComment(currentComment)}
                            color='error'
                            variant='outlined'
                          >
                            Remove
                          </Button>
                        )}
                    </Stack>
                  }
                  sx={{
                    flexWrap: 'wrap',
                    '.MuiCardHeader-action': {
                      marginLeft: 'auto',
                      padding: '8px'
                    }
                  }}
                />
                <CardContent>
                  <span style={{ fontWeight: '600' }}>
                    {currentComment.message.length > 0
                      ? currentComment.message
                      : currentComment.messageType + 'd'}
                  </span>
                  <br />
                  <br />
                  <Replies
                    uid={uid}
                    currentComment={currentComment}
                    db={db}
                    document={document}
                    displayName={displayName}
                    isAllowedToComment={isAllowedToComment}
                  />
                </CardContent>
              </Card>
            ))}
        </Stack>
        <div className='bm' style={{ display: 'none' }}>
          No comments on document or with filter.
        </div>
      </div>
    </Card>
  )
}

export default ViewCommentsUnder
