import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth'
import { useState } from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import GoogleLogo from '../../Icons/google.svg'
import { useNavigate } from 'react-router-dom'
import { Card, InputAdornment, Tooltip } from '@mui/material'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import SignInToView from './SignInToView'
import Document from '../../Icons/document.jpg'
import { ContactSupport, ReportGmailerrorred } from '@mui/icons-material'
import { logEvent } from 'firebase/analytics'

const SignUp = ({
  auth,
  db,
  setUserType,
  savedLocation,
  setDisplayName,
  asAdmin = false,
  analytics
}) => {
  const provider = new GoogleAuthProvider()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [alert, setAlert] = useState(null)
  const navigate = useNavigate()

  function signIn () {
    navigate('/signin')
  }

  function getEmailPrefix (email) {
    var parts = email.split('@')
    return parts[0]
  }

  function signUp () {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        logEvent(analytics, 'sign_up_admin')
        const user = userCredential.user
        setDisplayName(getEmailPrefix(email))
        await setDoc(
          doc(db, 'users', user.uid),
          {
            displayName: getEmailPrefix(email),
            email: email,
            lastLogin: Date.now(),
            paid: asAdmin,
            documentCount: 0
          },
          { merge: true }
        )
        setUserType(asAdmin)
        navigate(savedLocation)
      })
      .catch(error => {
        const errorMessage = error.message
        if (errorMessage === 'Firebase: Error (auth/email-already-in-use).') {
          setAlert('You already have an account. Please sign in.')
          signInWithEmailAndPassword(auth, email, password).then(() => {
            logEvent(analytics, 'sign_in_admin')
            navigate(savedLocation)
          })
        } else {
          setAlert('Something went wrong, please try again.')
        }
      })
  }

  function signInGoogle () {
    signInWithPopup(auth, provider)
      .then(async result => {
        const user = result.user
        let userRef = doc(db, 'users', user.uid)
        let userDoc = await getDoc(userRef)
        if (!userDoc.exists()) {
          logEvent(analytics, 'sign_up_admin')
          setDisplayName(getEmailPrefix(user.email))
          let userRef = doc(db, 'users', user.uid)
          await setDoc(
            userRef,
            {
              email: user.email,
              displayName: getEmailPrefix(user.email),
              lastLogin: Date.now(),
              paid: asAdmin,
              documentCount: 0
            },
            { merge: true }
          ).then(() => {
            logEvent(analytics, 'sign_in_admin')
            setUserType(asAdmin)
            navigate(savedLocation)
          })
        } else {
          navigate(savedLocation)
        }
      })
      .catch(error => {
        const errorMessage = error.message
        setAlert(errorMessage)
      })
  }
  const handleEmail = event => {
    const value = event.target.value
    setEmail(value)
  }
  const handlePassword = event => {
    const value = event.target.value
    setPassword(value)
  }

  return (
    <Stack sx={{ pb: 2 }}>
      <Card
        raised
        sx={{
          p: 5,
          width: 'min(500px, 95%)',
          margin: '20px auto',
          p: { md: 6, xs: 3 }
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={1}
        >
          <SignInToView db={db} savedLocation={savedLocation} signIn={false} />
          {alert !== null && (
            <Alert
              variant='filled'
              severity='error'
              icon={false}
              action={
                <Button color='inherit' size='small' onClick={signIn}>
                  Sign In
                </Button>
              }
            >
              {alert}
            </Alert>
          )}
          {asAdmin && (
            <span style={{ fontSize: '1em' }}>Sign Up as an Admin User</span>
          )}
          <TextField
            id='email'
            label='Email'
            variant='filled'
            onChange={handleEmail}
            required
            value={email}
            fullWidth
          />
          <TextField
            id='password'
            label='Password'
            variant='filled'
            type='password'
            onChange={handlePassword}
            required
            value={password}
            fullWidth
            error={password.length < 6 && password.length !== 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  {password.length < 6 && password.length !== 0 ? (
                    <Tooltip title={'Password must be 6 characters or longer.'}>
                      <ReportGmailerrorred color='error' />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              )
            }}
          />

          <p
            style={{
              marginBottom: '10px',
              fontSize: '0.75em',
              textAlign: 'center'
            }}
          >
            {!asAdmin &&
              'Requiring sign-ins for commenting helps maintain a respectful and accountable community, fostering meaningful interactions while deterring spam and inappropriate behavior.'}
          </p>
          <Stack
            spacing={2}
            direction='row'
            useFlexGap
            flexWrap='wrap'
            justifyContent='center'
          >
            <Button
              variant='contained'
              disabled={email.length === 0 || password.length < 6}
              onClick={signUp}
            >
              Sign Up
            </Button>
            <Button variant='text' onClick={signInGoogle}>
              <img
                src={GoogleLogo}
                alt='Google Sign Up'
                style={{ objectFit: 'contain', width: '50px' }}
              />
            </Button>
            {asAdmin && (
              <Tooltip title='Contact Us'>
                <Button
                  variant='contained'
                  onClick={() => navigate('/support/contact')}
                >
                  <ContactSupport />
                </Button>
              </Tooltip>
            )}
          </Stack>
          <Tooltip title='Sign In/Up'>
            <Button
              onClick={() => navigate('/signUp' + (asAdmin ? '' : 'Admin'))}
            >
              {(asAdmin ? 'User' : 'Admin') + ' Sign Up'}
            </Button>
          </Tooltip>
        </Stack>
      </Card>
      <img
        src={Document}
        alt='Collaborate on Documents/PDFs'
        style={{
          borderRadius: '4px',
          width: '50%',
          margin: 'auto',
          maxWidth: '600px'
        }}
      />
    </Stack>
  )
}

export default SignUp
