import { Alert } from '@mui/material'
import { useEffect, useState } from 'react'

const WelcomeAlert = ({ displayName, showAlert, setShowAlert }) => {
  const [showWelcome, setShowWelcome] = useState(false)

  const hide = () => {
    if (typeof setShowAlert !== 'undefined') {
        setShowAlert(false)
    } else {
        setShowWelcome(false)
    }
  }

  useEffect(() => {
    if (typeof showAlert !== 'undefined') {
      setShowWelcome(showAlert)
    }
  }, [showAlert])

  return showWelcome && displayName ? (
    <Alert
      variant='filled'
      severity='success'
      icon={false}
      onClose={hide}
      sx={{ m: '0 auto 4px auto', width: '80%' }}
    >
      {`Welcome ${displayName}!`}
    </Alert>
  ) : (
    <></>
  )
}

export default WelcomeAlert
