import {
  Stack,
  TextField,
  Button,
  Alert,
  Switch,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Card,
  CardHeader,
  Box,
  Autocomplete,
  Link,
  MenuItem,
  Tooltip,
  InputAdornment
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'
import {
  AddPhotoAlternate,
  Autorenew,
  ContactSupport,
  ContentCopy,
  Delete,
  PostAdd,
  ReportGmailerrorred,
  RestartAlt,
  Verified,
  Visibility,
  VisibilityOff
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
  doc,
  setDoc,
  addDoc,
  arrayUnion,
  collection,
  increment,
  deleteField,
  query,
  where,
  getDocs,
  or,
  onSnapshot,
  arrayRemove,
  deleteDoc
} from 'firebase/firestore'
import ViewDoc from '../View/ViewDoc'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import '../../Style/Form.css'
import { PaperComponent } from '../Helpers/Drag'
import MultiEmailComponent from '../Email/MultiEmailComponent'
import DownloadButton from './DownloadButton'
import docSucess from '../../Icons/docSucess.jpg'
import docUpload from '../../Icons/docUpload.jpg'

const Upload = ({
  storage,
  db,
  uid,
  document = null,
  displayUserName,
  userEmail,
  isDraft = false,
  isCopy = false
}) => {
  const [viewCode, setViewCode] = useState('')
  const [useViewCode, setUseViewCode] = useState(false)

  const [displayName, setDisplayName] = useState('')
  const [hostName, setHostName] = useState('')
  const [contactInformation, setContactInformation] = useState('')
  const [contactLink, setContactLink] = useState('')
  const [useHostName, setUseHostName] = useState(false)
  const [useComments, setUseComments] = useState(false)
  const [commentVisibility, setCommentVisibility] = useState(false)
  const [searchable, setSearchable] = useState(false)
  const [signInView, setSignInView] = useState(true)

  const [description, setDescription] = useState('')

  const [fileName, setFileName] = useState('')
  const [file, setFile] = useState(null)
  const inputFileRef = useRef()

  const [logo, setLogo] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const logoFileRef = useRef()

  const [alert, setAlert] = useState(null)
  const [loading, setLoading] = useState(false)

  const [comments, setComments] = useState([])

  const [isEdit, setIsEdit] = useState(false)
  const [docChanged, setDocChanged] = useState(false)
  const [logoChanged, setLogoChanged] = useState(false)
  const [logoDeleted, setLogoDeleted] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [adminEmails, setAdminEmails] = useState([])
  const [editEmails, setEditEmails] = useState([])
  const [minQuantity, setMinQuantity] = useState(null)
  const [customRoute, setCustomRoute] = useState('')
  const [customRouteVerified, setCustomRouteVerified] = useState('')

  const [collapse, setCollapse] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  const [documentUrl, setDocumentURL] = useState('')
  const [documentAlteredUrl, setAlteredDocumentURL] = useState('')
  const [logoAltered, setLogoAltered] = useState(null)
  const [selectedTeams, setSelectedTeams] = useState([])

  const [teams, setTeams] = useState([])

  const [noPaymentNeeded, setNoPaymentNeeded] = useState(false)

  const [showUsageDirections, setShowUsageDirections] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const oneDay = 86400000
  const twoMB = 2097152

  //change to true when ready
  const requirePayments = true

  var dateToday = new Date()
  var date = new Date(dateToday.getTime() + oneDay * 29)

  const [state, setState] = useState([
    {
      startDate: dateToday,
      endDate: date,
      key: 'selection'
    }
  ])

  const [state2, setState2] = useState([
    {
      startDate: dateToday,
      endDate: date,
      key: 'selection'
    }
  ])

  function getDate (date) {
    return new Date(date.toDate())
  }

  async function getTeams () {
    let q = query(
      collection(db, 'teams'),
      or(
        where('creator', '==', uid),
        where('emails', 'array-contains', userEmail)
      )
    )
    const documentSnapshots = await getDocs(q)
    let teams_ = []
    documentSnapshots.forEach(val => {
      teams_.push({ ...val.data(), id: val.id })
    })
    setTeams(teams_)
  }

  function quantityOfDates (start, end) {
    const diffTime = Math.abs(start - end)
    const quantity = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
    return quantity
  }

  useEffect(() => {
    if (document) {
      if (document.commentDate) {
        setUseComments(true)
        setCommentVisibility(document.commentVisibility || false)
      }
      setMinQuantity(0)

      if (!isCopy) {
        setCollapse(true)
        setCustomRoute(document.customRoute || '')
        setCustomRouteVerified(document.customRoute !== '')
        setIsEdit(true)
        setFileName(document.fileName)
        setDocumentURL(document.url)
        setAlteredDocumentURL(document.url)
        setState([
          {
            key: document.visibleDate.key,
            startDate: getDate(document.visibleDate.startDate),
            endDate: getDate(document.visibleDate.endDate)
          }
        ])

        if (document.paidQuantity) {
          setMinQuantity(parseInt(document.paidQuantity))
        }
        setComments(document.comments || [])
        setEditEmails(document.adminEmails || [])
        if (document.logoURL) {
          setLogoPreview(document.logoURL)
          setLogoAltered(document.logoURL)
        }
        if (document.commentDate) {
          setState2([
            {
              key: document.commentDate.key,
              startDate: getDate(document.commentDate.startDate),
              endDate: getDate(document.commentDate.endDate)
            }
          ])
        }
      }

      setDisplayName(document.displayName)
      setContactInformation(document.contactInformation)
      setContactLink(document.contactLink || '')
      setDescription(document.description)
      setSearchable(document.searchable)
      setSignInView(
        typeof document.signInView !== 'undefined' ? document.signInView : true
      )

      setAdminEmails(document.adminEmails || [])
      if (document.hostName) {
        setUseHostName(true)
        setHostName(document.hostName)
      }
      if (document.viewCode) {
        setUseViewCode(true)
        setViewCode(document.viewCode)
      }
    }
    if (uid && userEmail) {
      getTeams()
    }
  }, [uid, document, userEmail])

  useEffect(() => {
    async function getNoPayment () {
      let noPaymentSnap = await getDocs(collection(db, 'paymentConfig'))
      let paymentBool = false
      noPaymentSnap.forEach(val => {
        let data = val.data()
        let domains = data.domains
        let ids = data.ids
        let inDomains = domains.reduce((acc, curr) => {
          return acc || userEmail.includes(curr)
        }, false)
        let inIds = ids.includes(uid)
        paymentBool = paymentBool || inIds || inDomains
      })
      setNoPaymentNeeded(paymentBool)
    }
    if (db) {
      getNoPayment()
    } else {
      setNoPaymentNeeded(false)
    }
  }, [db])

  const navigate = useNavigate()

  function makeid (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!?$%&'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  const handleLogoChange = e => {
    if (e.target.files) {
      var file = e.target.files[0]
      if (file.size < twoMB) {
        setLogo(file)
        setLogoPreview(URL.createObjectURL(file))
        setLogoChanged(true)
        setAlert(null)
      } else {
        setAlert('Logo must be smaller than 2MB')
      }
    }
  }

  const handleLogoRemove = () => {
    setLogo(null)
    setLogoPreview(null)
    logoAltered && setLogoDeleted(true)
    if (logoFileRef.current) {
      logoFileRef.current.value = ''
      logoFileRef.current.type = 'text'
      logoFileRef.current.type = 'file'
    }
  }

  const handleFileChange = e => {
    if (e.target.files) {
      var file = e.target.files[0]
      setFile(file)
      setDocumentURL(window.URL.createObjectURL(file))
      if (fileName === '') {
        setFileName(file.name)
      }
    }
  }

  const handleEditDocRemove = () => {
    setShowModal(true)
  }

  const handleDocRemove = () => {
    setShowModal(false)
    setFile(null)
    setDocChanged(true)
    setDocumentURL('')
    setComments([])
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      inputFileRef.current.type = 'text'
      inputFileRef.current.type = 'file'
    }
  }

  function onlyUnique (value, index, array) {
    return array.indexOf(value) === index
  }

  const handleChangeSelectedTeams = (event, newSelectedTeams) => {
    let oldSelected = []
    selectedTeams.forEach(
      val => (oldSelected = [...oldSelected, ...val.emails])
    )
    let newEmails = adminEmails.filter(val => !oldSelected.includes(val))
    newSelectedTeams.forEach(val => (newEmails = [...newEmails, ...val.emails]))
    setSelectedTeams(newSelectedTeams)
    setAdminEmails(
      newEmails.filter(val => val !== userEmail).filter(onlyUnique)
    )
  }

  const handleCode = event => {
    const value = event.target.value
    setViewCode(value)
  }

  const handleCustomRoute = event => {
    let value = event.target.value
    value = value.replace(/[^A-Za-z0-9]/g, '_')
    setCustomRoute(value.toLowerCase())
    setCustomRouteVerified(false)
  }

  useEffect(() => {
    checkCustomRoute()
  }, [customRoute])

  const checkCustomRoute = async () => {
    if (customRoute !== '') {
      let q1 = query(
        collection(db, 'documents'),
        where('customRoute', '==', customRoute)
      )
      const querySnapshot = await getDocs(q1)
      if (querySnapshot.docs.length === 0) {
        setCustomRouteVerified(true)
      } else {
        setCustomRouteVerified(false)
        if (isEdit && document) {
          querySnapshot.forEach(doc => {
            if (doc.id === document.id) {
              setCustomRouteVerified(true)
            }
          })
        }
      }
    }
  }

  const handleFileName = event => {
    const value = event.target.value
    setFileName(value)
  }
  const handleDisplayName = event => {
    const value = event.target.value
    setDisplayName(value)
  }
  const handleHostName = event => {
    const value = event.target.value
    setHostName(value)
  }
  const handleDescription = event => {
    const value = event.target.value
    setDescription(value)
  }
  const handleContact = event => {
    const value = event.target.value
    setContactInformation(value)
  }
  const handleContactLink = event => {
    const value = event.target.value
    setContactLink(value)
  }
  const changeDate2 = item => {
    if (
      item.selection.startDate >= state[0].startDate &&
      item.selection.endDate <= state[0].endDate
    )
      setState2([item.selection])
  }
  const matchDates = () => {
    setState2(state)
  }

  const getObject = downloadURL => {
    var obj = {
      fileName: fileName,
      uid: uid,
      displayName: displayName,
      contactInformation: contactInformation,
      description: description,
      visibleDate: state[0],
      searchable: searchable,
      adminEmails: adminEmails,
      editDate: Date.now(),
      contactLink: contactLink,
      signInView: signInView,
      customRoute: customRoute
    }
    if (downloadURL) {
      obj.url = downloadURL
    }

    if (useComments) {
      obj.commentDate = state2[0]
      obj.comments = comments || []
      obj.commentCount = comments.length || 0
      obj.commentVisibility = commentVisibility
    } else {
      if (isEdit && !isCopy) {
        obj.commentDate = deleteField()
        obj.comments = deleteField()
        obj.commentCount = deleteField()
        obj.commentVisibility = deleteField()
      }
    }
    if (useHostName) {
      obj.hostName = hostName
    } else {
      if (isEdit && !isCopy) {
        obj.hostName = deleteField()
      }
    }
    if (useViewCode) {
      obj.viewCode = viewCode
    } else {
      if (isEdit && !isCopy) {
        obj.viewCode = deleteField()
      }
    }

    return obj
  }

  function getRandomInt (max) {
    return Math.floor(Math.random() * max)
  }

  function createAdminObject (id) {
    let obj = {}
    obj.to = adminEmails
    obj.message = {
      subject:
        'You have been invited to be an admin for a document on Community Document',
      html:
        `Check out ${fileName} here: <a href='${
          window.location.origin + '/view/' + id
        }' target='_blank'>
        ${window.location.host + '/view/' + id}
      </a>` +
        (viewCode !== null && useViewCode
          ? '. Use Access Code: "' + viewCode + '".'
          : '') +
        ' Please use this email address when signing up or signing in, or ask the creator to add your correct email. Sign up for a paid version.'
    }
    return obj
  }

  const handleRemoveDoc = async () => {
    if (uid === document.uid) {
      setLoading(true)
      await deleteDoc(doc(db, 'documents', document.id))
        .then(async () => {
          let promises = []

          if (document.logoURL) {
            promises.push(
              deleteObject(ref(storage, document.logoURL))
                .then(() => {})
                .catch(() => {})
            )
          }

          promises.push(
            deleteObject(ref(storage, document.url))
              .then(() => {})
              .catch(() => {})
          )

          let q1 = query(
            collection(db, 'users'),
            where('documentIds', 'array-contains', document.id)
          )
          const querySnapshot = await getDocs(q1)
          querySnapshot.forEach(async docRef => {
            promises.push(
              await setDoc(
                doc(db, 'users', docRef.id),
                {
                  documentIds: arrayRemove(docRef.id),
                  documentCount: increment(-1)
                },
                {
                  merge: true
                }
              )
            )
          })

          await Promise.all(promises)

          navigate('/dashboard')
        })
        .catch(error => {
          setLoading(false)
          console.error('Error removing document: ', error)
        })
    }
  }

  const editDoc = async () => {
    if (document && document.id) {
      const docRef = doc(db, 'documents', document.id)
      setLoading(true)
      if (docChanged) {
        const uploadTask = uploadBytesResumable(
          ref(storage, `${uid}/${fileName}_${getRandomInt(10000)}`),
          file
        )

        uploadTask.on(
          'state_changed',
          snapshot => {},
          error => {
            setAlert('Error adding file, please try again')
            setLoading(false)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
              await setDoc(
                docRef,
                {
                  url: downloadURL
                },
                { merge: true }
              )
              deleteObject(ref(storage, documentAlteredUrl))
                .then(() => {})
                .catch(() => {})
            })
          }
        )
      }

      if (logoChanged) {
        const uploadLogoTask = uploadBytesResumable(
          ref(storage, `${uid}/${fileName}_logo_${getRandomInt(10000)}`),
          logo
        )
        uploadLogoTask.on(
          'state_changed',
          snapshot => {},
          error => {
            setAlert('Error adding logo, please try again')
            setLoading(false)
          },
          () => {
            getDownloadURL(uploadLogoTask.snapshot.ref).then(
              async downloadLogoURL => {
                await setDoc(
                  docRef,
                  {
                    logoURL: downloadLogoURL
                  },
                  { merge: true }
                )
              }
            )
          }
        )
      }

      if (logoDeleted) {
        deleteObject(ref(storage, logoAltered))
          .then(() => {})
          .catch(() => {})
        if (!logoChanged) {
          await setDoc(
            docRef,
            {
              logoURL: deleteField()
            },
            { merge: true }
          )
        }
      }

      if (adminEmails.length > 0 && adminEmails !== editEmails) {
        await addDoc(collection(db, 'mail'), createAdminObject(docRef.id))
      }

      await setDoc(
        docRef,
        {
          ...getObject(null),
          draft:
            minQuantity === null
              ? true
              : minQuantity !==
                quantityOfDates(state[0].startDate, state[0].endDate)
        },
        {
          merge: true
        }
      ).then(() => {
        if (
          minQuantity !== null &&
          minQuantity === quantityOfDates(state[0].startDate, state[0].endDate)
        ) {
          navigate(
            '/view/' + (customRoute.length > 0 ? customRoute : docRef.id)
          )
        } else {
          checkout(docRef.id)
        }
      })
    }
  }

  async function checkout (documentId) {
    if (noPaymentNeeded || !requirePayments) {
      await setDoc(
        doc(db, 'documents', documentId),
        {
          draft: false
        },
        {
          merge: true
        }
      ).then(() => {
        navigate('/view/' + (customRoute.length > 0 ? customRoute : documentId))
      })
    } else {
      let quantity = quantityOfDates(state[0].startDate, state[0].endDate)

      if (minQuantity !== null) {
        quantity -= minQuantity
      }

      let docRef = await addDoc(
        collection(db, 'customers', uid, 'checkout_sessions'),
        {
          mode: 'payment',
          price: 'price_1P0bIT01d38yDsADkNfUQGxk',
          quantity: quantity,
          allow_promotion_codes: true,
          success_url:
            window.location.origin +
            '/view/' +
            (customRoute.length > 0 ? customRoute : documentId),
          cancel_url: window.location.origin + '/draft/' + documentId,
          metadata: {
            item: documentId,
            paidQuantity: parseInt(parseInt(minQuantity) + quantity)
          }
        }
      )
      if (docRef) {
        const unsub = onSnapshot(
          doc(db, 'customers', uid, 'checkout_sessions', docRef.id),
          snap => {
            const { error, url } = snap.data()
            if (error) {
              unsub()
              window.alert(`An error occured: ${error.message}`)
              setLoading(false)
            }
            if (url) {
              unsub()
              setLoading(false)
              window.location.assign(url)
            }
          }
        )
      }
    }
  }

  const submitDoc = () => {
    setLoading(true)
    const uploadTask = uploadBytesResumable(
      ref(storage, `${uid}/${fileName}_${getRandomInt(10000)}`),
      file
    )

    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        setAlert('Error adding file, please try again')
        setLoading(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
          try {
            await addDoc(collection(db, 'documents'), {
              ...getObject(downloadURL),
              createDate: Date.now(),
              draft: true
            }).then(async docRef => {
              if (logo !== null) {
                const uploadLogoTask = uploadBytesResumable(
                  ref(
                    storage,
                    `${uid}/${fileName}_logo_${getRandomInt(10000)}`
                  ),
                  logo
                )
                uploadLogoTask.on(
                  'state_changed',
                  snapshot => {},
                  error => {
                    setAlert('Error adding logo, please try again')
                    setLoading(false)
                  },
                  () => {
                    getDownloadURL(uploadLogoTask.snapshot.ref).then(
                      async downloadLogoURL => {
                        await setDoc(
                          doc(db, 'documents', docRef.id),
                          {
                            logoURL: downloadLogoURL
                          },
                          { merge: true }
                        )
                      }
                    )
                  }
                )
              }
              if (adminEmails.length > 0) {
                await addDoc(
                  collection(db, 'mail'),
                  createAdminObject(docRef.id)
                )
              }
              await setDoc(
                doc(db, 'users', uid),
                {
                  documentIds: arrayUnion(docRef.id),
                  documentCount: increment(1)
                },
                { merge: true }
              )
              checkout(docRef.id)
            })
          } catch (error) {
            if (!alert) {
              setAlert('Error uploading document, please try again')
            }
            setLoading(false)
          }
        })
      }
    )
  }

  return (
    <div style={{ paddingTop: '20px', paddingBottom: 2 }}>
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        sx={{ m: 'auto', mb: 4, maxWidth: 'min(500px, 95%)' }}
      >
        {alert !== null && (
          <Alert
            variant='filled'
            severity='error'
            onClose={() => {
              setAlert(null)
            }}
          >
            {alert}
          </Alert>
        )}
        <Card raised sx={{ width: '100%' }}>
          <CardHeader
            title={
              isEdit
                ? isDraft
                  ? 'Finish Document Upload'
                  : 'Edit Document'
                : 'Document Information'
            }
            action={
              <>
                <Tooltip title='Upload Directions'>
                  <IconButton
                    color='inherit'
                    onClick={() => setShowUsageDirections(true)}
                  >
                    <ContactSupport />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={showUsageDirections}
                  onClose={() => setShowUsageDirections(false)}
                  PaperComponent={PaperComponent}
                  fullWidth
                  aria-labelledby='draggable-dialog-title'
                >
                  <DialogTitle sx={{ cursor: 'pointer' }}>
                    Upload Directions
                  </DialogTitle>
                  <DialogContent>
                    <iframe
                      width='560'
                      height='315'
                      src='https://www.youtube.com/embed/zcH7Zx-t2b8?si=Qk6GBI3BzKwqsU1Z'
                      title='YouTube video player'
                      frameborder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      referrerpolicy='strict-origin-when-cross-origin'
                      allowfullscreen
                      style={{ width: '100%' }}
                    ></iframe>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() =>
                        window
                          .open(
                            window.location.origin + '/support/help',
                            '_blank'
                          )
                          .focus()
                      }
                      variant='outlined'
                      disabled={loading}
                    >
                      More Help
                    </Button>
                    <Button
                      onClick={() => setShowUsageDirections(false)}
                      variant='contained'
                      disabled={loading}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            }
          />
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
            sx={{ width: '90%', m: 'auto', pb: 2 }}
          >
            <TextField
              id='file name'
              label='File Name'
              variant='outlined'
              onChange={handleFileName}
              fullWidth
              disabled={loading}
              value={fileName}
              inputProps={{ maxLength: 200 }}
              required
              autoFocus
            />
            <TextField
              id='displayName'
              variant='outlined'
              label='Organization Name'
              onChange={handleDisplayName}
              value={displayName}
              disabled={loading}
              fullWidth
              required
            />

            <input
              style={{ display: 'none' }}
              ref={logoFileRef}
              id='logo'
              type='file'
              accept='.png, .jpg, .jpeg'
              onChange={handleLogoChange}
            />
            {logoPreview !== null ? (
              <Stack alignItems='center'>
                <img
                  style={{
                    height: '100px',
                    margin: '10px',
                    objectFit: 'contain'
                  }}
                  src={logoPreview}
                  className='logoUpload'
                  alt='logo preview'
                />
                <Button
                  variant='contained'
                  onClick={handleLogoRemove}
                  disabled={loading}
                  color='error'
                  startIcon={<Delete />}
                >
                  Remove Logo
                </Button>
              </Stack>
            ) : (
              <Button
                variant='contained'
                onClick={() => logoFileRef.current.click()}
                disabled={loading}
                startIcon={<AddPhotoAlternate />}
              >
                Upload Logo
              </Button>
            )}

            <Stack direction='row' alignItems='center'>
              <h5 style={{ margin: '0' }}>Host Name</h5>
              <Switch
                checked={useHostName}
                onChange={() => {
                  setUseHostName(!useHostName)
                }}
              />
            </Stack>
            {useHostName && (
              <TextField
                id='hostName'
                label='Host Name'
                variant='outlined'
                onChange={handleHostName}
                value={hostName}
                fullWidth
                disabled={loading}
                required
              />
            )}

            <TextField
              label='Description'
              multiline
              fullWidth
              disabled={loading}
              rows={4}
              variant='outlined'
              value={description}
              onChange={handleDescription}
            />

            <TextField
              id='contact information'
              label='Contact Information'
              variant='outlined'
              onChange={handleContact}
              disabled={loading}
              fullWidth
              value={contactInformation}
              inputProps={{ maxLength: 200 }}
            />

            <TextField
              id='contact link'
              label='Contact Link'
              variant='outlined'
              onChange={handleContactLink}
              disabled={loading}
              fullWidth
              value={contactLink}
              inputProps={{ maxLength: 200 }}
            />
            {contactLink.length > 0 && (
              <span
                className='caption'
                style={{ margin: '0', marginTop: '10px' }}
              >
                Include full link like: "https://communitydocument.com/support/contact"
              </span>
            )}
          </Stack>
        </Card>

        <input
          style={{ display: 'none' }}
          ref={inputFileRef}
          id='file'
          type='file'
          accept='application/pdf'
          onChange={handleFileChange}
        />

        {documentUrl !== '' ? (
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
            sx={{ pt: 2, pb: 2 }}
          >
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                onClick={isEdit ? handleEditDocRemove : handleDocRemove}
                disabled={loading}
                color='error'
                startIcon={<Autorenew />}
              >
                Replace Document
              </Button>
              <Button
                variant='contained'
                disabled={loading}
                onClick={() => setCollapse(!collapse)}
                startIcon={collapse ? <Visibility /> : <VisibilityOff />}
              >
                {(collapse ? 'Preview' : 'Hide') + ' Document'}
              </Button>
            </Stack>

            {!collapse ? (
              <ViewDoc
                url={documentUrl}
                fileName={fileName}
                view={false}
                uid={uid}
                comments={comments || []}
                setComments={setComments}
                isAllowedToComment={useComments}
                commentVisibility={true}
                displayName={displayUserName}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            ) : (
              <Card>
                <img
                  src={docSucess}
                  alt='Document Uploaded'
                  style={{ borderRadius: '4px', width: '200px' }}
                />

                <h5 style={{ textAlign: 'center' }}>Document Uploaded</h5>
              </Card>
            )}
            <Dialog
              open={showModal}
              onClose={() => setShowModal(false)}
              PaperComponent={PaperComponent}
              aria-labelledby='draggable-dialog-title'
            >
              <DialogTitle style={{ cursor: 'move' }}>
                <Stack
                  direction='row'
                  style={{ gap: '10px' }}
                  alignItems='center'
                >
                  Removing the document will also delete all comments and likes
                </Stack>
              </DialogTitle>

              <DialogContent>
                {comments ? comments.length : 0} comments and likes
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDocRemove}
                  color='error'
                  variant='outlined'
                  disabled={loading}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => setShowModal(false)}
                  disabled={loading}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
        ) : (
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
            sx={{ p: 2 }}
          >
            <Button
              variant='contained'
              onClick={() => inputFileRef.current.click()}
              disabled={loading}
              startIcon={<PostAdd />}
            >
              Upload Document
            </Button>

            <Card
              onClick={() => inputFileRef.current.click()}
              sx={{ cursor: 'pointer' }}
            >
              <img
                src={docUpload}
                alt='Document not Uploaded'
                style={{ borderRadius: '4px', width: '200px' }}
              />
              <h5 style={{ textAlign: 'center' }}>No Document Selected</h5>
            </Card>
          </Stack>
        )}

        <Card raised sx={{ width: '100%' }}>
          <CardHeader title='Configuration' />

          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
            sx={{ width: '90%', m: 'auto', pb: 2 }}
          >
            <h5>Other Admins</h5>

            <span className='caption' style={{ mt: '8px !important' }}>
              Press enter after entering each email, copy and paste a list, or
              add a team.
            </span>
            <MultiEmailComponent
              emails={adminEmails}
              setEmails={setAdminEmails}
              placeholder='Admin Emails'
            />

            {teams.length > 0 && (
              <Autocomplete
                multiple
                id='tags-outlined'
                options={teams}
                getOptionLabel={team => team.teamName}
                defaultValue={[]}
                filterSelectedOptions
                disabled={loading}
                fullWidth
                value={selectedTeams}
                onChange={handleChangeSelectedTeams}
                renderInput={params => (
                  <TextField {...params} label='Teams' placeholder='Teams' />
                )}
              />
            )}

            {teams.length === 0 && (
              <span className='caption' style={{ mt: '8px !important' }}>
                Create teams on the profile tab
              </span>
            )}

            <Divider component='div' sx={{ width: '100%' }} />
            <h5>Visible Dates*</h5>
            {isEdit && (
              <span
                className='caption'
                style={{ mt: '8px !important', fontWeight: 'bold' }}
              >
                {`Duration must be ${minQuantity} days or longer.`}
              </span>
            )}
            <p
              className='caption'
              style={{
                mt: '8px !important',
                color:
                  minQuantity !== null
                    ? quantityOfDates(state[0].startDate, state[0].endDate) <
                      minQuantity
                      ? 'red'
                      : 'green'
                    : '',
                fontWeight: 'bold'
              }}
            >
              {`Selected Duration: ${quantityOfDates(
                state[0].startDate,
                state[0].endDate
              )} days`}
            </p>

            <p
              className='caption'
              style={{
                margin: '8px',
                fontStyle: 'italic',
                textAlign: 'center'
              }}
            >
              {`Start date cannot be changed after submission and duration cannot be shortened after payment.`}
              <br />
              {!noPaymentNeeded && requirePayments && (
                <Link
                  href={window.location.origin + '/support/contact'}
                  target='_blank'
                  rel='noreferrer'
                >{`Contact us, with questions or to work out another price structure!`}</Link>
              )}
            </p>

            <Box className='dateRange'>
              <DateRange
                editableDateInputs={true}
                onChange={item => {
                  if (minQuantity !== null) {
                    item.selection.startDate = state[0].startDate
                    setState([item.selection])
                    setState2([item.selection])
                  } else {
                    setState([item.selection])
                    setState2([item.selection])
                  }
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                endDatePlaceholder='Not Set'
              />
            </Box>
            <Divider component='div' sx={{ width: '100%' }} />

            <Stack direction='row' alignItems='center'>
              <h5 style={{ margin: '0' }}>Allow Comments</h5>
              <Switch
                checked={useComments}
                onChange={() => {
                  setUseComments(!useComments)
                }}
              />
            </Stack>
            {useComments && (
              <>
                {isEdit && (
                  <p className='caption' style={{ margin: '0', color: 'red' }}>
                    <i>
                      Turning off comments while editing will delete all
                      comments.
                    </i>
                  </p>
                )}
                <span className='caption' style={{ fontWeight: 'bold' }}>
                  Comment Dates (Must be between Visible Dates)
                </span>
                <Box className='dateRange'>
                  <DateRange
                    editableDateInputs={true}
                    onChange={changeDate2}
                    moveRangeOnFirstSelection={false}
                    ranges={state2}
                    endDatePlaceholder='Not Set'
                  />
                </Box>
                <Button
                  size='small'
                  onClick={matchDates}
                  disabled={loading}
                  startIcon={<ContentCopy />}
                >
                  All Visible Dates
                </Button>
                <h5 style={{ marginBottom: '0' }}>Comment Visibility</h5>

                <TextField
                  id='setCommentVisibility'
                  select
                  disabled={loading}
                  size='small'
                  label='Comment Visibility'
                  fullWidth
                  variant='outlined'
                  value={commentVisibility}
                  onChange={event => setCommentVisibility(event.target.value)}
                >
                  <MenuItem key={'own'} value={false}>
                    Users can only see their own comments
                  </MenuItem>
                  <MenuItem key={'everyone'} value={true}>
                    Users can see everyone's comments
                  </MenuItem>
                </TextField>
              </>
            )}
            <Divider component='div' sx={{ width: '100%' }} />

            {customRoute.length > 0 && (
              <span
                className='caption'
                style={{ fontWeight: 'bold', textAlign: 'center' }}
              >
                URL Preview:{' '}
                {window.location.origin + '/view/' + customRoute.toLowerCase()}
              </span>
            )}
            <TextField
              id='customURL'
              variant='outlined'
              label='Custom URL'
              onChange={handleCustomRoute}
              value={customRoute}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='start'>
                    {customRouteVerified && customRoute.length > 0 ? (
                      <Tooltip title={'This URL is available.'}>
                        <Verified color='success' />
                      </Tooltip>
                    ) : customRoute.length > 0 ? (
                      <Tooltip title={'This URL is taken.'}>
                        <ReportGmailerrorred color='error' />
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                )
              }}
              disabled={loading}
              fullWidth
              sx={{ mr: 2 }}
              inputProps={{ maxLength: 25 }}
              error={customRoute.length > 0 && !customRouteVerified}
            />
            <Divider component='div' sx={{ width: '100%' }} />
            <Stack direction='row' alignItems='center'>
              <h5 style={{ margin: '0' }}>Access Code</h5>
              <Switch
                checked={useViewCode}
                onChange={() => {
                  setUseViewCode(!useViewCode)
                }}
              />
            </Stack>
            {useViewCode && (
              <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
                <TextField
                  id='code'
                  variant='outlined'
                  label='Access Code'
                  onChange={handleCode}
                  value={viewCode}
                  disabled={loading}
                  fullWidth
                  sx={{ mr: 2 }}
                  inputProps={{ maxLength: 20 }}
                />
                <IconButton
                  variant='outlined'
                  onClick={() => {
                    setViewCode(makeid(6))
                  }}
                  disabled={loading}
                >
                  <RestartAlt />
                </IconButton>
              </Stack>
            )}
            <Divider component='div' sx={{ width: '100%' }} />
            <Stack spacing={3} sx={{ width: '100%' }}>
              <TextField
                id='setSearchable'
                select
                size='small'
                label='Searchable'
                fullWidth
                disabled={loading}
                variant='outlined'
                value={searchable}
                onChange={event => setSearchable(event.target.value)}
              >
                <MenuItem key={'own'} value={false}>
                  Not Searchable by Public
                </MenuItem>
                <MenuItem key={'everyone'} value={true}>
                  Searchable by Public
                </MenuItem>
              </TextField>
              <TextField
                id='setSignInView'
                select
                size='small'
                label='Document Permissions'
                fullWidth
                disabled={loading}
                variant='outlined'
                value={signInView}
                onChange={event => setSignInView(event.target.value)}
              >
                <MenuItem key={'own'} value={false}>
                  Not Required to Sign In to View Document
                </MenuItem>
                <MenuItem key={'everyone'} value={true}>
                  Required to Sign In to View Document
                </MenuItem>
              </TextField>
            </Stack>
          </Stack>
        </Card>

        <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            disabled={loading}
            onClick={() => navigate('/dashboard')}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={isEdit && !isCopy ? editDoc : submitDoc}
            disabled={
              documentUrl === '' ||
              fileName === '' ||
              displayName === '' ||
              state[0].endDate === '' ||
              (useComments &&
                (state2[0].startDate < state[0].startDate ||
                  state2[0].endDate > state[0].endDate)) ||
              (useViewCode && viewCode === '') ||
              loading ||
              (minQuantity !== null &&
                quantityOfDates(state[0].startDate, state[0].endDate) <
                  minQuantity) ||
              (customRoute.length > 0 && !customRouteVerified)
            }
            startIcon={<PostAdd />}
          >
            {loading
              ? 'Submitting...'
              : 'Submit' + (isEdit && !isDraft ? ' Edits' : '')}
          </Button>
        </Stack>

        {isEdit && document.uid === uid && (
          <>
            <Button
              variant='outlined'
              disabled={loading}
              onClick={() =>
                window
                  .open(
                    window.location.origin + '/copy/' + document.id,
                    '_blank'
                  )
                  .focus()
              }
              startIcon={<ContentCopy />}
            >
              Copy Configuration
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              color='error'
              onClick={() => setShowDeleteModal(true)}
              startIcon={<Delete />}
            >
              Delete Project
            </Button>
            <Dialog
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              PaperComponent={PaperComponent}
              aria-labelledby='draggable-dialog-title'
            >
              <DialogTitle style={{ cursor: 'move' }}>
                <Stack
                  direction='row'
                  style={{ gap: '10px' }}
                  alignItems='center'
                >
                  Deleting this Project is Permanent
                </Stack>
              </DialogTitle>
              <DialogContent>
                Download your report before deleting.
                <DownloadButton document={document} icon={false} db={db} />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleRemoveDoc}
                  color='error'
                  variant='outlined'
                  disabled={loading}
                >
                  Delete Forever
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => setShowDeleteModal(false)}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Stack>
    </div>
  )
}

export default Upload
