import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Stack,
  MenuItem,
  Dialog,
  Card,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
  Menu,
  Badge
} from '@mui/material'
import {
  ContactSupport,
  FilterList,
  Fullscreen,
  FullscreenExit,
  NavigateBefore,
  NavigateNext,
  ZoomIn,
  ZoomOut
} from '@mui/icons-material'
import { Document, Page, pdfjs } from 'react-pdf'
import { useEffect, useReducer, useRef, useState } from 'react'
import '../../Style/ViewDoc.css'
import CommentModal from './CommentModal'
import ViewCommentModal from './ViewCommentModal'
import CommentTypeIcon, { LegendBar } from './CommentTypeIcon'
import communityLogo from '../../Icons/logoAlt.png'
import { PaperComponent, TransitionUp } from '../Helpers/Drag'
import Loading from '../Helpers/Loading'

import { openDB } from 'idb'

const DB_NAME = 'pdf-cache-db'
const DB_VERSION = 1
const STORE_NAME = 'pdfs'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export const ViewDoc = ({
  url,
  fileName,
  view = true,
  uid,
  comments,
  setComments,
  isAllowedToComment = true,
  commentVisibility = false,
  displayName,
  document,
  db = null,
  pageNumber,
  setPageNumber,
  showInfo,
  setShowInfo
}) => {
  const [pageNumber2, setPageNumber2] = useState(1)
  const [totalPages, setTotalPages] = useState(-1)
  const [zoom, setZoom] = useState(0.75)
  const [zoom2, setZoom2] = useState(75)
  const canvasRef = useRef()
  const boxRef = useRef()
  const appBarRef = useRef()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [currentComment, setCurrentComment] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showComment, setShowComment] = useState(false)
  const [showCommentTypes, setShowCommentTypes] = useState('All')
  const [loading, setLoading] = useState(true)
  const [handleFullScreen, setHandleFullScreen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if ((isLeftSwipe || isRightSwipe) && zoom <= 1) {
      if (isLeftSwipe) {
        onChangePageNumber(pageNumber + 1)
      } else {
        onChangePageNumber(pageNumber - 1)
      }
    }
  }

  const zoomLevels = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5]

  function findClosestIndex (number) {
    let closestIndex = 0
    let closestDifference = Math.abs(number - zoomLevels[0])

    for (let i = 1; i < zoomLevels.length; i++) {
      const difference = Math.abs(number - zoomLevels[i])
      if (difference < closestDifference) {
        closestIndex = i
        closestDifference = difference
      }
    }

    return closestIndex
  }

  useEffect(() => {
    function handleResize () {
      setWindowWidth(
        window.innerWidth +
          (window.innerWidth > 900 ? -(window.innerWidth * 0.1) : 0)
      )
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // const scrollToBox = () => {
  //   appBarRef.current?.scrollIntoView({ behavior: 'smooth' })
  // }

  const types = [
    {
      value: 'All',
      label: 'All'
    },
    {
      value: 'Yours',
      label: 'Yours'
    },
    {
      value: 'None',
      label: 'None'
    },
    {
      value: 'Question',
      label: 'Questions'
    },
    {
      value: 'Like',
      label: 'Likes'
    },
    {
      value: 'Comment',
      label: 'Comments'
    }
  ]

  function handleMessageType (e) {
    let value = e.target.value
    setShowCommentTypes(value)
  }

  function openComment (comment) {
    setCurrentComment(comment)
    setShowComment(true)
  }

  function onDocumentLoadSuccess ({ numPages }) {
    setTotalPages(numPages)
  }

  function onChangePageNumber (value) {
    if (value > 0 && value <= totalPages) {
      setPageNumber(value)
      setPageNumber2(value)
    } else {
      setPageNumber(pageNumber)
      setPageNumber2(pageNumber)
    }
  }

  useEffect(() => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setPageNumber2(pageNumber)
    } else {
      setPageNumber(1)
      setPageNumber2(1)
    }
    // if (pageNumber !== 1) {
    //   scrollToBox()
    // }
  }, [pageNumber])

  function onHandleZoom (zoomIn) {
    let currentZoomIndex = findClosestIndex(zoom)
    if (zoomIn) {
      if (currentZoomIndex !== zoomLevels.length - 1) {
        setZoom(zoomLevels[currentZoomIndex + 1])
        setZoom2((zoomLevels[currentZoomIndex + 1] * 100).toFixed(0))
      }
    } else {
      if (currentZoomIndex !== 0) {
        setZoom(zoomLevels[currentZoomIndex - 1])
        setZoom2((zoomLevels[currentZoomIndex - 1] * 100).toFixed(0))
      }
    }
  }

  function onHandleZoomInput () {
    if (zoom2 !== '' && zoom2 >= 20) {
      setZoom(zoom2 / 100)
    } else {
      setZoom2((zoom * 100).toFixed(0))
    }
  }

  function onHandleZoomChange (e) {
    const value = parseInt(e.target.value) || ''
    setZoom2(value)
  }

  function onHandlePageNumberChange (event) {
    const value = parseInt(event.target.value) || ''
    setPageNumber2(value)
  }

  function handleDocClick (e) {
    if (e.target.nodeName === 'CANVAS' && isAllowedToComment) {
      var comment = {
        x: 0,
        y: 0,
        pageNumber: pageNumber,
        id: uid + '_' + Math.floor(Math.random() * 10000),
        uid: uid,
        displayName: displayName
      }
      comment.x =
        (e.pageX - canvasRef.current.getBoundingClientRect().left) /
        canvasRef.current.scrollWidth
      comment.y =
        (e.pageY - canvasRef.current.getBoundingClientRect().top) /
        canvasRef.current.scrollHeight
      setCurrentComment(comment)
      setShowModal(true)
    }
  }

  function addComment (comment) {
    setComments([...comments, comment], comment)
    setShowModal(false)
    setShowCommentTypes('All')
  }

  function removeComment (comment) {
    setComments(
      comments.filter(val => val.id !== comment.id),
      comment,
      false
    )
    setShowComment(false)
    setShowCommentTypes('All')
  }

  function findPlace (pos) {
    var newPos = { x: 10, y: 70 }
    if (canvasRef.current) {
      newPos.x = pos.x * canvasRef.current.scrollWidth - 10
      newPos.y = pos.y * canvasRef.current.scrollHeight - 10
    }
    return newPos
  }

  useEffect(() => {
    forceUpdate()
  }, [zoom])

  const [file, setFile] = useState(null)

  useEffect(() => {
    const fetchPdf = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade (db) {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME)
          }
        }
      })

      try {
        const cachedFile = await db.get(STORE_NAME, url)
        if (cachedFile) {
          // File is cached, set it to state
          setFile(cachedFile)
        } else {
          // Fetch the file from the network
          const response = await fetch(url)
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`)
          }
          const blob = await response.blob()
          setFile(blob)

          // Store the file in IndexedDB
          await db.put(STORE_NAME, blob, url)
        }
      } catch (error) {
        console.error('Error fetching PDF: ', error)
      }
    }

    fetchPdf()
  }, [url])

  return (
    <Card
      style={{
        background: '#80808070',
        width: 'min-content',
        margin: '16px auto'
      }}
      raised
    >
      <Card
        raised
        className='viewDocBox'
        sx={{
          m: { md: '0 auto', xs: '0' },
          width: { md: '90vw', xs: '100vw' }
        }}
      >
        <div ref={appBarRef}></div>
        <ViewDocAppBar
          fileName={fileName}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          onHandlePageNumberChange={onHandlePageNumberChange}
          pageNumber2={pageNumber2}
          totalPages={totalPages}
          onHandleZoom={onHandleZoom}
          onHandleZoomChange={onHandleZoomChange}
          zoom2={zoom2}
          onHandleZoomInput={onHandleZoomInput}
          showCommentTypes={showCommentTypes}
          handleMessageType={handleMessageType}
          types={types}
          setHandleFullScreen={setHandleFullScreen}
          handleFullScreen={handleFullScreen}
          setShowInfo={setShowInfo}
        />
      </Card>

      {totalPages > 0 &&
        !loading &&
        zoom < 1 &&
        (canvasRef.current
          ? window.innerWidth < 900
            ? true
            : canvasRef.current.getBoundingClientRect().left > 60
          : false) && (
          <>
            <Button
              sx={{
                float: 'left',
                height: canvasRef.current
                  ? canvasRef.current.scrollHeight + 'px'
                  : '200px',
                minWidth: 0,
                width: canvasRef.current
                  ? window.innerWidth < 900
                    ? `calc(${
                        canvasRef.current.getBoundingClientRect().left
                      }px - 5px)`
                    : `calc(${
                        canvasRef.current.getBoundingClientRect().left
                      }px - 5vw - 5px)`
                  : 'unset',
                color: '#1177d4'
              }}
              onClick={() => onChangePageNumber(pageNumber - 1)}
              disabled={pageNumber - 1 <= 0}
            >
              <NavigateBefore />
            </Button>
            <Button
              sx={{
                float: 'right',
                height: canvasRef.current
                  ? canvasRef.current.scrollHeight + 'px'
                  : '200px',
                minWidth: 0,
                width: canvasRef.current
                  ? window.innerWidth < 900
                    ? `calc(${
                        canvasRef.current.getBoundingClientRect().left
                      }px - 5px)`
                    : `calc(${
                        canvasRef.current.getBoundingClientRect().left
                      }px - 5vw - 5px)`
                  : 'unset',
                color: '#1177d4'
              }}
              onClick={() => onChangePageNumber(pageNumber + 1)}
              disabled={pageNumber + 1 > totalPages}
            >
              <NavigateNext />
            </Button>
          </>
        )}
      <Card
        className='viewDocBox'
        sx={{
          m: { md: '0 auto', xs: '0 auto' },
          maxWidth: { md: '90vw', xs: '100vw' },
          width: 'min-content'
        }}
      >
        <div ref={boxRef}>
          <Box className='viewDocCanvasBox'>
            <Document
              file={file}
              noData={<Loading padding='50px 0' />}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loading padding='50px 0' />}
            >
              <Page
                pageNumber={pageNumber}
                scale={zoom}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                canvasRef={canvasRef}
                onClick={handleDocClick}
                onLoadSuccess={() => setLoading(false)}
                width={windowWidth - 1}
                loading={<Loading padding='50px 0' />}
                noData={<Loading padding='50px 0' />}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              />
            </Document>
            {totalPages > 0 && !loading && (
              <div>
                {showCommentTypes !== 'None' &&
                  comments
                    .filter(val => {
                      if (!commentVisibility) {
                        return (
                          val.uid === uid ||
                          (document &&
                            (document.uid === uid ||
                              (document.admins &&
                                document.admins.includes(uid)))) ||
                          !view
                        )
                      } else {
                        return true
                      }
                    })
                    .filter(
                      val =>
                        val.pageNumber === pageNumber &&
                        (showCommentTypes === 'All' ||
                          val.messageType === showCommentTypes ||
                          (showCommentTypes === 'Yours' && val.uid === uid))
                    )
                    .map(val => (
                      <Box
                        key={val.x + val.y + val.pageNumber}
                        sx={{
                          position: 'absolute',
                          left:
                            canvasRef.current &&
                            canvasRef.current.offsetWidth <= windowWidth
                              ? findPlace(val).x + 'px'
                              : '5px',
                          top: findPlace(val).y + 'px'
                        }}
                        onClick={() => openComment(val)}
                      >
                        <CommentTypeIcon
                          currentComment={val}
                          type={val.messageType}
                          document={document}
                          uid={uid}
                        />
                      </Box>
                    ))}
                {showModal && (
                  <CommentModal
                    currentComment={currentComment}
                    uid={uid}
                    addComment={addComment}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                )}
                {showComment && (
                  <ViewCommentModal
                    currentComment={currentComment}
                    uid={uid}
                    showModal={showComment}
                    setShowModal={setShowComment}
                    removeComment={removeComment}
                    document={document}
                    displayName={displayName}
                    db={db}
                    view={view}
                    isAllowedToComment={isAllowedToComment}
                  />
                )}
              </div>
            )}
          </Box>
        </div>

        <Dialog
          open={handleFullScreen}
          onClose={() => setHandleFullScreen(false)}
          fullScreen
          TransitionComponent={TransitionUp}
          className='fullscreen'
        >
          <ViewDocAppBar
            fileName={fileName}
            onChangePageNumber={onChangePageNumber}
            pageNumber={pageNumber}
            onHandlePageNumberChange={onHandlePageNumberChange}
            pageNumber2={pageNumber2}
            totalPages={totalPages}
            onHandleZoom={onHandleZoom}
            onHandleZoomChange={onHandleZoomChange}
            zoom2={zoom2}
            onHandleZoomInput={onHandleZoomInput}
            showCommentTypes={showCommentTypes}
            handleMessageType={handleMessageType}
            types={types}
            setHandleFullScreen={setHandleFullScreen}
            handleFullScreen={handleFullScreen}
            logoURL={(document && document.logoURL) || null}
            setShowInfo={setShowInfo}
          />
          <Box sx={{ mb: 4, overflowX: 'auto' }}>
            {totalPages > 0 &&
              !loading &&
              zoom < 1 &&
              (canvasRef.current
                ? canvasRef.current.getBoundingClientRect().left > 40
                : false) && (
                <>
                  <Button
                    sx={{
                      float: 'left',
                      height: canvasRef.current
                        ? canvasRef.current.scrollHeight + 'px'
                        : '200px',
                      minWidth: 0,
                      width: canvasRef.current
                        ? `calc(${
                            canvasRef.current.getBoundingClientRect().left
                          }px - 5px)`
                        : 'unset',
                      color: '#1177d4'
                    }}
                    onClick={() => onChangePageNumber(pageNumber - 1)}
                    disabled={pageNumber - 1 <= 0}
                  >
                    <NavigateBefore />
                  </Button>
                  <Button
                    sx={{
                      float: 'right',
                      height: canvasRef.current
                        ? canvasRef.current.scrollHeight + 'px'
                        : '200px',
                      minWidth: 0,
                      width: canvasRef.current
                        ? `calc(${
                            canvasRef.current.getBoundingClientRect().left
                          }px - 5px)`
                        : 'unset',
                      color: '#1177d4'
                    }}
                    onClick={() => onChangePageNumber(pageNumber + 1)}
                    disabled={pageNumber + 1 > totalPages}
                  >
                    <NavigateNext />
                  </Button>
                </>
              )}
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              noData={<Loading padding='50px 0' />}
              loading={<Loading padding='50px 0' />}
            >
              <Page
                pageNumber={pageNumber}
                scale={zoom}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onLoadSuccess={() => setLoading(false)}
                width={windowWidth}
                loading={<Loading padding='50px 0' />}
                noData={<Loading padding='50px 0' />}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              />
            </Document>
          </Box>
        </Dialog>
        <InfoDialog showInfo={showInfo} setShowInfo={setShowInfo} />
      </Card>
    </Card>
  )
}

const ViewDocAppBar = ({
  fileName,
  onChangePageNumber,
  pageNumber,
  onHandlePageNumberChange,
  pageNumber2,
  totalPages,
  onHandleZoom,
  onHandleZoomChange,
  zoom2,
  onHandleZoomInput,
  showCommentTypes,
  handleMessageType,
  types,
  setHandleFullScreen,
  handleFullScreen,
  logoURL,
  setShowInfo
}) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null)

  const handleOpenFilterMenu = event => {
    setAnchorElFilter(event.currentTarget)
  }

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null)
  }

  return (
    <AppBar position='static'>
      <Toolbar
        sx={{
          justifyContent: { xs: 'flex-end' },
          md: ''
        }}
      >
        {handleFullScreen && (
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              height: '75px',
              p: '5px'
            }}
          >
            <img
              alt='logo'
              src={logoURL || communityLogo}
              style={{
                maxWidth: '100%',
                objectFit: 'contain',
                height: '60px',
                borderRadius: '4px',
                margin: '2px'
              }}
            />
          </Box>
        )}
        <Stack
          direction='row'
          sx={{
            flexGrow: 1
          }}
          alignItems='center'
          spacing={2}
        >
          <Typography
            variant='h7'
            component='div'
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontWeight: 'bold'
            }}
          >
            {fileName}
          </Typography>
          {typeof setShowInfo === 'function' && (
            <Tooltip title='Usage Directions'>
              <IconButton color='inherit' onClick={() => setShowInfo(true)}>
                <ContactSupport />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Tooltip title='Previous Page'>
          <IconButton
            color='inherit'
            onClick={() => onChangePageNumber(pageNumber - 1)}
          >
            <NavigateBefore />
          </IconButton>
        </Tooltip>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='baseline'
          spacing={1}
          className='noWrap'
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <TextField
            id='pageNumber'
            variant='standard'
            size='small'
            onChange={onHandlePageNumberChange}
            value={pageNumber2}
            hiddenLabel
            focused
            margin='dense'
            inputProps={{ maxLength: 4 }}
            color='secondary'
            sx={{
              width: 40,
              input: {
                color: 'white',
                padding: '0',
                textAlign: 'center'
              }
            }}
            onBlur={() => onChangePageNumber(pageNumber2)}
            onKeyDown={e =>
              e.keyCode === 13 ? onChangePageNumber(pageNumber2) : null
            }
          />
          {'/ ' + totalPages}
        </Stack>
        <Typography sx={{ display: { xs: 'flex', md: 'none' } }}>
          {pageNumber + '/' + totalPages}
        </Typography>

        <Tooltip title='Next Page'>
          <IconButton
            color='inherit'
            onClick={() => onChangePageNumber(pageNumber + 1)}
          >
            <NavigateNext />
          </IconButton>
        </Tooltip>

        <Tooltip title='Zoom Out'>
          <IconButton color='inherit' onClick={() => onHandleZoom(false)}>
            <ZoomOut />
          </IconButton>
        </Tooltip>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='baseline'
          spacing={1}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <TextField
            id='pageNumber'
            variant='standard'
            size='small'
            onChange={onHandleZoomChange}
            value={zoom2}
            hiddenLabel
            focused
            margin='dense'
            inputProps={{ maxLength: 3 }}
            color='secondary'
            sx={{
              width: 35,
              input: {
                color: 'white',
                padding: '0',
                textAlign: 'center'
              }
            }}
            onBlur={() => onHandleZoomInput()}
            onKeyDown={e => (e.keyCode === 13 ? onHandleZoomInput() : null)}
          />
          {'%'}
        </Stack>

        <Tooltip title='Zoom In'>
          <IconButton color='inherit' onClick={() => onHandleZoom(true)}>
            <ZoomIn />
          </IconButton>
        </Tooltip>
        {!handleFullScreen && (
          <>
            <TextField
              id='messageTypesView'
              select
              size='small'
              label='Filter'
              defaultValue='Comment'
              variant='outlined'
              value={showCommentTypes}
              onChange={handleMessageType}
              sx={{
                width: '125px',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)'
                },
                '.MuiSvgIcon-root ': {
                  fill: 'white !important'
                },
                display: { md: 'inline-flex', xs: 'none' }
              }}
            >
              {types.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              sx={{ display: { md: 'none', xs: 'inline-flex' } }}
              aria-label='account of current user'
              aria-controls='filter'
              aria-haspopup='true'
              onClick={handleOpenFilterMenu}
              color='inherit'
            >
              <Badge
                color='secondary'
                variant='dot'
                invisible={showCommentTypes === 'All'}
              >
                <FilterList />
              </Badge>
            </IconButton>
            <Menu
              id='filter'
              anchorEl={anchorElFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElFilter)}
              onClose={handleCloseFilterMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {types.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  selected={showCommentTypes === option.value}
                  onClick={() =>
                    handleMessageType({ target: { value: option.value } })
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        <Tooltip title='Fullscreen'>
          <IconButton
            color='inherit'
            onClick={() => setHandleFullScreen(!handleFullScreen)}
          >
            {!handleFullScreen ? <Fullscreen /> : <FullscreenExit />}
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

const InfoDialog = ({ showInfo, setShowInfo }) => {
  return (
    <Dialog
      open={showInfo}
      onClose={() => setShowInfo(false)}
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
    >
      <DialogTitle style={{ cursor: 'move' }}>
        Interacting With a Document
      </DialogTitle>
      <DialogContent>
        <p>
          Using the control bar, you can change pages, zoom, filter comments and
          toggle fullscreen mode.
          <br />
          <br />
          <b>You cannot view or leave comments in fullscreen mode.</b> Click
          anywhere on the document to add a comment, a question or a like. Click
          an icon on the document to view comments and reply to them.
          <br />
        </p>
        <LegendBar />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowInfo(false)} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewDoc
