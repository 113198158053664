import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  or,
  limit,
  startAfter
} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import '../../Style/Dashboard.css'
import {
  Button,
  Stack,
  Card,
  CardHeader,
  CardActions,
  IconButton,
  Dialog,
  Tooltip,
  Box,
  Badge,
  Typography
} from '@mui/material'
import {
  Edit,
  Search,
  Comment,
  Visibility,
  Publish,
  IosShare,
  FileOpen,
  PostAdd
} from '@mui/icons-material'

import { useEffect, useState } from 'react'
import { PaperComponent } from '../Helpers/Drag'
import EmailShare from '../Email/EmailShare'
import DownloadButton from '../Document/DownloadButton'
import WelcomeAlert from './WelcomeAlert'
import Loading from '../Helpers/Loading'
import noData from '../../Icons/noData.jpg'
import { openDB } from 'idb'

const DB_NAME = 'pdf-cache-db'
const DB_VERSION = 1
const STORE_NAME = 'pdfs'

const Dashboard = ({ uid, db, displayName }) => {
  const navigate = useNavigate()
  const limitLength = 10
  const [documents, setDocuments] = useState([])
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null)
  const [next, setNext] = useState(true)
  const [loading, setLoading] = useState(true)

  const getQuery = lastVisible => {
    const docRefs = collection(db, 'documents')

    return lastVisible
      ? query(
          docRefs,
          or(where('uid', '==', uid), where('admins', 'array-contains', uid)),
          orderBy('editDate', 'desc'),
          startAfter(lastVisible),
          limit(2)
        )
      : query(
          docRefs,
          or(where('uid', '==', uid), where('admins', 'array-contains', uid)),
          orderBy('editDate', 'desc'),
          limit(limitLength)
        )
  }

  async function loadDocuments () {
    const q = getQuery(null)

    const querySnapshot = await getDocs(q)
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
    if (querySnapshot.docs.length === limitLength) {
      setLastVisibleDoc(lastVisible)
    } else {
      setNext(false)
    }
    let docArray = []
    querySnapshot.forEach(doc => {
      let d = doc.data()
      d.id = doc.id
      docArray.push(d)
    })
    setDocuments(docArray)
    setLoading(false)
  }

  async function loadNextDocuments () {
    setLoading(true)
    const q = getQuery(lastVisibleDoc)

    await getDocs(q).then(querySnapshot => {
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
      if (querySnapshot.docs.length === limitLength) {
        setLastVisibleDoc(lastVisible)
      } else {
        setNext(false)
      }
      let docArray = []
      querySnapshot.forEach(doc => {
        let d = doc.data()
        d.id = doc.id
        docArray.push(d)
      })
      setDocuments([...documents, ...docArray])
      setLoading(false)
    })
  }

  useEffect(() => {
    if (uid) {
      setLoading(true)
      loadDocuments()
    }
  }, [uid])

  return (
    <Card
      raised
      sx={{
        m: { md: 2, xs: 0 },
        mb: { md: 2, xs: 2 },
        minHeight: '95%',
        pb: 2
      }}
    >
      <Box
        sx={{
          width: '90vw',
          margin: '10px auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '90vh'
        }}
      >
        <WelcomeAlert displayName={displayName} />
        <Stack
          direction='row'
          useFlexGap
          flexWrap='wrap'
          sx={{
            justifyContent: { xs: 'center', md: 'space-between' },
            mb: 3,
            alignItems: 'center',
            gap: { md: 4, xs: 1 },
            width: '100%'
          }}
        >
          <h1>Dashboard</h1>
          <Stack
            direction='row'
            useFlexGap
            flexWrap='wrap'
            justifyContent='center'
            spacing={2}
            height='min-content'
          >
            <Button
              variant='contained'
              color='success'
              startIcon={<PostAdd />}
              onClick={() => navigate('/upload')}
              height='60px'
            >
              Upload a Document
            </Button>
            <Button
              variant='contained'
              startIcon={<Search />}
              onClick={() => navigate('/search')}
              height='60px'
            >
              Find a Document
            </Button>
          </Stack>
        </Stack>
        {documents.length === 0 && !loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
              width: { md: '50%', xs: '90%' },
              margin: 'auto',
              mt: 6
            }}
          >
            <img
              src={noData}
              alt='No Documents'
              style={{ borderRadius: '4px', width: '100%' }}
            />
            <Typography component='h3' sx={{ fontWeight: 'bold' }}>
              No Documents
            </Typography>
          </Box>
        ) : (
          <Stack
            direction='row'
            useFlexGap
            flexWrap='wrap'
            spacing={2}
            sx={{
              justifyContent: { xs: 'center', md: 'flex-start' },
              width: '100%'
            }}
          >
            {documents.map(val => (
              <div key={val.url} className='dashboardCard'>
                <DocTileView document={val} db={db} />
              </div>
            ))}
          </Stack>
        )}
        {loading && <Loading padding='50px 0' />}
        {next && (
          <Button
            variant='contained'
            sx={{ mt: 2, maxWidth: '200px' }}
            disabled={!next || loading}
            onClick={loadNextDocuments}
          >
            Load More
          </Button>
        )}
      </Box>
    </Card>
  )
}

const DocTileView = ({ document, db }) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  function getDateString (date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date.toDate()).toLocaleDateString(undefined, options)
  }

  function getComboDateString (obj) {
    return getDateString(obj.startDate) + ' - ' + getDateString(obj.endDate)
  }

  function isDraftCheck (document) {
    return typeof document.draft !== 'undefined' && document.draft === true
  }


  const [src, setSrc] = useState(null)

  useEffect(() => {
    const fetchPdf = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade (db) {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME)
          }
        }
      })

      try {
        const cachedFile = await db.get(STORE_NAME, document.url)
        if (cachedFile) {
          // Convert Blob to object URL
          const objectURL = URL.createObjectURL(cachedFile)
          setSrc(objectURL)
        } else {
          const response = await fetch(document.url)
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`)
          }
          const blob = await response.blob()

          // Store the Blob in IndexedDB
          await db.put(STORE_NAME, blob, document.url)

          // Convert Blob to object URL
          const objectURL = URL.createObjectURL(blob)
          setSrc(objectURL)
        }
      } catch (error) {
        console.error('Error fetching PDF: ', error)
      }
    }
    if (document && document.url) {
      fetchPdf()
    }

    return () => {
      // Clean up the object URL when the component unmounts
      if (src) {
        URL.revokeObjectURL(src)
      }
    }
  }, [document])

  return (
    <>
      <Badge
        badgeContent={'DRAFT'}
        color='error'
        className='draftBadge'
        invisible={!isDraftCheck(document)}
      >
        <Card sx={{ maxWidth: 345, p: 1 }}>
          <div className='previewHolder'>
            <div
              className='previewCover'
              onClick={() =>
                navigate(
                  '/view/' +
                    (typeof document.customRoute !== 'undefined' &&
                    document.customRoute.length > 0
                      ? document.customRoute
                      : document.id)
                )
              }
            ></div>
            <iframe
              loading='lazy'
              className='previewFrame'
              title={'document ' + document.fileName + document.uid}
              src={src}
              style={{ width: '100%' }}
            />
          </div>
          <CardHeader
            action={
              <>
                {isDraftCheck(document) ? (
                  <Tooltip title='Finish Document Upload'>
                    <IconButton
                      aria-label='edit draft'
                      onClick={() => navigate('/draft/' + document.id)}
                    >
                      <Publish />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Edit Document'>
                    <IconButton
                      aria-label='edit'
                      onClick={() => navigate('/edit/' + document.id)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            }
            title={
              <div
                className='pointer'
                onClick={() =>
                  navigate(
                    '/view/' +
                      (typeof document.customRoute !== 'undefined' &&
                      document.customRoute.length > 0
                        ? document.customRoute
                        : document.id)
                  )
                }
              >
                {document.fileName}
              </div>
            }
            subheader={
              <>
                {document.displayName +
                  (document.hostName ? ` (${document.hostName})` : '')}
                <br />
                {getComboDateString(document.visibleDate)}
              </>
            }
          />
          <CardActions sx={{ justifyContent: 'space-around' }}>
            {!isDraftCheck(document) && (
              <>
                <Tooltip title='Comments'>
                  <Button
                    startIcon={<Comment />}
                    onClick={() =>
                      navigate(
                        '/view/' +
                          (typeof document.customRoute !== 'undefined' &&
                          document.customRoute.length > 0
                            ? document.customRoute
                            : document.id)
                      )
                    }
                  >
                    {document.comments ? document.comments.length : '0'}
                  </Button>
                </Tooltip>

                <Tooltip title='Visit Count'>
                  <Button
                    startIcon={<Visibility />}
                    onClick={() =>
                      navigate(
                        '/view/' +
                          (typeof document.customRoute !== 'undefined' &&
                          document.customRoute.length > 0
                            ? document.customRoute
                            : document.id)
                      )
                    }
                  >
                    {document.visited ? document.visited : '0'}
                  </Button>
                </Tooltip>

                <Tooltip title='Share'>
                  <Button
                    onClick={() => setShowModal(true)}
                    sx={{ minWidth: 'unset' }}
                  >
                    <IosShare />
                  </Button>
                </Tooltip>

                <DownloadButton document={document} icon={false} db={db} />
              </>
            )}
            <Tooltip title='Open Document'>
              <Button
                onClick={() =>
                  navigate(
                    '/view/' +
                      (typeof document.customRoute !== 'undefined' &&
                      document.customRoute.length > 0
                        ? document.customRoute
                        : document.id)
                  )
                }
                sx={{ minWidth: 'unset' }}
              >
                <FileOpen />
              </Button>
            </Tooltip>
          </CardActions>
        </Card>
      </Badge>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
        fullWidth
      >
        <EmailShare
          setShowModal={setShowModal}
          db={db}
          id={document.id}
          viewCode={document.viewCode || null}
          fileName={document.fileName}
          customURL={document.customRoute || null}
        />
      </Dialog>
    </>
  )
}

export default Dashboard
