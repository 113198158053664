import { Box, Card, CardContent } from '@mui/material'
import Puzzle from '../../Icons/puzzle.jpg'
import Document from '../../Icons/document.jpg'
import { Code, Comment, Groups, IosShare, Star } from '@mui/icons-material'
import { useEffect } from 'react'
import { logEvent } from 'firebase/analytics'

const About = ({ analytics }) => {
  useEffect(() => {
    logEvent(analytics, 'about')
  }, [])
  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardContent sx={{ maxWidth: '800px', m: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <h3
            style={{
              color: '#1976d2'
            }}
          >
            About Community Document
          </h3>
          <h1>Engage, Revise, Empower</h1>
        </Box>
        <h4>
          Community Document is not just another platform for sharing files;
          it's a dynamic ecosystem designed to enhance communication and
          engagement. We understand that traditional documents and PDFs can
          often feel static and uninspiring, which is why we've developed an
          innovative solution that breathes life into your content.
        </h4>
        <h4>
          Imagine transforming your mundane presentations, reports, or event
          materials into captivating experiences that truly resonate with your
          audience. With Community Document, this vision becomes a reality. Our
          platform empowers you to create immersive documents that go beyond
          mere text and images.
        </h4>
        <Box
          sx={{
            display: 'flex',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            m: '24px 0'
          }}
        >
          <img
            src={Puzzle}
            alt='Working together as a community'
            style={{ borderRadius: '4px', width: '50%' }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: '#1976d2'
              }}
            >
              <Star /> <h3>Our Mission</h3>
            </Box>
            <h4>
              Our mission is to revolutionize the way people communicate and
              engage with information. Whether you're creating a presentation,
              report, or event materials, Community Document empowers you to
              captivate your audience like never before. With our intuitive
              tools, you can easily add documents and reach your audience. Plus,
              our interactive features keep your audience engaged and informed
              by allowing commenting, questioning and suggesting right on the
              document!
            </h4>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            m: '24px 0'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: '#1976d2'
              }}
            >
              <Groups /> <h3>Engage</h3>
            </Box>
            <h4>
              One of the key aspects of our mission is to revolutionize how
              people interact with information. We believe that communication
              should be a two-way street, where audiences can actively engage
              with content rather than passively consume it. That's why we've
              integrated a range of interactive features into our platform.
            </h4>
          </Box>

          <img
            src={Document}
            alt='Collaborate on Documents/PDFs'
            style={{ borderRadius: '4px', width: '50%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <Code /> <h3>Embed</h3>
        </Box>
        <h4>
          For instance, imagine being able to embed our platform directly on to
          your sites. This not only adds depth to your content but also
          encourages active participation from your audience. Moreover, our
          platform facilitates seamless collaboration by enabling commenting,
          questioning, and suggesting directly within the document interface.
        </h4>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <IosShare /> <h3>Share</h3>
        </Box>
        <h4>
          Our intuitive tools make it easy to create and share documents,
          ensuring that your message reaches your audience effectively. Whether
          you're a business professional delivering a pitch, an educator
          presenting a lesson, or an event organizer distributing materials,
          Community Document provides the tools you need to captivate your
          audience and make a lasting impression.
        </h4>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#1976d2'
          }}
        >
          <Comment /> <h3>Communicate</h3>
        </Box>
        <h4>
          In essence, Community Document represents a paradigm shift in how we
          communicate and engage with information. It's not just about sharing
          files; it's about fostering meaningful connections and facilitating
          impactful interactions.
        </h4>
        <h2 style={{ textAlign: 'center', color: '#1976d2' }}>
          Join us in transforming the way you communicate with Community
          Document.
        </h2>
      </CardContent>
    </Card>
  )
}

export default About
