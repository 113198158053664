import { Slide } from '@mui/material';
import Paper from '@mui/material/Paper'
import { forwardRef } from 'react';
import Draggable from 'react-draggable'

export function PaperComponent (props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export const TransitionUp = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});