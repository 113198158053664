import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Stack
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import communityLogo from '../Icons/logoAlt.png'
import { Login, Settings } from '@mui/icons-material'
import '../Style/Layout.css'
import Footer from './Support/Footer'

const Layout = ({
  auth,
  setUid,
  uid,
  db,
  setUserType,
  savedLocation,
  setSavedLocation,
  setDisplayName,
  setEmail,
  overrideLocation,
  setOverrideLocation,
  dark,
  setDark
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const [pages, setPages] = useState(['Search'])
  const [settings, setSettings] = useState(['Profile', 'Sign Out'])
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const scrollRef = useRef()

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const navigateTo = page => {
    if (uid) {
      handleCloseNavMenu()
      handleCloseUserMenu()
      navigate('/' + page.toLowerCase().split(' ').join(''))
    }
  }

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    switch (pathname) {
      case '/dashboard':
        document.title = 'Community Document: Dashboard'
        break
      case '/search':
        document.title = 'Community Document: Search'
        break
      case '/upload':
        document.title = 'Community Document: Upload'
        break
      case '/edit':
        document.title = 'Community Document: Edit'
        break
      default:
        document.title = 'Community Document'
    }
  }, [pathname])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user === null) {
        if (pathname.includes('/view')) {
          var result = pathname.substring(pathname.lastIndexOf('/') + 1)
          const docRef = doc(db, 'documents', result)
          const docSnap = await getDoc(docRef)
          if (
            docSnap.exists() &&
            docSnap.data().signInView !== 'undefined' &&
            docSnap.data().signInView === false
          ) {
          } else {
            let q1 = query(
              collection(db, 'documents'),
              where('customRoute', '==', result)
            )
            const querySnapshot = await getDocs(q1)
            if (querySnapshot.docs.length === 0) {
              if (location.key === 'default') {
                setSavedLocation(overrideLocation || window.location.pathname)
              } else {
                pages.length > 0 &&
                  setSavedLocation('/' + pages[0].toLowerCase())
              }
              navigate('/signup')
            } else {
              querySnapshot.forEach(docSnap2 => {
                if (
                  !(
                    docSnap2.data().signInView !== 'undefined' &&
                    docSnap2.data().signInView === false
                  )
                ) {
                  if (location.key === 'default') {
                    setSavedLocation(
                      overrideLocation || window.location.pathname
                    )
                  } else {
                    pages.length > 0 &&
                      setSavedLocation('/' + pages[0].toLowerCase())
                  }
                  navigate('/signup')
                }
              })
            }
          }
        } else if (pathname.includes('/support/')) {
        } else if (
          !pathname.includes('/signup') &&
          !pathname.includes('/signUp') &&
          !pathname.includes('/signin')
        ) {
          if (location.key === 'default') {
            setSavedLocation(overrideLocation || window.location.pathname)
          } else {
            pages.length > 0 && setSavedLocation('/' + pages[0].toLowerCase())
          }
          navigate('/signup')
        }
        setPages(['Search'])
        setUid(null)
      } else {
        setUid(user.uid)
        let docRef = doc(db, 'users', user.uid)
        let goTo = 'search'
        let email = ''
        let paid = false
        let docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          let data = docSnap.data()
          setUserType(data.paid)
          setDisplayName(data.displayName)
          if (data.paid) {
            setPages(['Dashboard', 'Upload', 'Search'])
            goTo = 'dashboard'
            paid = data.paid
            if (data.superAdmin) {
              setPages(['Dashboard', 'Upload', 'Search', 'ADMIN'])
            }
          }
          email = data.email
        } else {
          return
        }
        setEmail(email)
        //find admin obj
        if (email !== '' && paid) {
          let q1 = query(
            collection(db, 'documents'),
            where('adminEmails', 'array-contains', email)
          )
          const querySnapshot = await getDocs(q1)
          querySnapshot.forEach(async docRef => {
            if (
              !docRef.data().admins ||
              !docRef.data().admins.includes(user.uid)
            ) {
              await setDoc(
                doc(db, 'documents', docRef.id),
                {
                  admins: arrayUnion(user.uid)
                },
                {
                  merge: true
                }
              )
              await setDoc(
                doc(db, 'users', user.uid),
                {
                  documentIds: arrayUnion(docRef.id)
                },
                {
                  merge: true
                }
              )
            }
          })
        }

        if (
          !pathname.includes('/dashboard') &&
          !pathname.includes('/upload') &&
          !pathname.includes('/view') &&
          !pathname.includes('/search') &&
          !pathname.includes('/profile') &&
          !pathname.includes('/edit') &&
          !pathname.includes('/support/') &&
          !pathname.includes('/draft') &&
          !pathname.includes('/copy')
        ) {
          if (savedLocation) {
            navigate(savedLocation)
            pages.length > 0 &&
              setSavedLocation(overrideLocation || '/' + pages[0].toLowerCase())
          } else {
            navigate('/' + goTo)
          }
        }
      }

      if (window !== window.parent) {
        setPages([])
        setSettings(['Sign Out'])
        setOverrideLocation(window.location.pathname)
      }
    })

    return unsubscribe
  }, [])

  return (
    <>
      <AppBar position='sticky' sx={{ top: '0' }}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                height: '75px',
                mr: '10px'
              }}
            >
              <img
                onClick={() =>
                  uid && pages.length > 0
                    ? navigate('/' + pages[0].toLowerCase())
                    : navigate('/signup')
                }
                alt='logo'
                src={communityLogo}
                style={{
                  maxWidth: '100%',
                  objectFit: 'contain',
                  height: '60px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  margin: '8px'
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              {uid !== null && (
                <>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}
                    color='inherit'
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' }
                    }}
                  >
                    {pages.map(page => (
                      <MenuItem key={page} onClick={() => navigateTo(page)}>
                        <Typography textAlign='center'>{page}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                flexGrow: 1,
                height: '5vh'
              }}
            >
              <img
                onClick={() =>
                  uid && pages.length > 0
                    ? navigate('/' + pages[0].toLowerCase())
                    : navigate('/signup')
                }
                alt='logo'
                src={communityLogo}
                style={{
                  maxWidth: '100%',
                  objectFit: 'contain',
                  height: '5vh',
                  cursor: 'pointer',
                  borderRadius: '4px'
                }}
              />
            </Box>
            {uid !== null ? (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                    gap: 1
                  }}
                >
                  {pages.map(page => (
                    <Button
                      key={page}
                      onClick={() => navigateTo(page)}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                      className={
                        pathname.includes(page.toLowerCase())
                          ? 'selectedPage'
                          : ''
                      }
                    >
                      {page}
                    </Button>
                  ))}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title='Open Settings'>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt='settings'>
                        <Settings />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id='menu-appbar'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map(setting => (
                      <MenuItem
                        key={setting}
                        onClick={() => navigateTo(setting)}
                      >
                        <Typography textAlign='center'>{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </>
            ) : (
              <Stack direction='row' spacing={2} sx={{ ml: 'auto' }}>
                <Button
                  onClick={() => navigate('/support/about')}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: { xs: 'none', md: 'block' }
                  }}
                >
                  About
                </Button>
                <Button
                  onClick={() => navigate('/support/contact')}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: { xs: 'none', md: 'block' }
                  }}
                >
                  Contact Us
                </Button>
                <Button
                  onClick={() => {
                    setSavedLocation(
                      overrideLocation || window.location.pathname
                    )
                    navigate('/signup')
                  }}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: { xs: 'none', md: 'block' }
                  }}
                >
                  Sign Up
                </Button>
                <IconButton
                  onClick={() => {
                    setSavedLocation(
                      overrideLocation || window.location.pathname
                    )
                    navigate('/signup')
                  }}
                  sx={{ my: 2, display: { xs: 'block', md: 'none' } }}
                >
                  <Login sx={{ fill: 'white' }} />
                </IconButton>
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 88px)',
          paddingTop: { md: '16px', xs: '24px' }
        }}
      >
        <div ref={scrollRef} />
        <div style={{ minHeight: '100%' }}>
          <Outlet />
        </div>
        <Footer dark={dark} setDark={setDark} />
      </Box>
    </>
  )
}

export default Layout
