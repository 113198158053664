import { Card, Divider, Stack } from '@mui/material'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import AlgoliaLogo from '../../../Icons/algolia.png'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, useHits } from 'react-instantsearch'
import '../../../Style/Search.css'
import SearchResultTile, { SearchTileRow } from './SearchResultTile'
import WelcomeAlert from '../WelcomeAlert'
import Loading from '../../Helpers/Loading'

import BrandedSearch from '../../../Icons/document.jpg'

const Search = ({ uid, db, displayName }) => {
  const algoliaClient = algoliasearch(
    '5QYIIRZJEF',
    'b417e34e1db9887a2765845c18d1fc96'
  )

  const searchClient = {
    ...algoliaClient,
    search (requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        })
      }

      return algoliaClient.search(requests)
    }
  }

  const [visited, setVisited] = useState([])
  const [yours, setYours] = useState([])
  const [popular, setPopular] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const today = Date.now()

    const getDate = date => {
      return new Date(date.toDate())
    }

    const checkDates = document => {
      return (
        (document.visibleDate &&
          today >= getDate(document.visibleDate.startDate) &&
          today <= getDate(document.visibleDate.endDate)) ||
        document.uid === uid ||
        (document.admins && document.admins.includes(uid))
      )
    }

    const preSearch = async () => {
      const userRef = await getDoc(doc(db, 'users', uid))
      if (userRef.exists()) {
        let data = userRef.data()
        let c = data.commentedOn ? data.commentedOn.reverse().slice(0, 10) : []
        let v = data.visited
          ? data.visited
              .reverse()
              .filter(val => !c.includes(val))
              .slice(0, 10)
          : []

        let c2 = []
        let v2 = []

        if (c.length > 0) {
          let q1 = query(
            collection(db, 'documents'),
            where('__name__', 'in', c)
          )
          const querySnapshot = await getDocs(q1)
          querySnapshot.forEach(doc => {
            let c_val = doc.data()
            c_val.id = doc.id
            checkDates(c_val) && c2.push(c_val)
          })
        }
        if (v.length > 0) {
          let q2 = query(
            collection(db, 'documents'),
            where('__name__', 'in', v)
          )
          const querySnapshot2 = await getDocs(q2)
          querySnapshot2.forEach(doc => {
            let v_val = doc.data()
            v_val.id = doc.id
            checkDates(v_val) && v2.push(v_val)
          })
        }
        setVisited([...c2, ...v2])

        let q3 = query(
          collection(db, 'documents'),
          where('uid', '==', uid),
          orderBy('editDate', 'desc')
        )
        const querySnapshot3 = await getDocs(q3)
        let y2 = []
        querySnapshot3.forEach(doc => {
          let y_val = doc.data()
          y_val.id = doc.id
          y2.push(y_val)
        })
        setYours(y2)
      }

      let q4 = query(
        collection(db, 'documents'),
        where('searchable', '==', true),
        orderBy('commentCount', 'desc'),
        limit(10)
      )
      const querySnapshot3 = await getDocs(q4)
      let p2 = []
      querySnapshot3.forEach(doc => {
        let p_val = doc.data()
        p_val.id = doc.id
        checkDates(p_val) && p2.push(p_val)
      })
      setPopular(p2)
      setLoading(false)
    }

    if (uid) {
      preSearch()
    }
  }, [uid])

  return (
    <Card
      raised
      sx={{
        m: { md: 2, xs: 0 },
        mb: { md: 2, xs: 2 },
        p: 2,
        minHeight: '95%',
        pb: 2
      }}
      className='searchPage'
    >
      <WelcomeAlert displayName={displayName} />
      <Stack
        direction='column'
        alignItems='center'
        spacing={2}
        sx={{ mb: 2, mt: 2 }}
      >
        <img
          src={AlgoliaLogo}
          alt='Algolia Logo'
          style={{ height: '30px', margin: '5px', objectFit: 'contain' }}
        />
        <InstantSearch searchClient={searchClient} indexName='documents'>
          <SearchBox
            classNames={{
              input: 'searchInput',
              submit: 'searchButton',
              form: 'searchForm',
              reset: 'searchReset',
              submitIcon: 'searchIcon'
            }}
            placeholder='Search'
            onSubmit={() => setLoading(true)}
          />
          <h3>Search Results</h3>

          {loading ? (
            <Loading padding='50px 0' />
          ) : (
            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                maxWidth: '90vw',
                gap: '10px',
                paddingRight: '10px'
              }}
            >
              <CustomHits
                db={db}
                uid={uid}
                setLoading={setLoading}
                inLibrary={[...visited, ...yours, ...popular]}
              />
            </div>
          )}
        </InstantSearch>

        {yours.length > 0 && (
          <>
            <Divider component='div' sx={{ width: '75%' }} />
            <SearchTileRow documents={yours} title={'Your Documents'} />
          </>
        )}

        {visited.length > 0 && (
          <>
            <Divider component='div' sx={{ width: '75%' }} />
            <SearchTileRow documents={visited} title={'Recently Viewed'} />
          </>
        )}

        {popular.length > 0 && (
          <>
            <Divider component='div' sx={{ width: '75%' }} />
            <SearchTileRow documents={popular} title={'Popular'} />
          </>
        )}
      </Stack>
    </Card>
  )
}

function CustomHits (props) {
  const { hits } = useHits(props)
  if (hits.length > 0) {
    props.setLoading(false)
  }

  return (
    <>
      {hits.length > 0 ? (
        <>
          {hits.map(hit => (
            <SearchResultTile
              documentResult={hit}
              title={'your_search'}
              db={props.db}
              uid={props.uid}
              key={hit.path}
            />
          ))}
        </>
      ) : (
        <img
          src={BrandedSearch}
          alt='Collaborate on Documents/PDFs'
          style={{ borderRadius: '4px', maxHeight: '200px' }}
        />
      )}
    </>
  )
}

export default Search
