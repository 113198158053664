import { Card, CardContent, CardHeader, Link } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'

const DPA = ({ analytics }) => {
  useEffect(() => {
    logEvent(analytics, 'dpa')
  }, [])
  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardHeader title='Data Protection Addendum' />
      <CardContent>
        <div className='w-richtext'>
          <h2>The General Data Protection Regulations</h2>

          <p>
            The General Data Protection Regulations (GDPR) is a regulation in
            the European Union (EU) law on data protection and privacy for all
            individuals within EU. It came into effect on May 25, 2018 and
            applies to all organizations which collect, store and/or process EU
            personal data.
          </p>
          <p>
            At DBC Media Online, LLC, we value privacy and transparency and we
            want to let our customer know that DBC Media Online has taken
            complete measures to comply with these regulations. To that end we
            have created this Data Processing Addendum (“DPA”) which describes
            our data processing practices.
          </p>
          <p>
            Need a signed copy? <Link href='/support/contact'>Contact us</Link>
          </p>
          <h2>Data Protection Addendum</h2>
          <p>
            This Data Protection Addendum ("
            <strong>Addendum</strong>") forms part of the{' '}
            <Link href='/support/terms'>Terms and Conditions</Link> ("
            <strong>Principal Agreement</strong>", "<strong>Agreement</strong>")
            between: (i) DBC Media Online, LLC ("
            <strong>Vendor</strong>") acting on its own behalf and as agent for
            each Vendor Affiliate; and (ii) you the Customer ("
            <strong>Company</strong>") acting on its own behalf and as agent for
            each Company Affiliate.
          </p>
          <p>
            The terms used in this Addendum shall have the meanings set forth in
            this Addendum. Capitalized terms not otherwise defined herein shall
            have the meaning given to them in the Principal Agreement. Except as
            modified below, the terms of the Principal Agreement shall remain in
            full force and effect.
          </p>
          <p>
            In consideration of the mutual obligations set out herein, the
            parties hereby agree that the terms and conditions set out below
            shall be added as an Addendum to the Principal Agreement. Except
            where the context requires otherwise, references in this Addendum to
            the Principal Agreement are to the Principal Agreement as amended
            by, and including, this Addendum.
          </p>
          <h2>
            <strong>1. Definitions</strong>
          </h2>
          <p>
            1.1 In this Addendum, the following terms shall have the meanings
            set out below and cognate terms shall be construed accordingly:
          </p>
          <p>
            1.1.1 "<strong>Applicable Laws</strong>" means (a) United States or
            European Union or Member State laws with respect to any Company
            Personal Data in respect of which any Company Group Member is
            subject to EU Data Protection Laws; and (b) any other applicable law
            with respect to any Company Personal Data in respect of which any
            Company Group Member is subject to any other Data Protection Laws;
          </p>
          <p>
            1.1.2 "<strong>Company Affiliate</strong>" means an entity that owns
            or controls, is owned or controlled by or is or under common control
            or ownership with Company, where control is defined as the
            possession, directly or indirectly, of the power to direct or cause
            the direction of the management and policies of an entity, whether
            through ownership of voting securities, by contract or otherwise;
          </p>
          <p>
            1.1.3 "<strong>Company Group Member</strong>" means Company or any
            Company Affiliate;
          </p>
          <p>
            1.1.4 "<strong>Company Personal Data</strong>" means any Personal
            Data Processed by a Contracted Processor on behalf of a Company
            Group Member pursuant to or in connection with the Principal
            Agreement;
          </p>
          <p>
            1.1.5 "<strong>Contracted Processor</strong>" means Vendor or a
            Subprocessor;
          </p>
          <p>
            1.1.6 "<strong>Data Protection Laws</strong>" means EU Data
            Protection Laws, United States data protection laws and, to the
            extent applicable, the data protection or privacy laws of any other
            country;
          </p>
          <p>
            1.1.7 "<strong>EEA</strong>" means the European Economic Area;
          </p>
          <p>
            1.1.8 "<strong>EU Data Protection Laws</strong>" means EU Directive
            95/46/EC, as transposed into domestic legislation of each Member
            State and as amended, replaced or superseded from time to time,
            including by the GDPR and laws implementing or supplementing the
            GDPR;
          </p>
          <p>
            1.1.9 "<strong>GDPR</strong>" means EU General Data Protection
            Regulation 2016/679;
          </p>
          <p>
            1.1.10 "<strong>Restricted Transfer</strong>"means:
          </p>
          <p>
            1.1.10.1 a transfer of Company Personal Data from any Company Group
            Member to a Contracted Processor; or
          </p>
          <p>
            1.1.10.2 an onward transfer of Company Personal Data from a
            Contracted Processor to a Contracted Processor, or between two
            establishments of a Contracted Processor,
          </p>
          <p>
            in each case, where such transfer would be prohibited by Data
            Protection Laws (or by the terms of data transfer agreements put in
            place to address the data transfer restrictions of Data Protection
            Laws) in the absence of the Standard Contractual Clauses to be
            established under section 6.4.3 or 12 below;
          </p>
          <p>
            1.1.11 "<strong>Services</strong>" means the services and other
            activities to be supplied to or carried out by or on behalf of
            Vendor for Company Group Members pursuant to the Principal
            Agreement;
          </p>
          <p>
            1.1.12 "<strong>Standard Contractual Clauses</strong>" means the
            contractual clauses set out in Annex 2, amended as indicated (in
            square brackets and italics) in that Annex and under section 13.4;
          </p>
          <p>
            1.1.13 "<strong>Subprocessor</strong>" means any person (including
            any third party and any Vendor Affiliate, but excluding an employee
            of Vendor or any of its sub-contractors) appointed by or on behalf
            of Vendor or any Vendor Affiliate to Process Personal Data on behalf
            of any Company Group Member in connection with the Principal
            Agreement; and
          </p>
          <p>
            1.1.14 "<strong>Vendor Affiliate</strong>" means an entity that owns
            or controls, is owned or controlled by or is or under common control
            or ownership with Vendor, where control is defined as the
            possession, directly or indirectly, of the power to direct or cause
            the direction of the management and policies of an entity, whether
            through ownership of voting securities, by contract or otherwise.
          </p>
          <p>
            1.2 The terms, "<strong>Commission</strong>", "
            <strong>Controller</strong>", "<strong>Data Subject</strong>", "
            <strong>Member State</strong>", "<strong>Personal Data</strong>", "
            <strong>Personal Data Breach</strong>", "<strong>Processing</strong>
            " and "<strong>Supervisory Authority</strong>" shall have the same
            meaning as in the GDPR, and their cognate terms shall be construed
            accordingly.
          </p>
          <p>
            1.3 The word "<strong>include</strong>" shall be construed to mean
            include without limitation, and cognate terms shall be construed
            accordingly.
          </p>
          <h2>
            <strong>2. Authority</strong>
          </h2>
          <p>
            Vendor warrants and represents that, before any Vendor Affiliate
            Processes any Company Personal Data on behalf of any Company Group
            Member, Vendor's entry into this Addendum as agent for and on behalf
            of that Vendor Affiliate will have been duly and effectively
            authorized (or subsequently ratified) by that Vendor Affiliate.
          </p>
          <h2>
            <strong>3. Processing of Company Personal Data</strong>
          </h2>
          <p>3.1 Vendor and each Vendor Affiliate shall:</p>
          <p>
            3.1.1 comply with all applicable Data Protection Laws in the
            Processing of Company Personal Data; and
          </p>
          <p>
            3.1.2 not Process Company Personal Data other than on the relevant
            Company Group Member’s documented instructions unless Processing is
            required by Applicable Laws to which the relevant Contracted
            Processor is subject, in which case Vendor or the relevant Vendor
            Affiliate shall to the extent permitted by Applicable Laws inform
            the relevant Company Group Member of that legal requirement before
            the relevant Processing of that Personal Data.
          </p>
          <p>3.2 Each Company Group Member:</p>
          <p>
            3.2.1 instructs Vendor and each Vendor Affiliate (and authorizes
            Vendor and each Vendor Affiliate to instruct each Subprocessor) to:
          </p>
          <p>3.2.1.1 Process Company Personal Data; and</p>
          <p>
            3.2.1.2 in particular, transfer Company Personal Data to any country
            or territory,
          </p>
          <p>
            as reasonably necessary for the provision of the Services and
            consistent with the Principal Agreement; and
          </p>
          <p>
            3.2.2 warrants and represents that it is and will at all relevant
            times remain duly and effectively authorized to give the instruction
            set out in section 3.2.1 on behalf of each relevant Company
            Affiliate.
          </p>
          <p>
            3.3 Annex 1 to this Addendum sets out certain information regarding
            the Contracted Processors' Processing of the Company Personal Data
            as required by article 28(3) of the GDPR (and, possibly, equivalent
            requirements of other Data Protection Laws). Company may make
            reasonable amendments to Annex 1 by written notice to Vendor from
            time to time as Company reasonably considers necessary to meet those
            requirements. Nothing in Annex 1 (including as amended pursuant to
            this section 3.3) confers any right or imposes any obligation on any
            party to this Addendum.
          </p>
          <h2>
            <strong>4. Vendor and Vendor Affiliate Personnel</strong>
          </h2>
          <p>
            Vendor and each Vendor Affiliate shall take reasonable steps to
            ensure the reliability of any employee, agent or contractor of any
            Contracted Processor who may have access to the Company Personal
            Data, ensuring in each case that access is strictly limited to those
            individuals who need to know / access the relevant Company Personal
            Data, as strictly necessary for the purposes of the Principal
            Agreement, and to comply with Applicable Laws in the context of that
            individual's duties to the Contracted Processor, ensuring that all
            such individuals are subject to confidentiality undertakings or
            professional or statutory obligations of confidentiality.
          </p>
          <h2>
            <strong>5. Security</strong>
          </h2>
          <p>
            5.1 Taking into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of
            Processing as well as the risk of varying likelihood and severity
            for the rights and freedoms of natural persons, Vendor and each
            Vendor Affiliate shall in relation to the Company Personal Data
            implement appropriate technical and organizational measures to
            ensure a level of security appropriate to that risk, including, as
            appropriate, the measures referred to in Article 32(1) of the GDPR.
          </p>
          <p>
            5.2 In assessing the appropriate level of security, Vendor and each
            Vendor Affiliate shall take account in particular of the risks that
            are presented by Processing, in particular from a Personal Data
            Breach.
          </p>
          <h2>
            6.
            <strong>Subprocessing</strong>
          </h2>
          <p>
            6.1 Each Company Group Member authorizes Vendor and each Vendor
            Affiliate to appoint (and permit each Subprocessor appointed in
            accordance with this section 6 to appoint) Subprocessors in
            accordance with this section 6 and any restrictions in the Principal
            Agreement.
          </p>
          <p>
            6.2 Vendor and each Vendor Affiliate may continue to use those
            Subprocessors already engaged by Vendor or any Vendor Affiliate as
            at the date of this Addendum, subject to Vendor and each Vendor
            Affiliate in each case as soon as practicable meeting the
            obligations set out in section 6.4.
          </p>
          <p>
            6.3 Vendor will make an effort to give Company prior written notice
            of the appointment of any new Subprocessor, including full details
            of the Processing to be undertaken by the Subprocessor. If, within
            14 days of receipt of that notice, Company notifies Vendor in
            writing of any objections (on reasonable grounds) to the proposed
            appointment:
          </p>
          <p>
            6.3.1 Vendor shall work with Company in good faith to make available
            a commercially reasonable change in the provision of the Services
            which avoids the use of that proposed Subprocessor; and
          </p>
          <p>
            6.3.2 where such a change cannot be made within 31 days from
            Vendor's receipt of Company's notice, notwithstanding anything in
            the Principal Agreement, Company may by written notice to Vendor
            with immediate effect terminate the Principal Agreement to the
            extent that it relates to the Services which require the use of the
            proposed Subprocessor.
          </p>
          <p>
            6.4 With respect to each Subprocessor, Vendor or the relevant Vendor
            Affiliate shall:
          </p>
          <p>
            6.4.1 before the Subprocessor first Processes Company Personal Data
            (or, where relevant, in accordance with section 6.2 ), carry out
            adequate due diligence to ensure that the Subprocessor is capable of
            providing the level of protection for Company Personal Data required
            by the Principal Agreement;
          </p>
          <p>
            6.4.2 ensure that the arrangement between on the one hand (a)
            Vendor, or (b) the relevant Vendor Affiliate, or (c) the relevant
            intermediate Subprocessor; and on the other hand the Subprocessor,
            is governed by a written contract including terms which offer at
            least the same level of protection for Company Personal Data as
            those set out in this Addendum and meet the requirements of article
            28(3) of the GDPR;
          </p>
          <p>
            6.4.3 provide to Company for review such copies of the Contracted
            Processors' agreements with Subprocessors (which may be redacted to
            remove confidential commercial information not relevant to the
            requirements of this Addendum) as Company may request from time to
            time.
          </p>
          <p>
            6.5 Vendor and each Vendor Affiliate shall ensure that each
            Subprocessor performs the obligations under sections 3.1, 4, 5, 7.1,
            8.2, 9 and 11.1, as they apply to Processing of Company Personal
            Data carried out by that Subprocessor, as if it were party to this
            Addendum in place of Vendor.
          </p>
          <h2>
            <strong>7. Data Subject Rights</strong>
          </h2>
          <p>
            7.1 Taking into account the nature of the Processing, Vendor and
            each Vendor Affiliate shall assist each Company Group Member by
            implementing appropriate technical and organizational measures,
            insofar as this is possible, for the fulfillment of the Company
            Group Members' obligations, as reasonably understood by Company, to
            respond to requests to exercise Data Subject rights under the Data
            Protection Laws.
          </p>
          <p>7.2 Vendor shall:</p>
          <p>
            7.2.1 promptly notify Company if any Contracted Processor receives a
            request from a Data Subject under any Data Protection Law in respect
            of Company Personal Data; and
          </p>
          <p>
            7.2.2 ensure that the Contracted Processor does not respond to that
            request except on the documented instructions of Company or the
            relevant Company Affiliate or as required by Applicable Laws to
            which the Contracted Processor is subject, in which case Vendor
            shall to the extent permitted by Applicable Laws inform Company of
            that legal requirement before the Contracted Processor responds to
            the request.
          </p>
          <h2>
            8.
            <strong>Personal Data Breach</strong>
          </h2>
          <p>
            8.1 Vendor shall notify Company without undue delay upon Vendor or
            any Subprocessor becoming aware of a Personal Data Breach affecting
            Company Personal Data, providing Company with sufficient information
            to allow each Company Group Member to meet any obligations to report
            or inform Data Subjects of the Personal Data Breach under the Data
            Protection Laws.
          </p>
          <p>
            8.2 Vendor shall co-operate with Company and each Company Group
            Member and take such reasonable commercial steps as are directed by
            Company to assist in the investigation, mitigation and remediation
            of each such Personal Data Breach.
          </p>
          <h2>
            <strong>
              9. Data Protection Impact Assessment and Prior Consultation
            </strong>
          </h2>
          <p>
            Vendor and each Vendor Affiliate shall provide reasonable assistance
            to each Company Group Member with any data protection impact
            assessments, and prior consultations with Supervising Authorities or
            other competent data privacy authorities, which Company reasonably
            considers to be required of any Company Group Member by article 35
            or 36 of the GDPR or equivalent provisions of any other Data
            Protection Law, in each case solely in relation to Processing of
            Company Personal Data by, and taking into account the nature of the
            Processing and information available to, the Contracted Processors.
          </p>
          <h2>
            <strong>10. Deletion of Company Personal Data</strong>
          </h2>
          <p>
            10.1 Subject to sections 10.2 and 10.3 Vendor and each Vendor
            Affiliate shall promptly and in any event within 14 days of the date
            of cessation of any Services involving the Processing of Company
            Personal Data (the "<strong>CessationDate</strong>"), delete and
            procure the deletion of all copies of those Company Personal Data.
          </p>
          <p>
            10.2 Subject to section 10.3, Company may in its absolute discretion
            by written notice to Vendor within 30 days of the Cessation Date
            require Vendor and each Vendor Affiliate to (a) return a complete
            copy of all Company Personal Data to Company by secure file transfer
            in such format as is reasonably notified by Company to Vendor; and
            (b) delete and procure the deletion of all other copies of Company
            Personal Data Processed by any Contracted Processor. Vendor and each
            Vendor Affiliate shall comply with any such written request within
            30 days of the Cessation Date.
          </p>
          <p>
            10.3 Each Contracted Processor may retain Company Personal Data to
            the extent required by Applicable Laws and only to the extent and
            for such period as required by Applicable Laws and always provided
            that Vendor and each Vendor Affiliate shall ensure the
            confidentiality of all such Company Personal Data and shall ensure
            that such Company Personal Data is only Processed as necessary for
            the purpose(s) specified in the Applicable Laws requiring its
            storage and for no other purpose.
          </p>
          <p>
            10.4 Vendor shall provide written certification to Company that it
            and each Vendor Affiliate has fully complied with this section 10
            within 30 days of the Cessation Date.
          </p>
          <h2>
            <strong>11. Audit rights</strong>
          </h2>
          <p>
            11.1 Subject to section 11.2, Vendor and each Vendor Affiliate shall
            make available to each Company Group Member on request all
            information necessary to demonstrate compliance with this Addendum,
            and shall allow for and contribute to audits, including inspections,
            by any Company Group Member or an auditor mandated by any Company
            Group Member in relation to the Processing of the Company Personal
            Data by the Contracted Processors.
          </p>
          <p>
            11.2 Information and audit rights of the Company Group Members only
            arise under section 11.1 to the extent that the Principal Agreement
            does not otherwise give them information and audit rights meeting
            the relevant requirements of Data Protection Law (including, where
            applicable, article 28(3)(h) of the GDPR).
          </p>
          <h2>
            <strong>12. Restricted Transfers</strong>
          </h2>
          <p>
            12.1 Subject to section 12.3, each Company Group Member (as "data
            exporter") and each Contracted Processor, as appropriate, (as "data
            importer") hereby enter into the Standard Contractual Clauses in
            respect of any Restricted Transfer from that Company Group Member to
            that Contracted Processor.
          </p>
          <p>
            12.2 The Standard Contractual Clauses shall come into effect under
            section 12.1 on the later of:
          </p>
          <p>12.2.1 the data exporter becoming a party to them;</p>
          <p>12.2.2 the data importer becoming a party to them; and</p>
          <p>12.2.3 commencement of the relevant Restricted Transfer.</p>
          <p>
            12.3 Section 12.1 shall not apply to a Restricted Transfer unless
            its effect, together with other reasonably practicable compliance
            steps (which, for the avoidance of doubt, do not include obtaining
            consents from Data Subjects), is to allow the relevant Restricted
            Transfer to take place without breach of applicable Data Protection
            Law.
          </p>
          <h2>
            <strong>13. General Terms</strong>
          </h2>
          <h3>GOVERNING LAW AND JURISDICTION</h3>
          <p>
            13.1 Without prejudice to clauses 7 (Mediation and Jurisdiction) and
            9 (Governing Law) of the Standard Contractual Clauses:
          </p>
          <p>
            13.1.1 the parties to this Addendum hereby submit to the choice of
            jurisdiction stipulated in the Principal Agreement with respect to
            any disputes or claims howsoever arising under this Addendum,
            including disputes regarding its existence, validity or termination
            or the consequences of its nullity; and
          </p>
          <p>
            13.1.2 this Addendum and all non-contractual or other obligations
            arising out of or in connection with it are governed by the laws of
            the country or territory stipulated for this purpose in the
            Principal Agreement.
          </p>
          <h3>ORDER OF PRECEDENCE</h3>
          <p>
            13.2 Nothing in this Addendum reduces Vendor's or any Vendor
            Affiliate’s obligations under the Principal Agreement in relation to
            the protection of Personal Data or permits Vendor or any Vendor
            Affiliate to Process (or permit the Processing of) Personal Data in
            a manner which is prohibited by the Principal Agreement. In the
            event of any conflict or inconsistency between this Addendum and the
            Standard Contractual Clauses, the Standard Contractual Clauses shall
            prevail.
          </p>
          <p>
            13.3 Subject to section 13.2, with regard to the subject matter of
            this Addendum, in the event of inconsistencies between the
            provisions of this Addendum and any other agreements between the
            parties, including the Principal Agreement and including (except
            where explicitly agreed otherwise in writing, signed on behalf of
            the parties) agreements entered into or purported to be entered into
            after the date of this Addendum, the provisions of this Addendum
            shall prevail.
          </p>
          <h3>CHANGES IN DATA PROTECTION LAWS, ETC.</h3>
          <p>13.4 Company may:</p>
          <p>
            13.4.1 by at least 30 (thirty) calendar days' written notice to
            Vendor from time to time make any variations to the Standard
            Contractual Clauses (including any Standard Contractual Clauses
            entered into under section 12.1), as they apply to Restricted
            Transfers which are subject to a particular Data Protection Law,
            which are required, as a result of any change in, or decision of a
            competent authority under, that Data Protection Law, to allow those
            Restricted Transfers to be made (or continue to be made) without
            breach of that Data Protection Law; and
          </p>
          <p>
            13.4.2 propose any other variations to this Addendum which Company
            reasonably considers to be necessary to address the requirements of
            any Data Protection Law.
          </p>
          <p>13.5 If Company gives notice under section 13.4.1:</p>
          <p>
            13.5.1 Vendor and each Vendor Affiliate shall promptly co-operate
            (and ensure that any affected Subprocessors promptly co-operate) to
            ensure that equivalent variations are made to any agreement put in
            place under section 6.4.3; and
          </p>
          <p>
            13.5.2 Company shall not unreasonably withhold or delay agreement to
            any consequential variations to this Addendum proposed by Vendor to
            protect the Contracted Processors against additional risks
            associated with the variations made under section 13.4.1 or 13.5.1.
          </p>
          <p>
            13.6 If Company gives notice under section 13.4.2, the parties shall
            promptly discuss the proposed variations and negotiate in good faith
            with a view to agreeing and implementing those or alternative
            variations designed to address the requirements identified in
            Company's notice as soon as is reasonably practicable.
          </p>
          <p>
            13.7 Neither Company nor Vendor shall require the consent or
            approval of any Company Affiliate or Vendor Affiliate to amend this
            Addendum pursuant to this section 13.5 or otherwise.
          </p>
          <h3>SEVERANCE</h3>
          <p>
            13.8 Should any provision of this Addendum be invalid or
            unenforceable, then the remainder of this Addendum shall remain
            valid and in force. The invalid or unenforceable provision shall be
            either (i) amended as necessary to ensure its validity and
            enforceability, while preserving the parties’ intentions as closely
            as possible or, if this is not possible, (ii) construed in a manner
            as if the invalid or unenforceable part had never been contained
            therein.
            <br />
          </p>
          <h2>Annex 1: Standard Contractual Clauses</h2>
          <h3>STANDARD CONTRACTUAL CLAUSES (PROCESSORS)</h3>
          <p>
            For the purposes of Article 26(2) of Directive 95/46/EC for the
            transfer of personal data to processors established in third
            countries which do not ensure an adequate level of data protection.
          </p>
          <h3>BACKGROUND</h3>
          <p>
            The data exporter has entered into a data processing addendum
            (“DPA”) with the data importer. Pursuant to the terms of the DPA, it
            is contemplated that services provided by the data importer will
            involve the transfer of personal data to data importer. Data
            importer is located in a country not ensuring an adequate level of
            data protection. To ensure compliance with Directive 95/46/EC and
            applicable data protection law, the controller agrees to the
            provision of such Services, including the processing of personal
            data incidental thereto, subject to the data importer’s execution
            of, and compliance with, the terms of these Clauses.
          </p>
          <p>
            <strong>CLAUSE 1</strong>
          </p>
          <h3>DEFINITIONS</h3>
          <p>For the purposes of the Clauses:</p>
          <ul>
            <li>
              <em>‘personal data’</em>,<em>‘special categories of data’</em>,
              <em>‘process/processing’</em>,<em>‘controller’</em>,
              <em>‘processor’</em>,<em>‘data subject’</em> and
              <em>‘supervisory authority’</em> shall have the same meaning as in
              Directive 95/46/EC of the European Parliament and of the Council
              of 24 October 1995 on the protection of individuals with regard to
              the processing of personal data and on the free movement of such
              data;
            </li>
            <li>
              <em>‘the data exporter’</em> means the controller who transfers
              the personal data;
            </li>
            <li>
              <em>‘the data importer’</em> means the processor who agrees to
              receive from the data exporter personal data intended for
              processing on his behalf after the transfer in accordance with his
              instructions and the terms of the Clauses and who is not subject
              to a third country’s system ensuring adequate protection within
              the meaning of Article 25(1) of Directive 95/46/EC;
            </li>
            <li>
              <em>‘the subprocessor’</em> means any processor engaged by the
              data importer or by any other subprocessor of the data importer
              who agrees to receive from the data importer or from any other
              subprocessor of the data importer personal data exclusively
              intended for processing activities to be carried out on behalf of
              the data exporter after the transfer in accordance with his
              instructions, the terms of the Clauses and the terms of the
              written subcontract;
            </li>
            <li>
              <em>‘the applicable data protection law’</em> means the
              legislation protecting the fundamental rights and freedoms of
              individuals and, in particular, their right to privacy with
              respect to the processing of personal data applicable to a data
              controller in the Member State in which the data exporter is
              established;
            </li>
            <li>
              <em>‘technical and organisational security measures’</em> means
              those measures aimed at protecting personal data against
              accidental or unlawful destruction or accidental loss, alteration,
              unauthorised disclosure or access, in particular where the
              processing involves the transmission of data over a network, and
              against all other unlawful forms of processing.
            </li>
          </ul>
          <p>
            <strong>CLAUSE 2</strong>
          </p>
          <h3>DETAILS OF THE TRANSFER</h3>
          <p>
            The details of the transfer and in particular the special categories
            of personal data where applicable are specified in Appendix 1 which
            forms an integral part of the Clauses.
          </p>
          <p>
            <strong>CLAUSE 3</strong>
          </p>
          <h3>THIRD-PARTY BENEFICIARY CLAUSE</h3>
          <ol>
            <li>
              The data subject can enforce against the data exporter this
              Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j),
              Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as
              third-party beneficiary.
            </li>
            <li>
              The data subject can enforce against the data importer this
              Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause
              8(2), and Clauses 9 to 12, in cases where the data exporter has
              factually disappeared or has ceased to exist in law unless any
              successor entity has assumed the entire legal obligations of the
              data exporter by contract or by operation of law, as a result of
              which it takes on the rights and obligations of the data exporter,
              in which case the data subject can enforce them against such
              entity.
            </li>
            <li>
              The data subject can enforce against the subprocessor this Clause,
              Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and
              Clauses 9 to 12, in cases where both the data exporter and the
              data importer have factually disappeared or ceased to exist in law
              or have become insolvent, unless any successor entity has assumed
              the entire legal obligations of the data exporter by contract or
              by operation of law as a result of which it takes on the rights
              and obligations of the data exporter, in which case the data
              subject can enforce them against such entity. Such third-party
              liability of the subprocessor shall be limited to its own
              processing operations under the Clauses.
            </li>
            <li>
              The parties do not object to a data subject being represented by
              an association or other body if the data subject so expressly
              wishes and if permitted by national law.
            </li>
          </ol>
          <p>
            <strong>CLAUSE 4</strong>
          </p>
          <h3>OBLIGATIONS OF THE DATA EXPORTER</h3>
          <p>The data exporter agrees and warrants:</p>
          <ul>
            <li>
              that the processing, including the transfer itself, of the
              personal data has been and will continue to be carried out in
              accordance with the relevant provisions of the applicable data
              protection law (and, where applicable, has been notified to the
              relevant authorities of the Member State where the data exporter
              is established) and does not violate the relevant provisions of
              that State;
            </li>
            <li>
              that it has instructed and throughout the duration of the personal
              data processing services will instruct the data importer to
              process the personal data transferred only on the data exporter’s
              behalf and in accordance with the applicable data protection law
              and the Clauses;
            </li>
            <li>
              that the data importer will provide sufficient guarantees in
              respect of the technical and organisational security measures
              specified in Appendix 2 to this contract;
            </li>
            <li>
              that after assessment of the requirements of the applicable data
              protection law, the security measures are appropriate to protect
              personal data against accidental or unlawful destruction or
              accidental loss, alteration, unauthorised disclosure or access, in
              particular where the processing involves the transmission of data
              over a network, and against all other unlawful forms of
              processing, and that these measures ensure a level of security
              appropriate to the risks presented by the processing and the
              nature of the data to be protected having regard to the state of
              the art and the cost of their implementation;
            </li>
            <li>that it will ensure compliance with the security measures;</li>
            <li>
              that, if the transfer involves special categories of data, the
              data subject has been informed or will be informed before, or as
              soon as possible after, the transfer that its data could be
              transmitted to a third country not providing adequate protection
              within the meaning of Directive 95/46/EC;
            </li>
            <li>
              to forward any notification received from the data importer or any
              subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data
              protection supervisory authority if the data exporter decides to
              continue the transfer or to lift the suspension;
            </li>
            <li>
              to make available to the data subjects upon request a copy of the
              Clauses, with the exception of Appendix 2, and a summary
              description of the security measures, as well as a copy of any
              contract for subprocessing services which has to be made in
              accordance with the Clauses, unless the Clauses or the contract
              contain commercial information, in which case it may remove such
              commercial information;
            </li>
            <li>
              that, in the event of subprocessing, the processing activity is
              carried out in accordance with Clause 11 by a subprocessor
              providing at least the same level of protection for the personal
              data and the rights of data subject as the data importer under the
              Clauses; and
            </li>
            <li>that it will ensure compliance with Clause 4(a) to (i).</li>
          </ul>
          <p>
            <strong>CLAUSE 5</strong>
          </p>
          <h3>OBLIGATIONS OF THE DATA IMPORTER</h3>
          <p>The data importer agrees and warrants:</p>
          <ul>
            <li>
              to process the personal data only on behalf of the data exporter
              and in compliance with its instructions and the Clauses; if it
              cannot provide such compliance for whatever reasons, it agrees to
              inform promptly the data exporter of its inability to comply, in
              which case the data exporter is entitled to suspend the transfer
              of data and/or terminate the contract;
            </li>
            <li>
              that it has no reason to believe that the legislation applicable
              to it prevents it from fulfilling the instructions received from
              the data exporter and its obligations under the contract and that
              in the event of a change in this legislation which is likely to
              have a substantial adverse effect on the warranties and
              obligations provided by the Clauses, it will promptly notify the
              change to the data exporter as soon as it is aware, in which case
              the data exporter is entitled to suspend the transfer of data
              and/or terminate the contract;
            </li>
            <li>
              that it has implemented the technical and organisational security
              measures specified in Appendix 2 before processing the personal
              data transferred;
            </li>
            <li>that it will promptly notify the data exporter about:</li>
            <li>
              any legally binding request for disclosure of the personal data by
              a law enforcement authority unless otherwise prohibited, such as a
              prohibition under criminal law to preserve the confidentiality of
              a law enforcement investigation,
            </li>
            <li>any accidental or unauthorised access, and</li>
            <li>
              any request received directly from the data subjects without
              responding to that request, unless it has been otherwise
              authorised to do so;
            </li>
            <li>
              to deal promptly and properly with all inquiries from the data
              exporter relating to its processing of the personal data subject
              to the transfer and to abide by the advice of the supervisory
              authority with regard to the processing of the data transferred;
            </li>
            <li>
              at the request of the data exporter to submit its data processing
              facilities for audit of the processing activities covered by the
              Clauses which shall be carried out by the data exporter or an
              inspection body composed of independent members and in possession
              of the required professional qualifications bound by a duty of
              confidentiality, selected by the data exporter, where applicable,
              in agreement with the supervisory authority;
            </li>
            <li>
              to make available to the data subject upon request a copy of the
              Clauses, or any existing contract for subprocessing, unless the
              Clauses or contract contain commercial information, in which case
              it may remove such commercial information, with the exception of
              Appendix 2 which shall be replaced by a summary description of the
              security measures in those cases where the data subject is unable
              to obtain a copy from the data exporter;
            </li>
            <li>
              that, in the event of subprocessing, it has previously informed
              the data exporter and obtained its prior written consent;
            </li>
            <li>
              that the processing services by the subprocessor will be carried
              out in accordance with Clause 11;
            </li>
            <li>
              to send promptly a copy of any subprocessor agreement it concludes
              under the Clauses to the data exporter.
            </li>
          </ul>
          <p>
            <strong>CLAUSE 6</strong>
          </p>
          <h3>LIABILITY</h3>
          <ol>
            <li>
              The parties agree that any data subject, who has suffered damage
              as a result of any breach of the obligations referred to in Clause
              3 or in Clause 11 by any party or subprocessor is entitled to
              receive compensation from the data exporter for the damage
              suffered.
            </li>
            <li>
              If a data subject is not able to bring a claim for compensation in
              accordance with paragraph 1 against the data exporter, arising out
              of a breach by the data importer or his subprocessor of any of
              their obligations referred to in Clause 3 or in Clause 11, because
              the data exporter has factually disappeared or ceased to exist in
              law or has become insolvent, the data importer agrees that the
              data subject may issue a claim against the data importer as if it
              were the data exporter, unless any successor entity has assumed
              the entire legal obligations of the data exporter by contract of
              by operation of law, in which case the data subject can enforce
              its rights against such entity.
              <br />
              The data importer may not rely on a breach by a subprocessor of
              its obligations in order to avoid its own liabilities.
            </li>
            <li>
              If a data subject is not able to bring a claim against the data
              exporter or the data importer referred to in paragraphs 1 and 2,
              arising out of a breach by the subprocessor of any of their
              obligations referred to in Clause 3 or in Clause 11 because both
              the data exporter and the data importer have factually disappeared
              or ceased to exist in law or have become insolvent, the
              subprocessor agrees that the data subject may issue a claim
              against the data subprocessor with regard to its own processing
              operations under the Clauses as if it were the data exporter or
              the data importer, unless any successor entity has assumed the
              entire legal obligations of the data exporter or data importer by
              contract or by operation of law, in which case the data subject
              can enforce its rights against such entity. The liability of the
              subprocessor shall be limited to its own processing operations
              under the Clauses.
            </li>
          </ol>
          <p>
            <strong>CLAUSE 7</strong>
          </p>
          <h3>MEDIATION AND JURISDICTION</h3>
          <ol>
            <li>
              The data importer agrees that if the data subject invokes against
              it third-party beneficiary rights and/or claims compensation for
              damages under the Clauses, the data importer will accept the
              decision of the data subject:
            </li>
            <li>
              to refer the dispute to mediation, by an independent person or,
              where applicable, by the supervisory authority;
            </li>
            <li>
              to refer the dispute to the courts in the Member State in which
              the data exporter is established.
            </li>
            <li>
              The parties agree that the choice made by the data subject will
              not prejudice its substantive or procedural rights to seek
              remedies in accordance with other provisions of national or
              international law.
            </li>
          </ol>
          <p>
            <strong>CLAUSE 8</strong>
          </p>
          <h3>COOPERATION WITH SUPERVISORY AUTHORITIES</h3>
          <ol>
            <li>
              The data exporter agrees to deposit a copy of this contract with
              the supervisory authority if it so requests or if such deposit is
              required under the applicable data protection law.
            </li>
            <li>
              The parties agree that the supervisory authority has the right to
              conduct an audit of the data importer, and of any subprocessor,
              which has the same scope and is subject to the same conditions as
              would apply to an audit of the data exporter under the applicable
              data protection law.
            </li>
            <li>
              The data importer shall promptly inform the data exporter about
              the existence of legislation applicable to it or any subprocessor
              preventing the conduct of an audit of the data importer, or any
              subprocessor, pursuant to paragraph 2. In such a case the data
              exporter shall be entitled to take the measures foreseen in Clause
              5 (b).
            </li>
          </ol>
          <p>
            <strong>CLAUSE 9</strong>
          </p>
          <h3>GOVERNING LAW</h3>
          <p>
            The Clauses shall be governed by the law of the Member State in
            which the data exporter is established.
          </p>
          <p>
            <strong>CLAUSE 10</strong>
          </p>
          <h3>VARIATION OF THE CONTRACT</h3>
          <p>
            The parties undertake not to vary or modify the Clauses. This does
            not preclude the parties from adding clauses on business related
            issues where required as long as they do not contradict the Clause.
          </p>
          <p>
            <strong>CLAUSE 11</strong>
          </p>
          <h3>SUBPROCESSING</h3>
          <ol>
            <li>
              The data importer shall not subcontract any of its processing
              operations performed on behalf of the data exporter under the
              Clauses without the prior written consent of the data exporter.
              Where the data importer subcontracts its obligations under the
              Clauses, with the consent of the data exporter, it shall do so
              only by way of a written agreement with the subprocessor which
              imposes the same obligations on the subprocessor as are imposed on
              the data importer under the Clauses. Where the subprocessor fails
              to fulfil its data protection obligations under such written
              agreement the data importer shall remain fully liable to the data
              exporter for the performance of the subprocessor’s obligations
              under such agreement.
            </li>
            <li>
              The prior written contract between the data importer and the
              subprocessor shall also provide for a third-party beneficiary
              clause as laid down in Clause 3 for cases where the data subject
              is not able to bring the claim for compensation referred to in
              paragraph 1 of Clause 6 against the data exporter or the data
              importer because they have factually disappeared or have ceased to
              exist in law or have become insolvent and no successor entity has
              assumed the entire legal obligations of the data exporter or data
              importer by contract or by operation of law. Such third-party
              liability of the subprocessor shall be limited to its own
              processing operations under the Clauses.
            </li>
            <li>
              The provisions relating to data protection aspects for
              subprocessing of the contract referred to in paragraph 1 shall be
              governed by the law of the Member State in which the data exporter
              is established.
            </li>
            <li>
              The data exporter shall keep a list of subprocessing agreements
              concluded under the Clauses and notified by the data importer
              pursuant to Clause 5 (j), which shall be updated at least once a
              year. The list shall be available to the data exporter’s data
              protection supervisory authority.
            </li>
          </ol>
          <p>
            <strong>CLAUSE 12</strong>
          </p>
          <h3>
            OBLIGATION AFTER THE TERMINATION OF PERSONAL DATA PROCESSING
            SERVICES
          </h3>
          <ol>
            <li>
              The parties agree that on the termination of the provision of data
              processing services, the data importer and the subprocessor shall,
              at the choice of the data exporter, return all the personal data
              transferred and the copies thereof to the data exporter or shall
              destroy all the personal data and certify to the data exporter
              that it has done so, unless legislation imposed upon the data
              importer prevents it from returning or destroying all or part of
              the personal data transferred. In that case, the data importer
              warrants that it will guarantee the confidentiality of the
              personal data transferred and will not actively process the
              personal data transferred anymore.
            </li>
            <li>
              The data importer and the subprocessor warrant that upon request
              of the data exporter and/or of the supervisory authority, it will
              submit its data processing facilities for an audit of the measures
              referred to in paragraph 1.
            </li>
          </ol>
          <h2>Appendix 1 to the Standard Contractual Clauses</h2>
          <p>
            The Member States may complete or specify, according to their
            national procedures, any additional necessary information to be
            contained in this Appendix
          </p>
          <h3>DATA EXPORTER</h3>
          <p>The data exporter is: _________________</p>
          <h3>DATA IMPORTER</h3>
          <p>
            The data importer is:
            <em>DBC Media Online, LLC</em>
          </p>
          <h3>DATA SUBJECTS</h3>
          <p>
            The personal data transferred concern the following categories of
            data subjects:
            <br />
            <em>Users, Managers, Administrators</em>
          </p>
          <h3>CATEGORIES OF DATA</h3>
          <p>
            The personal data transferred concern the following categories of
            data:
            <br />
            <em>
              Identifying information as pertinent for the purposes involved
              with creating a user account, submitting and managing comments
              such as name, email, phone number (optional), connection data,
              personal life data.
            </em>
          </p>
          <h3>SPECIAL CATEGORIES OF DATA (IF APPROPRIATE)</h3>
          <p>
            The personal data transferred concern the following special
            categories of data:
            <em>n/a</em>
          </p>
          <h3>PROCESSING OPERATIONS</h3>
          <p>
            The personal data transferred will be subject to the following basic
            processing activities:
            <br />
            <em>
              Providing services for comment and survey collection and
              management.
            </em>
          </p>
          <h2>Appendix 2 to the Standard Contractual Clauses</h2>
          <p>
            <strong>
              Description of the technical and organizational security measures
              implemented by the data importer in accordance with Clauses 4(d)
              and 5(c):
            </strong>
          </p>
          <p>
            Firewalls, SSL certificates, web application firewalls, secure
            development lifecycle management, secure coding practices, 2FA
            access, PCI Level 1 Service Provider, internal vulnerability
            assessments, continuous employee education, virus/malware scanning,
            phishing protection, and more.
          </p>
          <h2>‍</h2>
        </div>
      </CardContent>
    </Card>
  )
}

export default DPA
