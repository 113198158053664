import { Card, CardContent, CardHeader, Link } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'

const Subprocessors = ({ analytics }) => {
  useEffect(() => {
    logEvent(analytics, 'subprocessors')
  }, [])
  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardHeader title='Subprocessors' />
      <CardContent>
        <div className='w-richtext'>
          <p>
            Community Document uses third party subprocessors, such as cloud computing
            providers and customer support software, to provide our services. We
            enter into GDPR-compliant data processing agreements with each
            subprocessor, extending{' '}
            <Link href='/support/dpa'>GDPR safeguards</Link> everywhere
            personal data is processed.
          </p>
          <p>
            <strong>For personal data of end users of our clients:</strong>
          </p>
          <p>
            Subprocessors that store end user information are located in either
            the United States, Canada, Australia, or Europe, based on your
            preference:
          </p>
          <ul>
            <li>
              <Link
                href='https://firebase.google.com/terms/data-processing-terms'
                target='_blank' rel="noreferrer"
              >
                Firebase
              </Link>
              . Cloud services provider.
            </li>
          </ul>
          <p>
            Subprocessors that provide additional analysis or notification
            services (but do not store end user information) located in the
            United States:
          </p>
          <ul>
            <li>
              <Link href='https://cloud.google.com/privacy/gdpr/?hl=en' target='_blank' rel="noreferrer">
                GMAIL
              </Link>
              . Email service.
            </li>
            <li>
              <Link
                href='https://support.google.com/analytics/answer/6004245?hl=en'
                target='_blank' rel="noreferrer"
              >
                Google Analytics
              </Link>
              . Web analytics service.
            </li>
            <li>
              <Link href='https://cloud.google.com/security/gdpr/resource-center/'
                target='_blank' rel="noreferrer">
                Google Cloud Platform
              </Link>
              . Cloud services provider.
            </li>
          </ul>
          <p>
            <strong>For personal data of our clients:</strong>
          </p>
          <p>Subprocessors located in the United States:</p>
          <ul>
            <li>
              <Link
                href='https://stripe.com/guides/general-data-protection-regulation'
                target='_blank' rel="noreferrer"
              >
                Stripe
              </Link>
              . Payment processing services.
            </li>
          </ul>
          <p>‍</p>
        </div>
      </CardContent>
    </Card>
  )
}

export default Subprocessors
