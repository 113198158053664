import { Box, Button, Card, CardContent, Stack, TextField } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import { addDoc, collection } from 'firebase/firestore'
import { useEffect, useState } from 'react'

const ContactUs = ({ db, analytics }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organization, setOrganization] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [hear, setHear] = useState('')
  useEffect(() => {
    logEvent(analytics, 'contact')
  }, [])

  const handleEmail = event => {
    const value = event.target.value
    setEmail(value)
  }
  const handleFirstName = event => {
    const value = event.target.value
    setFirstName(value)
  }
  const handleLastName = event => {
    const value = event.target.value
    setLastName(value)
  }
  const handleOrganization = event => {
    const value = event.target.value
    setOrganization(value)
  }
  const handlePhoneNumber = event => {
    const value = event.target.value
    setPhoneNumber(value)
  }
  const handleMessage = event => {
    const value = event.target.value
    setMessage(value)
  }
  const handleHear = event => {
    const value = event.target.value
    setHear(value)
  }

  function createObject () {
    let obj = {}
    obj.to = ['contact@communitydocument.com']
    obj.message = {
      subject: 'Contact Form',
      html: `${firstName} ${lastName} (${email} - ${phoneNumber}) ${organization} <br /> Message: ${message} <br /> How Did They hear About Us: ${hear}`
    }
    return obj
  }

  async function contact () {
    await addDoc(collection(db, 'mail'), createObject())
    setEmail('')
    setFirstName('')
    setLastName('')
    setOrganization('')
    setPhoneNumber('')
    setHear('')
    setMessage('')
  }

  return (
    <Card
      raised
      sx={{ p: 5, width: { md: '90vw', xs: 'unset' }, margin: '20px auto' }}
    >
      <CardContent sx={{ width: 'fit-content', margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <h3
            style={{
              color: '#1976d2'
            }}
          >
            We would love to hear from you!
          </h3>
          <h1>Contact Us</h1>
        </Box>
        <br />
        <Stack
          direction='row'
          sx={{
            maxWidth: '820px',
            gap: '20px',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <TextField
            id='firstName'
            label='First Name'
            variant='filled'
            onChange={handleFirstName}
            value={firstName}
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
            required
          />
          <TextField
            id='lastName'
            label='Last Name'
            variant='filled'
            onChange={handleLastName}
            value={lastName}
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
            required
          />
          <TextField
            id='phoneNumber'
            label='Phone Number'
            variant='filled'
            onChange={handlePhoneNumber}
            value={phoneNumber}
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
            required
          />
          <TextField
            id='email'
            label='Email'
            variant='filled'
            onChange={handleEmail}
            value={email}
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
            required
          />
          <TextField
            id='organization'
            label='Organization'
            variant='filled'
            onChange={handleOrganization}
            value={organization}
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
          />
          <TextField
            label='Your Message'
            multiline
            fullWidth
            rows={4}
            variant='filled'
            required
            sx={{ width: '-webkit-fill-available' }}
            value={message}
            onChange={handleMessage}
          />
          <TextField
            label='How Did You Hear About Community Document?'
            multiline
            fullWidth
            rows={2}
            sx={{ width: '-webkit-fill-available' }}
            variant='filled'
            value={hear}
            onChange={handleHear}
          />
          <br />
          <Button
            disabled={
              email.length === 0 ||
              phoneNumber.length === 0 ||
              firstName.length === 0 ||
              lastName === 0 ||
              message.length === 0
            }
            sx={{ maxWidth: '400px', width: '-webkit-fill-available' }}
            onClick={contact}
            variant='contained'
          >
            Contact Us
          </Button>

          <p style={{ width: '100%', textAlign: 'center' }}>
            <b>Customer Support: (240) 390-6286</b>
          </p>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ContactUs
