import '../../Style/ViewDoc.css'
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip
} from '@mui/material'
import { PaperComponent } from '../Helpers/Drag'
import CommentTypeIcon from './CommentTypeIcon'
import { useEffect, useRef, useState } from 'react'
import { Delete, Reply, ThumbUp } from '@mui/icons-material'
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  setDoc
} from 'firebase/firestore'

export const ViewCommentModal = ({
  uid,
  showModal,
  setShowModal,
  currentComment,
  removeComment,
  document,
  displayName,
  db,
  view,
  isAllowedToComment
}) => {
  const [reply, setReply] = useState('')
  const [likes, setLikes] = useState(0)
  const [replies, setReplies] = useState([])
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  function handleReplyChange (e) {
    let value = e.target.value
    setReply(value)
  }

  async function addReply (type) {
    const d = new Date()
    let r = replies
    let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
    let newObj = {
      displayName: displayName,
      uid: uid,
      dateString: d.toDateString(),
      time: d,
      message: type === 'Like' ? '' : reply,
      messageType: type,
      id: document.id + '_' + uid + '_' + Math.floor(Math.random() * 10000),
      currentComment: currentComment.id
    }
    await setDoc(
      ref,
      {
        replies: arrayUnion(newObj)
      },
      { merge: true }
    )
    r.push(newObj)
    setReplies(r)
    if (type === 'Like') {
      setLikes(likes + 1)
    } else {
      setReply('')
    }
  }

  function addLike () {
    let r = replies
    if (
      r.filter(val => val.uid === uid && val.messageType === 'Like').length > 0
    ) {
      let oldObj = r.filter(
        val => val.uid === uid && val.messageType === 'Like'
      )[0]
      removeReply(oldObj, 'Like')
    } else {
      addReply('Like')
    }
  }

  async function removeReply (currentReply, type = 'Reply') {
    let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
    let r = replies
    let oldObj = r.filter(val => val.id === currentReply.id)[0]
    await setDoc(
      ref,
      {
        replies: arrayRemove(oldObj)
      },
      { merge: true }
    )
    r = r.filter(val => val.id !== currentReply.id)
    setReplies(r)
    if (type === 'Like') {
      setLikes(likes - 1)
    }
  }

  useEffect(() => {
    async function getReplies () {
      let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
      let snap = await getDoc(ref)
      if (snap.exists()) {
        let data = snap.data()
        let r = data.replies
        setReplies(r)
        setLikes(r.filter(val => val.messageType === 'Like').length)
      } else {
        setReplies([])
        setLikes(0)
      }
    }
    if (view && db && document.id) {
      getReplies()
    } else {
      setReplies([])
      setLikes(0)
    }
  }, [currentComment, view, db])

  useEffect(() => {
    scrollToBottom()
  }, [replies.length])

  return (
    <>
      {currentComment && (
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          PaperComponent={PaperComponent}
          aria-labelledby='draggable-dialog-title'
          fullWidth
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <Stack direction='row' style={{ gap: '10px' }} alignItems='center'>
              <CommentTypeIcon
                type={currentComment.messageType}
                currentComment={currentComment}
                document={document}
                uid={uid}
              />
              <Stack spacing={2}>
                {currentComment.displayName
                  ? currentComment.displayName
                  : currentComment.uid}

                <span className='caption'>{currentComment.dateString}</span>
              </Stack>
            </Stack>
            <h5
              style={{
                fontWeight: '600',
                fontSize: '16px',
                overflowWrap: 'anywhere',
                lineHeight: '1.2',
                margin: '0',
                marginTop: '8px'
              }}
            >
              {currentComment.message.length > 0
                ? currentComment.message
                : currentComment.messageType + 'd'}
            </h5>
          </DialogTitle>
          {view && (
            <DialogContent>
              <Stack spacing={1}>
                {replies.filter(val => val.messageType !== 'Like').length >
                  0 && (
                  <>
                    <span>Replies:</span>
                    {replies
                      .filter(val => {
                        if (!document.commentVisibility) {
                          return (
                            val.uid === uid ||
                            (document &&
                              (document.uid === uid ||
                                (document.admins &&
                                  document.admins.includes(uid))))
                          )
                        } else {
                          return true
                        }
                      })
                      .filter(val => val.messageType !== 'Like')
                      .map(currentReply => (
                        <Card key={currentReply.id} sx={{ p: 1 }}>
                          <Stack spacing={2}>
                            {currentReply.displayName
                              ? currentReply.displayName
                              : currentReply.uid}
                            <span className='caption'>
                              {currentReply.dateString}
                            </span>
                          </Stack>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <span style={{ overflowWrap: 'anywhere' }}>
                              {currentReply.message}
                            </span>

                            {(uid === currentReply.uid ||
                              uid === document.uid ||
                              (document.admins &&
                                document.admins.includes(uid))) && (
                              <Tooltip title='Remove Reply'>
                                <IconButton
                                  onClick={() => removeReply(currentReply)}
                                  color='error'
                                  sx={{ width: 'min-content' }}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </Card>
                      ))}

                    <div ref={messagesEndRef} />
                  </>
                )}
              </Stack>
            </DialogContent>
          )}
          <DialogActions>
            <Stack spacing={2} sx={{ width: '100%' }}>
              {isAllowedToComment && view && (
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                    width: '100%',
                    alignSelf: 'center',
                    p: '0 24px'
                  }}
                >
                  <TextField
                    id='outlined-multiline-static'
                    label='Reply...'
                    fullWidth
                    variant='standard'
                    value={reply}
                    onChange={handleReplyChange}
                    onKeyUp={e => e.key === 'Enter' && addReply('Reply')}
                  />

                  {reply.length === 0 ? (
                    <Button
                      variant='contained'
                      disabled={reply.length === 0}
                      onClick={() => addReply('Reply')}
                    >
                      <Reply />
                    </Button>
                  ) : (
                    <Tooltip title='Reply'>
                      <Button
                        variant='contained'
                        disabled={reply.length === 0}
                        onClick={() => addReply('Reply')}
                      >
                        <Reply />
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              )}
              <Stack direction='row' spacing={2} sx={{ alignSelf: 'end' }}>
                {isAllowedToComment && (
                  <>
                    {(uid === currentComment.uid ||
                      uid === document.uid ||
                      (document.admins && document.admins.includes(uid))) && (
                      <Button
                        onClick={() => removeComment(currentComment)}
                        color='error'
                        variant='outlined'
                      >
                        Remove
                      </Button>
                    )}
                    {view && (
                      <Tooltip title='Like/Unlike'>
                        <Button
                          startIcon={<ThumbUp />}
                          variant={
                            replies.filter(
                              val =>
                                val.messageType === 'Like' && val.uid === uid
                            ).length > 0
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={addLike}
                        >
                          {likes}
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
                <Button onClick={() => setShowModal(false)} variant='outlined'>
                  Close
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ViewCommentModal
