import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

const SignOut = ({ auth }) => {
  const navigate = useNavigate()
  signOut(auth)
    .then(() => {
      navigate('/signin')
    })
    .catch(error => {})

  return <h1>Signed out</h1>
}

export default SignOut
