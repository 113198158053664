import { Message, NotListedLocation, QuestionMark, ThumbUp } from '@mui/icons-material'
import { Stack, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'

const CommentTypeIcon = ({
  type,
  currentComment,
  document,
  uid
}) => {
  const [classString, setClassString] = useState('')

  useEffect(() => {
    setClassString(
      'comment' +
        (document &&
        uid &&
        (currentComment.uid === document.uid ||
          (document.admins && document.admins.includes(currentComment.uid)))
          ? ' admin'
          : '') +
        (uid && currentComment.uid === uid ? ' yours' : '')
    )
  }, [type, currentComment, document])

  switch (type.toLowerCase()) {
    case 'question':
      if (classString.includes('admin')) {
        return (
          <Tooltip title='Admin Question'>
            <NotListedLocation className={classString} />
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title='Question'>
            <QuestionMark className={classString} />
          </Tooltip>
        )
      }
    case 'like':
      return (
        <Tooltip title='Like'>
          <ThumbUp className={classString} />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title='Comment'>
          <Message className={classString} />
        </Tooltip>
      )
  }
}

export const LegendBar = () => {
  return (
    <Stack
      direction='row'
      useFlexGap
      flexWrap='wrap'
      spacing={2}
      sx={{ p: 2 }}
      className='legend'
      justifyContent='center'
    >
      <Stack direction='row' spacing={1} alignItems='center'>
        <Message className={'comment'} />{' '}
        <span className='caption'>Comment</span>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        <QuestionMark className={'comment'} />{' '}
        <span className='caption'>Question</span>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        <ThumbUp className={'comment'} /> <span className='caption'>Like</span>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        <NotListedLocation className={'comment admin'} />{' '}
        <span className='caption'>Admin</span>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Message className={'comment yours'} />{' '}
        <span className='caption'>Yours</span>
      </Stack>
    </Stack>
  )
}

export default CommentTypeIcon
