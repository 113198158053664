import '../../Style/ViewDoc.css'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { MenuItem, Stack } from '@mui/material'
import { PaperComponent } from '../Helpers/Drag'
import CommentTypeIcon from './CommentTypeIcon'

export const CommentModal = ({
  showModal,
  setShowModal,
  addComment,
  currentComment
}) => {
  const [messageType, setMessageType] = useState('Comment')
  const [message, setMessage] = useState('')

  const types = [
    {
      value: 'Comment',
      label: 'Comment'
    },
    {
      value: 'Question',
      label: 'Question'
    },
    {
      value: 'Like',
      label: 'Like'
    }
  ]

  function handleMessageChange (e) {
    let value = e.target.value
    setMessage(value)
  }
  function handleMessageType (e) {
    let value = e.target.value
    setMessageType(value)
  }
  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      PaperProps={{
        component: 'form',
        onSubmit: e => {
          e.preventDefault()
          let comment = currentComment
          comment.message = message
          comment.messageType = messageType
          const d = new Date()
          comment.time = d
          comment.dateString = d.toDateString()
          addComment(comment)
        }
      }}
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
    >
      <DialogTitle style={{ cursor: 'move' }}>
        Add a Comment, Question or Like
      </DialogTitle>
      <DialogContent>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='baseline'
          spacing={1}
          sx={{ mb: 2 }}
        >
          <TextField
            sx={{ mb: 2 }}
            id='filled-select-currency'
            select
            label='Message type'
            defaultValue='Comment'
            variant='filled'
            fullWidth
            value={messageType}
            onChange={handleMessageType}
          >
            {types.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <CommentTypeIcon type={messageType} />
        </Stack>

        <TextField
          id='outlined-multiline-static'
          label='Message'
          multiline
          rows={4}
          fullWidth
          variant='filled'
          value={message}
          onChange={handleMessageChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowModal(false)} variant='outlined'>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          disabled={
            message === '' &&
            messageType !== 'Like' &&
            messageType !== 'Dislike'
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CommentModal
