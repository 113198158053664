import { ContentCopy, IosShare } from '@mui/icons-material'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Switch
} from '@mui/material'
import { addDoc, collection } from 'firebase/firestore'
import { useState } from 'react'
import MultiEmailComponent from './MultiEmailComponent'

const EmailShare = ({ setShowModal, db, viewCode, id, fileName, customURL }) => {
  const [emails, setEmails] = useState([])
  const [sentEmails, setSentEmails] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [useViewCode, setUseViewCode] = useState(true)
  const [docId, setDocId] = useState(customURL ? customURL : id)

  function createObject () {
    let obj = {}
    obj.to = emails
    obj.message = {
      subject: 'You have been invited to view a document on Community Document',
      html:
        `Check out ${fileName} here: <a href='${
          window.location.origin + '/view/' + docId
        }' target='_blank'>
        ${window.location.host + '/view/' + docId}
      </a>` +
        (viewCode !== null && useViewCode
          ? '. Use Access Code: "' + viewCode + '"'
          : '')
    }
    return obj
  }

  async function share () {
    await addDoc(collection(db, 'mail'), createObject())
    setShowSuccess(true)
    setSentEmails(emails)
    setEmails([])
  }

  function copy () {
    navigator.clipboard.writeText(
      window.location.origin +
        '/view/' +
        docId +
        (viewCode !== null ? ' Access Code: ' + viewCode : '')
    )
    setShowAlert(true)
  }

  return (
    <>
      <DialogTitle sx={{ cursor: 'move' }}>Share {fileName}!</DialogTitle>
      <DialogContent>
        <form>
          {showAlert && (
            <Alert
              variant='filled'
              severity='success'
              onClose={() => {
                setShowAlert(false)
              }}
              sx={{ mb: 1 }}
            >
              Copied to Clipboard!
            </Alert>
          )}
          <Stack
            direction='row'
            justifyContent='space-between'
            useFlexGap
            flexWrap='wrap'
            alignItems='center'
          >
            <Stack>
              <Link
                style={{ overflowWrap: 'anywhere' }}
                href={window.location.origin + '/view/' + docId}
                target='_blank' rel="noreferrer"
              >
                {window.location.host + '/view/' + docId}
              </Link>
              {viewCode !== null && ' View Code: ' + viewCode}
            </Stack>
            <IconButton onClick={copy}>
              <ContentCopy />
            </IconButton>
          </Stack>
          <h3>Recipients:</h3>
          <span>
            Add emails and click enter, or copy and paste an email list.
          </span>
          <br />
          <MultiEmailComponent emails={emails} setEmails={setEmails} />
          {viewCode !== null && (
            <Stack direction='row' alignItems='center'>
              <h5 style={{ margin: '0' }}>Share View Code</h5>
              <Switch
                checked={useViewCode}
                onChange={() => {
                  setUseViewCode(!useViewCode)
                }}
              />
            </Stack>
          )}
          {showSuccess && (
            <>
              <Alert
                variant='filled'
                severity='success'
                onClose={() => {
                  setShowSuccess(false)
                }}
                sx={{ mt: 1 }}
              >
                Shared with Recipients!
              </Alert>
              <br />
              <span style={{ overflowWrap: 'anywhere' }}>
                {sentEmails.join(', ')}
              </span>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowModal(false)} variant='outlined'>
          Close
        </Button>
        <Button
          onClick={share}
          color='success'
          variant='contained'
          disabled={emails.length < 1}
          startIcon={<IosShare />}
        >
          Share
        </Button>
      </DialogActions>
    </>
  )
}

export default EmailShare
