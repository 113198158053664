import { Download } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'

const DownloadButton = ({ document, icon = true, db }) => {
  const [transactionData, setTransactionData] = useState([])
  const csvLink = useRef()

  function getDateString (date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
  }

  const arrayToCsv = (array, header) => {
    const csvString = [
      [document.fileName, getDateString(Date.now())],
      header,
      ...array.map(item => Object.values(item))
    ]
    return csvString
  }

  async function getReplies (currentComment) {
    if (db) {
      let ref = doc(db, 'documents', document.id, 'replies', currentComment.id)
      let snap = await getDoc(ref)
      if (snap.exists()) {
        let data = snap.data()
        return data.replies
      }
    }
    return []
  }

  const downloadReport = async () => {
    let comments = []
    if (document.comments) {
      const promises = document.comments.map(async comment => {
        comments.push({
          User: comment.displayName ?? comment.uid,
          Comment: comment.message,
          Date: comment.dateString,
          Type: comment.messageType,
          Page: comment.pageNumber
        })
        let replies = await getReplies(comment)
        replies.forEach(reply => {
          comments.push({
            User: comment.displayName ?? comment.uid,
            Comment:
              reply.messageType === 'Like'
                ? "Liked '" +
                  (comment.message ? comment.message : 'A LIKE') +
                  "'"
                : "Reply to '" +
                  comment.message.substring(0, 10) +
                  "': " +
                  reply.message,
            Date: reply.dateString,
            Type: 'Comment ' + reply.messageType,
            Page: comment.pageNumber
          })
        })
      })

      await Promise.all(promises)

      let str = arrayToCsv(comments, [
        'User',
        'Comment',
        'Date',
        'Type',
        'Page'
      ])
      str.push([])
      str.push([
        'Comment Count:',
        document.comments ? document.comments.length : 0
      ])
      str.push([])
      str.push(['View Count', document.visited])
      str.push([
        'Distinct Viewers',
        document.visitedBy ? document.visitedBy.length : 0
      ])
      str.push([])
      str.push(['Viewer Name', 'Viewer Email'])
      str.push([])
      document.visitedBy &&
        document.visitedBy.forEach(viewer => {
            str.push([viewer.displayName, viewer.email])
        })
      setTransactionData(str)
    }
  }

  useEffect(() => {
    if (transactionData.length > 0) {
      csvLink.current.link.click()
      setTransactionData([])
    }
  }, [transactionData])

  return (
    <>
      {icon ? (
        <Tooltip title='Download Report'>
          <IconButton onClick={downloadReport}>
            <Download />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Download Report'>
          <Button onClick={downloadReport} sx={{ minWidth: 'unset' }}>
            <Download />
          </Button>
        </Tooltip>
      )}
      <CSVLink
        data={transactionData}
        filename={'Communnity Document Report for:' + document.fileName + '.csv'}
        style={{ display: 'none' }}
        ref={csvLink}
        target='_blank' rel="noreferrer"
      />
    </>
  )
}

export default DownloadButton
